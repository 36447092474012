import { PublicClientApplication } from "@azure/msal-browser";

export const config = {
  backendEndpoint: "https://cbbapi.shi-ev.com/api/office/",
  defaultEndpoint: "https://cbbapi.shi-ev.com/api/office/",
  clientEndpoint: "https://cbb.shi-ev.com/",
  clientId: "a36c5be5-e8af-4a35-a4f8-4c0eeeb7b906",
  tenantId: "963d0cb9-c29b-4266-82ba-950d434afb6e",
  timeoutDefault: 36000000,
};

export const msalConfig = {
  auth: {
    clientId: config.clientId,
    authority: `https://login.microsoftonline.com/${config.tenantId}`,
    redirectUri: config.clientEndpoint,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [`${config.clientId}/.default`],
};

const getTokenFromLocalStorage = () => {
  // Copy all localStorage keys and values
  const allTokens = { ...localStorage };
  // Filter the access token based on the key format
  const accessTokenKey = Object.keys({ ...localStorage }).find((key) =>
    key.includes("accesstoken")
  );
  if (accessTokenKey) {
    const accessToken = JSON.parse(allTokens[accessTokenKey]).secret;
    return accessToken;
  }
};

export const getToken = getTokenFromLocalStorage;

export const logOut = () => {
  localStorage.clear();
  const msalInstance = new PublicClientApplication(msalConfig);
  msalInstance.logoutRedirect({
    postLogoutRedirectUri: "/.default",
  });
};

export const toyotaKamoId = 35001;
export const arrayClInputDate = [1122, 1956];
export const yuniId = 10019;
export const workingSaveStatus = [2, 3];
