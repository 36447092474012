// Import libraries
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    Col,
    Row,
    Table,
    Space,
    Input,
    Modal,
    Button,
    DatePicker,
    Form,
    Tooltip,
    Select
} from "antd";
import {
    MSG_CATCH,
    INPUT_DATE,
    renderOption,
    showMessage,
    getQuerySearch,
    int2DateString,
    pushObject2Query,
    getDateValue,
    getTxtValue,
} from "../../constants/utils";
import {
    SearchOutlined,
    ClearOutlined,
    EyeFilled,
    DeleteOutlined,
    EditFilled,
} from "@ant-design/icons";
import {
    COMMON_CONSTANT,
    KEY,
    lang,
    CONFIG,
} from "../../constants/common.const";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import { Content } from "antd/lib/layout/layout";
import { ReactComponent as AddIcon } from "@src/styles/icon/add.svg";
import classGrid from "../../models/control/grid";
import API from "../../api/backend/representativeMaster";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import classText from "../../models/control/text";
import { useHistory, useLocation } from "react-router-dom";
import classDdl from "../../models/control/dropdownlist";
let objSearchOrigin = {
    representativeName: null,
    officeName: null,
    inaugurationDateFrom: null,
    inaugurationDateTo: null,
};
let objSearch = { ...objSearchOrigin };
objSearch = getQuerySearch();
const objClear = { value: null, disabled: true, checked: false };


function ViewRepresentativeMaster({ history: historyPage }) {
    // Step1: Update param
    if (historyPage.action === "POP") {
        objSearch = getQuerySearch();
    } else {
        objSearch = { ...objSearchOrigin };
    }
    const history = useHistory();

    // Declare variable --- START
    // 1: Loading
    const [showLoading, setShowLoading] = useState(false);
    let openLoadingSpinner = () => {
        setShowLoading(true);
    };
    let closeLoadingSpinner = () => {
        setShowLoading(false);
    };
    const [formSearch] = Form.useForm();


    // 2: Input search
    const [txtRepresev, setTxtRepresev] = useState({
        ...new classText(),
        value: getTxtValue(objSearch.representativeName),
    });

    const [ddlOfficeName, setDdlOfficeName] = useState(new classDdl());

    const [txtCreateStartDate, setTxtCreateStartDate] = useState({
        ...new classText(),
        value: getDateValue(objSearch.inaugurationDateFrom),
    });
    const [txtCreateEndDate, setTxtCreateEndDate] = useState({
        ...new classText(),
        value: getDateValue(objSearch.inaugurationDateTo),
    });


    // 3: Grid
    const [grid, setGrid] = useState(new classGrid());

    const { allowRoute } = useSelector((state) => state.auth);

    const isPC = useMediaQuery({
        query: KEY.LAPTOP_WIDTH,
    });

    // 4: Sync input <-> url
    const syncInput = () => {
        objSearch.representativeName = txtRepresev.value ? txtRepresev.value.trim() : null;
        objSearch.officeName = ddlOfficeName.value ? ddlOfficeName.value : null;
        objSearch.inaugurationDateFrom = txtCreateStartDate.value;
        objSearch.inaugurationDateTo = txtCreateEndDate.value;
        // Push to url
        pushObject2Query(objSearch);
    };

    // 5: ClearInputSearch
    const clearInputSearch = () => {
        setTxtRepresev(objClear);
        setDdlOfficeName(objClear);
        setTxtCreateStartDate(objClear);
        setTxtCreateEndDate(objClear);
        formSearch.resetFields();
        formSearch.submit();
        onClickClear();
    };

    // 6: Event call 
    //     6.1. API Search
    const search_onClick = () => {
        // Loading
        openLoadingSpinner();

        // input to objectSearch; push url
        syncInput();

        // Update format
        objSearch = getQuerySearch();
        objSearch.inaugurationDateFrom = int2DateString(Number(objSearch.inaugurationDateFrom));
        objSearch.inaugurationDateTo = int2DateString(Number(objSearch.inaugurationDateTo));

        // Call
        return API.searchRepresentativeMaster({
            params: {
                ...objSearch,
            },
        })
            .then((res) => {
                setGrid({ ...grid, data: res.data, count: res.data.length });
                getCombOffice();
            })
            .catch(() => {

                showMessageError(MSG_CATCH());
            })
            .then(() => {
                // Close loading
                closeLoadingSpinner();
            });
    };

    const getCombOffice = () => {
        return API.getOfficeName()
            .then((res) => {
                setDdlOfficeName({ ...ddlOfficeName, options: res.data, count: res.data.length })
            })
            .catch(() => {

                showMessageError(MSG_CATCH());
            });
    };

    // 7: Message
    //      7.1. Message Confirm
    const showMessageDeleteConfirm = (rowData) => {
        // Action del
        const funcDel = async () => {
            openLoadingSpinner();

            // API Del
            try {
                // Close warning
                Modal.destroyAll();

                await API.delete(rowData.id);
                // API Search
                await search_onClick();

                // Open success
                showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
            } catch (ex) {
                showMessage(KEY.ERROR, MSG_CATCH());
            }
            closeLoadingSpinner();
        };

        Modal.confirm({
            centered: true,
            title: lang.CONFIRM,
            content: COMMON_CONSTANT.CONFIRM_DEL,
            okText: lang.OK,
            cancelText: lang.CANCEL,
            onOk: funcDel,
        });
    };
    //      7.2. Message Error
    const showMessageError = (msg) => {
        showMessage(KEY.ERROR, msg);
    };

    // 8: Init
    useEffect(() => {
        search_onClick();
    }, [allowRoute]);

    // 9. Column GRID
    const initColumns = [
        {
            title: lang.REPRESENTATIVE_TXT,
            dataIndex: "representativeName",
            width: "15%",
            align: "left",
        },
        {
            title: lang.REPRESENTATIVE_OFF_NAME,
            dataIndex: "officeName",
            width: "15%",
            align: "left",
        },
        {
            title: lang.REPRESENTATIVE_DATE,
            dataIndex: "inaugurationDateDis",
            width: "15%",
            align: "center",
        },
        {
            title: lang.ACTION,
            align: "center",
            width: "15%",
            render: (row) => {
                let disabledEdit = false;
                let disabledDelete = false;
                return (
                    <>
                        {/* Edit */}
                        <Tooltip title={lang.IMPORT_INVENTORY_UPDATE_BUTTON}>
                            <Button disabled={disabledDelete} className="mr5 btnEdit">
                                <Link to={"/EditRepresentativeMaster/" + row.id} className="w100">
                                    <EditFilled className="" />
                                </Link>
                            </Button>
                        </Tooltip>

                        {/* Delete */}
                        <Tooltip title={lang.DELETE1}>
                            <Button
                                disabled={disabledDelete}
                                className="mr5 btnDelete"
                                onClick={() => {
                                    showMessageDeleteConfirm(row);
                                }}
                            >
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    // Event click button "Clear" then init screen
    const [currentPage, setCurrentPage] = useState("");
    const [pagingImplement, setPagingImplement] = useState({ position: ["bottomCenter"], pageSize: 15, hideOnSinglePage: true });
    const [btnclear, setBtnclear] = useState(0);
    const onClickClear = () => {
        setBtnclear(1);
    }
    const onChange = (pagination) => {
        setCurrentPage(pagination.current);
        setBtnclear(2);
    };

    useEffect(() => {
        if (currentPage.length === 0 || btnclear == 1) {
            setCurrentPage(1);
            setPagingImplement({ ...pagingImplement, current: currentPage })
            setBtnclear(2);
        } else if (btnclear == 2) {
            setPagingImplement({ ...pagingImplement, current: currentPage });
            setBtnclear(0);
        }
    });
    //-------------------------------------------------

    // Render
    return (
        <div className="des-content">
            <TitlePage name={lang.REPRESENTATIVE_MASTER} />
            <Content
                className="site-layout-background"
                style={{
                    margin: "24px 16px",
                    padding: 24,
                }}
            >
                <Form
                    layout={"vertical"}
                    name="searchWR"
                    form={formSearch}
                    onFinish={search_onClick}
                    className="formStyle"
                >
                    {/* 1 */}
                    <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
                        {/* 代表者 */}
                        <Col span={8}>
                            <div className="ant-form-item-label">{lang.REPRESENTATIVE_TXT}</div>
                            <Input placeholder={lang.REPRESENTATIVE_TXT}
                                value={txtRepresev.value}
                                maxLength={10}
                                onChange={(e) =>
                                    setTxtRepresev({
                                        ...txtRepresev,
                                        value: e.target.value.toUpperCase(),
                                    })
                                }
                            />
                        </Col>
                        <Col span={8}>
                            <div className="ant-form-item-label">{lang.REPRESENTATIVE_OFF_NAME}</div>
                            <Form.Item
                                name="ddlOfficeName"
                                label=""
                                className="mb0"
                            >
                                <Select
                                    value={ddlOfficeName.value}
                                    placeholder={lang.REPRESENTATIVE_OFF_NAME}
                                    onChange={(v) => {
                                        setDdlOfficeName({
                                            ...ddlOfficeName,
                                            value: v,
                                        });
                                    }}
                                // {...enterSearch(search_onClick)}
                                >
                                    {renderOption(ddlOfficeName.options)}
                                </Select>
                            </Form.Item>

                        </Col>

                    </Row>
                    {/* 2 */}
                    <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
                        <Col span={8}>
                            <div className="ant-form-item-label">
                                {lang.REPRESENTATIVE_DATE}
                            </div>
                            <Row>
                                <Col span={10}>
                                    <DatePicker
                                        format={KEY.DATE_DEFAULT}
                                        value={txtCreateStartDate.value}
                                        placeholder={KEY.DATE_DEFAULT}
                                        className="w100"
                                        onChange={(v) => {
                                            setTxtCreateStartDate({
                                                ...txtCreateStartDate,
                                                value: v,
                                            });
                                        }}
                                        {...INPUT_DATE} />
                                </Col>
                                <Col span={4} className="center" style={{ padding: "7px" }}>
                                    ～
                                </Col>
                                <Col span={10}>
                                    <DatePicker
                                        // size="large"
                                        format={KEY.DATE_DEFAULT}
                                        value={txtCreateEndDate.value}
                                        placeholder={KEY.DATE_DEFAULT}
                                        className="w100"
                                        onChange={(v) => {
                                            setTxtCreateEndDate({
                                                ...txtCreateEndDate,
                                                value: v,
                                            });
                                        }}
                                        {...INPUT_DATE}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="auto"></Col>
                        <Col flex="none" className="right">
                            <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                                <Col>
                                    <Button
                                        onClick={onClickClear}
                                        htmlType="submit"
                                        className="buttonPC button--info wAuto"
                                    >
                                        <SearchOutlined className="" />
                                        {lang.SEARCH}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className="buttonPC button--outline --todo-- wAuto ml10"
                                        onClick={clearInputSearch}
                                    >
                                        <ClearOutlined />
                                        {lang.CLEAR}
                                    </Button>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Form>
            </Content>

            <Content
                className="site-layout-background"
                style={{
                    margin: "24px 16px",
                    padding: 24,
                }}
            >
                {/* Count */}
                <Row justify="space-between ">
                    <Col span={12}>
                        <Button
                            type="link"
                            className="btnAddRecord mb15"
                            onClick={() => {
                                history.push("/AddRepresentativeMaster");
                            }}
                        >
                            <AddIcon />
                            {lang.ADD}
                        </Button>
                    </Col>
                    <Col span={12} className="right">
                        {/* Count */}
                        <Space size={10} align="center ">
                            {lang.ACCESS_NUMBERS}
                            <span>
                                {grid.count}
                                {lang.CASE}
                            </span>
                        </Space>
                    </Col>
                </Row>
                {/* Grid  */}
                <Table
                    rowKey={(record) => record.id}
                    columns={initColumns}
                    dataSource={grid.data}
                    current={currentPage}
                    pagination={pagingImplement}
                    onChange={onChange}
                    sticky={{offsetHeader:"53px"}}
                />
            </Content>
            <Loading show={showLoading}></Loading>
        </div >
    );
}
export default ViewRepresentativeMaster;