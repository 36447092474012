import Api from "@src/api";
export const FunctionCustomApi = {
  loadMenu() {
   return Api().get('Menu')
  },
  getMenuOffice(query) {
    return Api().get(`MenuOffice?menuName=${query}`);
  },
  postMenuOffice(data){
    return Api().post("MenuOffice",data)
  }

};
