// this function help to check exactly type of value
// typeOf([]) => 'Array'
// typeOf(new Date()) => 'Date'
// typeOf(new Function()) => 'Function'

export function typeOf(value) {
  return Object.prototype.toString.call(value).slice(8, -1);
}

export function formatNumber(value) {
  if (value == null) {
    return "";
  }
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
