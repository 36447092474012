import Api from "@src/api";
export default {
    // 1: Get All Data
    searchRepresentativeMaster(data) {
        return Api().get("RepresentativeMaster/SearchRepresentativeMaster", data);
    },
    // 2: Create/Update
    save(formData, id) {
        return id ?
            Api().put(`RepresentativeMaster/UpdateRepresentativeMaster/${id}`, formData, { // UPDATE
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            :
            Api().post("RepresentativeMaster/CreateRepresentativeMaster", formData, { // CREATE
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    },
    delete(id) {
        return Api().delete(`RepresentativeMaster/DeleteRepresentativeMaster/${id}`);
    },
    getOfficeName() {
        return Api().get(`RepresentativeMaster/GetOfficeName`);
    },
    getDetail(id) {
        return Api().get(`RepresentativeMaster/GetRepresentativeMasterDetail/${id}`);
    },

};