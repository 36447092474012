import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { KEY, lang } from "../../constants/common.const";
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { selectRowSearch } from "@src/redux/checkList";
import classGrid from "../../models/control/grid";
import {
  Col,
  Row,
  Space,
  Button,
  Input,
  Radio,
  Table, Pagination
} from "antd";
import API from "../../api/backend/contractSub";
import {
  MSG_CATCH,
  showMessage,
} from "../../constants/utils";

function PopupSearch({
  setIsModalOpenSearch: _setIsModalOpenSearch,
  show,
}) {
  const dispatch = useDispatch();
  const [tradingpartnermaster, setTradingpartnermaster] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [selectRow, setSelectRow] = useState();
  const [grid, setGrid] = useState(new classGrid());
  // const [currentPage, setCurrentPage] = useState("");
  // const [pagingImplement, setPagingImplement] = useState({
  //   position: ["bottomCenter"],
  //   pageSize: 15,
  //   hideOnSinglePage: true,
  // });
  // const [btnclear, setBtnclear] = useState(0);
  const [isLoadding, setIsLoadding] = useState(true);
  const [current, setCurrent] = useState(1);

  const getRowIndex = () => {
    if (grid.data && grid.data.length > 0) {
      // const arrayRow = grid.data.filter((_item, index) => { index == parseInt(selectRow) });
      const arrayRow = grid.data.filter((word, index, arr) => {
        return index == parseInt(selectRow);
      });
      dispatch(selectRowSearch(arrayRow));
      _setIsModalOpenSearch(false);
    }
  }

  // const onChange = (pagination) => {
  //   setCurrentPage(pagination.current);
  //   setBtnclear(2);
  //   clearLocalStorage();
  //   localStorage.removeItem("Back");
  // };

  // const clearLocalStorage = () => {
  //   localStorage.removeItem("Page");
  // };

  const onSearch = () => {
    getContractSubName(1);
    setCurrent(1);
  }

  //init
  useEffect(() => {
    if (show) {
      getContractSubName(1);
      setCurrent(1);
    }
  }, [show]);

  //change page
  useEffect(() => {
    if (current) {
      getContractSubName(current);
    }
  }, [current]);

  useEffect(() => {
    if (grid.data) {
      if (grid.data.length > 0) {
        setIsLoadding(false);
      }
    }
  }, [grid.data]);

  // useEffect(() => {
  //   var pageNum = localStorage.getItem("Page");
  //   var pageNumBack = localStorage.getItem("Back");
  //   if (currentPage.length === 0 || btnclear == 1) {
  //     setCurrentPage(1);
  //     setPagingImplement({ ...pagingImplement, current: currentPage });
  //     setBtnclear(2);
  //   } else if (btnclear == 2) {
  //     setPagingImplement({ ...pagingImplement, current: currentPage });
  //     setBtnclear(0);
  //   } else if (pageNum != null) {
  //     if (typeof pageNumBack === "undefined" || pageNumBack == null) {
  //       localStorage.setItem("Back", parseInt(pageNum));
  //       pageNumBack = pageNum;
  //     }
  //     if (pageNumBack != pageNum) {
  //       pageNum = pageNumBack;
  //     }
  //     setPagingImplement({ ...pagingImplement, current: parseInt(pageNum) });
  //     setBtnclear(0);
  //     clearLocalStorage();
  //   }
  // });

  const getContractSubName = (pcurrent) => {
    setIsLoadding(true);
    const objSearch = { pCompanyName: companyName, pTradingpartnermaster: tradingpartnermaster, currentPage: pcurrent, pageSize: pageSize, type: 1 };
    return API.contractSubName({
      params: {
        ...objSearch,
      },
    })
      .then((res) => {

        // Update key
        if (res.data && res.data.length > 0) {
          const pageCount = res.data[0].totalCount;
          let grid = res.data;
          grid = grid.map((v, i) => {
            v["rowKey"] = i;
            return v;
          });

          // Set data to grid
          setGrid({ ...grid, data: grid, count: pageCount });
        } else {
          setGrid({ ...grid, data: [], count: 0 });
        }
        setIsLoadding(false);
        setSelectRow('');
      })
      .catch((err) => {
        setIsLoadding(false);
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        setIsLoadding(false);
      });
  };

  // Column GRID
  const initColumns = [
    {
      title: "",
      align: "left",
      dataIndex: "radio",
      render: (text, row) => (
        <Radio value={row.rowKey} />
      ),
      width: "80px",
    },
    {
      title: "取引先コード",
      dataIndex: "trhsakcd",
      width: "100px",
      align: "left",
    },
    // {
    //   title: "担当課",
    //   dataIndex: "tntkac",
    //   width: "100px",
    //   align: "center",
    // },
    {
      title: "会社名",
      dataIndex: "trhsaknM1",
      width: "250px",
      align: "left",
    },
    {
      title: "住所",
      dataIndex: "trhadrs",
      width: "250",
      align: "left",
    }
  ];

  const pageSize = 5;

  const getData = (current, pageSize) => {
    // Normally you should get the data from the server
    return grid.data.slice((current - 1) * pageSize, current * pageSize);
  };

  // Custom pagination component
  const MyPagination = ({ total, onChange, current }) => {
    return (
      <Pagination
        className="center"
        onChange={onChange}
        total={total}
        current={current}
        pageSize={pageSize}
      />
    );
  };

  return (
    <div className={`content-popup ` + (show ? `d-block` : `d-none`)}>
      <div className="content-body-popup">
        <div className="bold">取引先マスター</div>
        <div className="horizontal-Item pt15">
          <div>
            <div>{lang.TRADINGPARTNERMASTER}</div>
            <div className="mr15">
              <Input
                value={tradingpartnermaster.value}
                maxLength={70}
                placeholder={lang.TRADINGPARTNERMASTER}
                onChange={(v) => {
                  setTradingpartnermaster(v.target.value);
                }} />
            </div>
          </div>
          <div>
            <div>{lang.COMPANYNAME}</div>
            <div className="mr15">
              <Input
                value={companyName.value}
                maxLength={70}
                placeholder={lang.COMPANYNAME}
                onChange={(v) => {
                  setCompanyName(v.target.value);
                }} />
            </div>
          </div>
          <Space size={20} align="center">
            <Button
              className="buttonPC button--info wAuto mt20"
              disabled={isLoadding}
              onClick={onSearch}
            >
              <SearchOutlined />
              {" " + lang.SEARCH}
            </Button>
          </Space>
        </div>
        {grid.data ? (
          <Radio.Group onChange={(v) => {
            setSelectRow(v.target.value);
          }} value={selectRow}>
            <Space direction="vertical">
              <Table
                rowKey={(record) => record.id}
                columns={initColumns}
                dataSource={grid.data}
                loading={isLoadding}
                pagination={false}
                scroll={{ y: 300 }}
              />
              <MyPagination
                total={grid.count}
                current={current}
                onChange={setCurrent}
              />
            </Space>
          </Radio.Group>
        ) : (
          <></>
        )}
        <Row justify="space-between">
          <Col span={24} className="right">
            <Space size={20} align="center">
              <Button
                className="buttonPC button--info wAuto ml15"
                disabled={!grid.data || grid.data.length <= 0}
                onClick={getRowIndex}
              >
                {lang.OK}
              </Button>
            </Space>
            <Space size={20} align="center">
              <Button
                className="buttonPC button--outline --todo-- wAuto ml20 mt30"
                onClick={() => _setIsModalOpenSearch(false)}
              >
                <CloseCircleOutlined />
                {" " + lang.CANCEL}
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PopupSearch;
