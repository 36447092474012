import Api from "@src/api";
import { get } from "lodash";

export default {

    //HieuLD6
    //1: list
    list(oyaseino, csncb1) {
        return Api().get(`ContractSub/List?oyaseino=${oyaseino}&&csncb1=${csncb1}`);
    },
    //2: list sub contract remove
    listRemove(oyaseino, csncb1) {
        return Api().get(`ContractSub/GetListRemove?oyaseino=${oyaseino}&&csncb1=${csncb1}`);
    },
    //3: sub contract detail
    detail(oyaseino, thino) {
        return Api().get(`ContractSub/Detail/${oyaseino}/${thino}`);
    },
    //3: sub contract name
    contractSubName(data) {
        return Api().get(`ContractSub/ContractSubName`, data);
    },
    //3: sub contract name
    getContractNumber(data) {
        return Api().get(`ContractSub/GetContractNumber`, data);
    },
    //4: revert sub contract detail
    revert(thino) {
        return Api().put(`ContractSub/Revert/${thino}`);
    },
    //5: sub contract status
    getStatus(thino) {
        return Api().get(`ContractSub/GetStatus/${thino}`);
    },
    //6: remove sub contract
    remove(thino) {
        return Api().delete(`ContractSub/Remove/${thino}`);
    },
    //7: Remove all sub contract checked
    removeChecked(data) {
        return Api().post(`ContractSub/RemoveChecked`, data, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
    },
    //8: Revert all sub contract checked
    revertChecked(data) {
        return Api().post(`ContractSub/RevertChecked`, data, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
    },
    //9: save contrant Number
    putContractNumber(data) {
        return Api().put(`ContractSub/PutContractNumber`, data);
    },
    //10: save contrant sub
    putContractSub(data) {
        return Api().put(`ContractSub/PutContractSub`, data, { // CREATE
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    //11: Acc or Del contract sub
    accOrDel(thino) {
        return Api().put(`ContractSub/AccOrDel/${thino}`);
    },
    //12: get dll kyokabango
    Getkyokabango() {
        return Api().get(`ContractSub/Getkyokabango`);
    },
    //13: check del contract number
    CheckDelContractNumber(data) {
        return Api().get(`ContractSub/CheckDelContractNumber`, data);
    },
    //14: update delflg BusinessPartnerMaster
    UpdateBusinessPartnerMaster(data) {
        return Api().put(`ContractSub/HidenAndShowBusinessPartnerMaster`, data);
    },
}