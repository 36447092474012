import React, { useState, useEffect, useRef } from "react";
import TitlePage from "../../component/Desktop/titlePage";
import GroupItem from "../../component/Desktop/groupItem";
import RowInput from "../../component/Desktop/rowInput";
import Loading from "../../component/Desktop/loading/loading";
import PopupSearch from "../../component/Desktop/popupSearch";
import PopupContractNumber from "../../component/Desktop/popupContractNumber";
import {
  KEY,
  lang,
  COMMON_CONSTANT,
  STYLE,
} from "../../constants/common.const";
import API from "../../api/backend/contractSub";
import BreakLine from "../../component/Desktop/breakLine";
import {
  MSG_CATCH,
  showMessage,
  statusRes,
  int2DateString,
  renderOptionNameCanInputSearch,
  getAttachName,
} from "../../constants/utils";
import { useHistory, useParams } from "react-router-dom";
import {
  RollbackOutlined,
  UploadOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import classText from "../../models/control/text";
import { selectRowSearch } from "@src/redux/checkList";
import moment from "moment";
import { config, getToken } from "../../api/config";
import axios from "axios";
import {
  Col,
  Row,
  Space,
  Button,
  DatePicker,
  Input,
  Switch,
  Select,
  Upload,
  Modal,
  InputNumber,
} from "antd";

function SubContractCreate({ history: historyPage }) {
  let history = useHistory();
  const dispatch = useDispatch();
  let [showLoading, setShowLoading] = useState(false);
  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  const [inaugurationDate, setinaugurationDate] = useState(new classText()); //Required
  const [isTxtSubcontractor, setTxtSubcontractor] = useState(new classText()); //Required
  const [txtSubcontractorNumber, setTxtSubcontractorNumber] = useState(
    new classText()
  ); //Required
  const [txtSubcontractorNumberOption, setTxtSubcontractorNumberOption] =
    useState([]);
  const [isDnk, setDnk] = useState(new classText()); //Required
  const [isDateFrom, setDateFrom] = useState(new classText());
  const [isDateTo, setDateTo] = useState(new classText());
  const [isModalOpenSearch, setIsModalOpenSearch] = useState(false);
  const [isModalOpenContractNumber, setIsModalOpenContractNumber] =
    useState(false);
  const [fileList, setFileList] = useState([]);
  const [trhadrs, setTrhadrs] = useState(null);
  const [newlistContractNumber, setNewlistContractNumber] = useState(null);
  const [checkSwich, setCheckSwich] = useState(false);
  const [checkButtonSave, setCheckButtonSave] = useState(1);
  const [trhsakcdold, setTrhsakcdold] = useState("");
  const [snychscd, setSnychscd] = useState();
  const [sihonkg, setSihonkg] = useState();
  const [paymentRules, setPaymentRules] = useState();
  const [paymentTerms, setPaymentTerms] = useState();
  let inaugurationDateRef = useRef(null);
  let txtSubcontractorRef = useRef(null);
  let txtSubcontractorNumberRef = useRef(null);
  let txtDateFromRef = useRef(null);
  let txtDateToRef = useRef(null);
  const [txtProductName, setTxtProductName] = useState("");
  const [txtPrchseAmount, setTxtPrchseAmount] = useState("");
  const userId = JSON.parse(localStorage.getItem("uInfo")).userName || "";
  const getRole = JSON.parse(localStorage.getItem("uInfo")).role || "";
  //state auth
  const { allowRoute } = useSelector((state) => state.auth);
  const { selectRow } = useSelector((state) => state.checklist);

  // get param
  let { oyaseino, thino } = useParams();

  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  //declare variable data grid
  const [dataDetail, setDataDetail] = useState({});

  //init
  useEffect(() => {
    init();
  }, [allowRoute]);

  useEffect(() => {
    if (selectRow && selectRow.length > 0) {
      setContractNumber();
      setTrhsakcdold(selectRow[0].trhsakcd);
      setTrhadrs(selectRow[0].trhadrs);
      setSnychscd(selectRow[0].snychscd);
      setSihonkg(selectRow[0].sihonkg);
      setPaymentRules(selectRow[0].paymentRules);
      setPaymentTerms(selectRow[0].paymentTerms);
      setTxtSubcontractor({
        ...isTxtSubcontractor,
        value: selectRow[0].trhsaknM1,
      });
      setTxtSubcontractorNumber({ ...txtSubcontractorNumber, value: "" });
      dispatch(selectRowSearch([]));
    }
  }, [selectRow]);

  const setContractNumber = async () => {
    const objSearch = {
      id: null,
      trhsakcd: selectRow[0].trhsakcd,
      oyaseino: selectRow[0].oyaseino,
    };
    await API.getContractNumber({
      params: {
        ...objSearch,
      },
    })
      .then((res) => {
        if (res.data != null && res.data.length > 0) {
          let newArr2 = [...newlistContractNumber];
          newArr2 = res.data;
          setNewlistContractNumber(newArr2);
        } else {
          setNewlistContractNumber([]);
        }
      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  };

  useEffect(() => {
    if (newlistContractNumber) {
      setTxtSubcontractorNumberOption([]);
      for (let i = 0; i < newlistContractNumber.length; i++) {
        let strid = newlistContractNumber[i].id;
        const strTrhsakcd = newlistContractNumber[i].trhsakcd;
        const strLicensenNumber = newlistContractNumber[i].licensennumber;
        if (strid == null) {
          strid = "";
        }
        const arrOption = {
          value: strid + ";" + strTrhsakcd,
          label: strLicensenNumber,
        };
        setTxtSubcontractorNumberOption((txtSubcontractorNumberOption) => [
          ...txtSubcontractorNumberOption,
          arrOption,
        ]);
      }
    }
  }, [newlistContractNumber]);

  const init = () => {
    // Open loading
    openLoadingSpinner();
    return API.detail(oyaseino, thino)
      .then((res) => {
        let data = res.data ? res.data : null;
        setDataDetail(data);

        let inaugurationDate = data.hacymD_PARSED_DATE
          ? moment.unix(data.hacymD_PARSED_DATE)
          : moment(new Date());
        setinaugurationDate({ ...inaugurationDate, value: inaugurationDate });

        setTxtSubcontractor({
          ...isTxtSubcontractor,
          value: data.subcontractor,
        });

        setTxtSubcontractorNumber({
          ...txtSubcontractorNumber,
          value: data.contractoR_NUMBER,
        });

        if (data.knsymdFrom_PARSED_DATE) {
          let dtDateFrom = data.knsymdFrom_PARSED_DATE
            ? moment.unix(data.knsymdFrom_PARSED_DATE)
            : moment(new Date());
          setDateFrom({ ...isDateFrom, value: dtDateFrom });
        }

        if (data.knsymdTo_PARSED_DATE) {
          let dtDateTo = data.knsymdTo_PARSED_DATE
            ? moment.unix(data.knsymdTo_PARSED_DATE)
            : moment(new Date());

          setDateTo({ ...isDateTo, value: dtDateTo });
        }

        setDnk({ ...isDnk, value: data.dnk });

        setTrhadrs(data.trhadrs);

        setNewlistContractNumber(data.listContractNumber);

        if (data.normaluserview && data.normaluserview == "1") {
          setCheckSwich(true);
        } else if (data.normaluserview && data.normaluserview == "0") {
          setCheckSwich(false);
        } else {
          data.screen > 1 ? setCheckSwich(false) : setCheckSwich(true);
        }

        if (data.flagDel && data.flagDel == "2") {
          setCheckButtonSave(2);
        } else if (data.flagDel == null) {
          setCheckButtonSave(1);
        } else {
          setCheckButtonSave(3);
        }

        setTrhsakcdold(data.trhsakcd);

        setSnychscd(data.snychscd);

        setSihonkg(data.sihonkg);

        setPaymentRules(data.paymentRules);

        setPaymentTerms(data.paymentTerms);
        setTxtProductName(data.buhinxnm);
        setTxtPrchseAmount(data.prchseAmount);

        if (data.attached_document) {
          data.attached_document.split(",").map((item) =>
            setFileList((fileList) => [
              ...fileList,
              {
                uid: getAttachName(
                  item.split("/")[item.split("/").length - 1],
                  1
                ),
                name: getAttachName(
                  item.split("/")[item.split("/").length - 1],
                  1
                ),
                status: "done",
                url: item,
              },
            ])
          );
        }
      })
      .catch((err) => {
        closeLoadingSpinner();
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        closeLoadingSpinner();
      });
  };

  // On file upload (click the upload button)
  const onFileUpload = async (pThino) => {
    let token = await getToken();

    // Create an object of formData
    const formData = new FormData();
    for (let index = 0; index < fileList.length; index++) {
      const element = fileList[index];
      formData.append("file", element.originFileObj);
    }
    formData.append("oyaseinoAndThino", dataDetail.oyaseino + "_" + pThino);
    // Request made to the backend api
    // Send formData object
    try {
      await axios({
        url: config.backendEndpoint + "ContractSub/ImportFile",
        method: "post",
        data: formData,
      });
      console.log("ok");
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpload = ({ fileList }) => {
    // you store them in state, so that you can make a http req with them later
    setFileList(fileList);
  };

  // Popup common
  const showConfirmMessage = (
    msg,
    msg2,
    callbackOK,
    callbackCancel,
    typeInfo = false
  ) => {
    let ok, cancel;
    let callBackDefault = () => {
      Modal.destroyAll();
    };

    // Callback OK exist
    if (callbackOK) {
      ok = () => {
        callbackOK();
        callBackDefault();
      };
    }
    // Callback Cancel exist
    if (callbackCancel) {
      cancel = () => {
        callbackCancel();
        callBackDefault();
      };
    }

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: <NewLineContent text={msg2 ? msg + "\n" + msg2 : msg} />,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onCancel: cancel || callBackDefault,
      onOk: ok || callBackDefault,
    });
  };

  function NewLineContent(props) {
    const text = props.text;
    const newText = text.split("\n");
    return (
      <div>
        <p>{newText[0]}</p>
        <p style={{ color: "red" }}>{newText[1]}</p>
      </div>
    );
  }

  function backToSearch() {
    history.push(
      `/ContractParent/Detail?oyaseino=${dataDetail.oyaseino}&&csncb1=${dataDetail.csncb1}`
    );
  }

  function backToAcc() {
    setCheckButtonSave(2);
    history.push(`/ContractSub/Detail/${oyaseino}/${thino}`);
  }

  function confirmSaveData(status) {
    if (validateSave()) {
      let message_confirm =
        dataDetail.screen > 1
          ? COMMON_CONSTANT.CONFIRM_UPDATE_REPRESENTATIVE
          : COMMON_CONSTANT.CONFIRM_REGISTER_REPRESENTATIVE;
      let message_confirm2 =
        dataDetail.screen > 1
          ? COMMON_CONSTANT.CONFIRM_UPDATE_REPRESENTATIVE_ACCEPT
          : "";
      if (status == 3) {
        message_confirm = COMMON_CONSTANT.CONFIRM_ACC_SUBCONTRACT;
        message_confirm2 = "";
      }
      showConfirmMessage(message_confirm, message_confirm2, function () {
        openLoadingSpinner();
        // Create Form data
        const formData = new FormData();
        const myArray = txtSubcontractorNumber.value.split(";");
        let txtattachFile;
        for (var i = 0; i < fileList.length; i++) {
          txtattachFile = txtattachFile
            ? txtattachFile + "," + fileList[i].name
            : fileList[i].name;
        }
        let formInput = {
          oyaseino: dataDetail.oyaseino,
          csncb1: dataDetail.csncb1,
          thino: dataDetail.thino,
          contractdate: int2DateString(Number(inaugurationDate.value)),
          contractorname: isTxtSubcontractor.value,
          contractordatefrom: int2DateString(Number(isDateFrom.value)),
          contractorDateto: int2DateString(Number(isDateTo.value)),
          acceptanceclassification: isDnk.value,
          normaluserview: checkSwich ? "1" : "0",
          filename: "",
          pathfile: "",
          contractornumberid: myArray.length > 0 ? myArray[0] : "",
          hcyskicd: myArray.length > 0 ? myArray[1] : "",
          tntka51cd: myArray.length > 0 ? myArray[2] : "",
          userid: userId ? userId : "Admin",
          attacheddocument: txtattachFile,
          status: status,
          productName: txtProductName,
          prchseAmount: txtPrchseAmount,
        };

        formData.append("data", JSON.stringify(formInput));
        // formData.append("file", JSON.stringify(selectedFile.files));
        // Sent data to Server
        API.putContractSub(formData)
          .then((res) => {
            if (statusRes(res)) {
              onFileUpload(res.data);
              // Success
              showMessage(
                KEY.INFO,
                COMMON_CONSTANT.BOM_C003,
                status === 3 ? backToAcc() : backToSearch
              );
            } else {
              showMessage(KEY.ERROR, MSG_CATCH());
            }
          })
          .catch((err) => {
            let msg = "";
            if (
              err.response &&
              err.response.status === 400 &&
              err.response.data
            ) {
              msg = err.response.data.msg;
            } else {
              msg = MSG_CATCH();
            }
            showMessage(KEY.ERROR, msg);
          })
          .then(() => {
            closeLoadingSpinner();
          });
      });
    }
  }

  function validateSave() {
    let flagErr = false,
      elmFocus;
    let CheckTxtSubcontractor = {
      ...isTxtSubcontractor,
      value: isTxtSubcontractor.value,
      error: false,
    };
    if (CheckTxtSubcontractor.value.length === 0) {
      flagErr = true;
      // Border red
      CheckTxtSubcontractor = {
        ...isTxtSubcontractor,
        value: isTxtSubcontractor.value,
        error: true,
      };
      elmFocus = txtSubcontractorRef;
      // Focus item error
      elmFocus && elmFocus.current.focus();
    }

    let checkTxtSubcontractorNumber = {
      ...txtSubcontractorNumber,
      value: txtSubcontractorNumber.value,
      error: false,
    };
    if (checkTxtSubcontractorNumber.value.length === 0) {
      flagErr = true;
      // Border red
      checkTxtSubcontractorNumber = {
        ...txtSubcontractorNumber,
        value: txtSubcontractorNumber.value,
        error: true,
      };
      elmFocus = txtSubcontractorNumberRef;
      // Focus item error
      elmFocus && elmFocus.current.focus();
    }

    // Update state
    setTxtSubcontractor(CheckTxtSubcontractor);
    setTxtSubcontractorNumber(checkTxtSubcontractorNumber);
    return !flagErr;
  }

  function confirmAccOrDel() {
    if (validateSave()) {
      let message_confirm = COMMON_CONSTANT.CONFIRM_ACC_SUBCONTRACT;
      showConfirmMessage(message_confirm, "", function () {
        openLoadingSpinner();

        // Sent data to Server
        API.accOrDel(dataDetail.thino)
          .then((res) => {
            if (statusRes(res)) {
              // Success
              showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, backToAcc);
            } else {
              showMessage(KEY.ERROR, MSG_CATCH());
            }
          })
          .catch((err) => {
            let msg = "";
            if (
              err.response &&
              err.response.status === 400 &&
              err.response.data
            ) {
              msg = err.response.data.msg;
            } else {
              msg = MSG_CATCH();
            }
            showMessage(KEY.ERROR, msg);
          })
          .then(() => {
            closeLoadingSpinner();
          });
      });
    }
  }

  const showModalSearch = () => {
    setIsModalOpenSearch(true);
  };

  const showModalContractNumber = () => {
    setIsModalOpenContractNumber(true);
  };

  const onChangeSwich = (checked) => {
    setCheckSwich(checked);
  };

  const onChangeProductName = (e) => {
    setTxtProductName(e.target.value);
  };

  const onChangePrchseAmount = (value) => {
    setTxtPrchseAmount(value);
  };

  // UPLOAD
  const props = {
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    multiple: true,
    beforeUpload: false,
  };

  return (
    <>
      <div className="des-content">
        <TitlePage
          name={
            dataDetail && dataDetail.screen > 1
              ? lang.REJECT_SUBCONTRACT_UPDATE
              : lang.REJECT_SUBCONTRACT_CREATE
          }
        />
        <GroupItem
          center
          style={{
            margin: isPC ? "24px 16px" : 0,
            padding: isPC ? 24 : "12px 24px",
          }}
        >
          <div
            className="title-page d-block left border-bottom-10px-orange mt10 pl-20 pr-20"
            style={{ marginBottom: "10px" }}
          >
            <h1 className="bold white-space">{"下請情報"}</h1>
          </div>

          <BreakLine />
          <RowInput name={lang.DEPARTMENT} box>
            {dataDetail.bunm}
          </RowInput>
          <BreakLine />
          <RowInput name={lang.MADE} box>
            {dataDetail.oyaseino}
          </RowInput>
          <BreakLine />
          <RowInput name={lang.CONSTRUCTION} box>
            {dataDetail.kjiryknm}
          </RowInput>
          <BreakLine />
          <RowInput name={lang.LOCATION_NAME} box>
            {dataDetail.trhsaknM1}
          </RowInput>
          <BreakLine />
          <RowInput name={lang.LOCATION_PROVINE} box>
            {dataDetail.prefnm}
          </RowInput>
          <BreakLine />
          <RowInput name={lang.LOCATION_DISTRICT} box>
            {dataDetail.locationProvince}
          </RowInput>
          <BreakLine />
          <RowInput name={lang.CONTRACT_TERM} box>
            {/* {dataDetail.hacymd} */}
            <DatePicker
              format={inaugurationDate.format || KEY.DATE_DEFAULT}
              ref={inaugurationDateRef}
              value={inaugurationDate.value}
              picker={inaugurationDate.picker}
              placeholder={inaugurationDate.format || KEY.DATE_DEFAULT}
              className={
                "w200px " + (inaugurationDate.error ? STYLE.BORDER_RED : "")
              }
              onChange={(v, dateString) => {
                setinaugurationDate({
                  ...inaugurationDate,
                  value: v,
                });
              }}
              {...inaugurationDate.input}
            />
          </RowInput>
          <BreakLine />
          <RowInput name={lang.SUBCONTRACTOR} required box>
            {/* {dataDetail.subcontractor} */}
            <div className="horizontal-Item">
              <Input
                ref={txtSubcontractorRef}
                className={isTxtSubcontractor.error ? STYLE.BORDER_RED : ""}
                disabled={true}
                value={isTxtSubcontractor.value}
                maxLength={70}
                onChange={(v) => {
                  setTxtSubcontractor({
                    ...isTxtSubcontractor,
                    value: v.target.value,
                  });
                }}
              />
              <Space size={20} align="center">
                <Button
                  className="buttonPC button--info wAuto ml15"
                  onClick={showModalSearch}
                >
                  <SearchOutlined />
                  {" " + lang.SEARCH}
                </Button>
              </Space>
            </div>
          </RowInput>
          <BreakLine />
          <RowInput name={lang.SUBCONTRACTOR_ADDRESS} box>
            {trhadrs}
          </RowInput>
          <BreakLine />
          <RowInput
            name={lang.SUBCONTRACTOR_CONTRUCTION_PERMIT_NUMBER}
            required
            box
          >
            {/* {dataDetail.ksgkykbng} */}
            <div className="horizontal-Item">
              <Select
                ref={txtSubcontractorNumberRef}
                style={{ width: 400 }}
                allowClear
                showSearch
                optionFilterProp="children"
                className={txtSubcontractorNumber.error ? STYLE.BORDER_RED : ""}
                value={txtSubcontractorNumber.value}
                disabled={!isTxtSubcontractor.value}
                onChange={(v) => {
                  setTxtSubcontractorNumber({
                    ...txtSubcontractorNumber,
                    value: v,
                  });
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(input.toUpperCase()) >= 0
                }
              >
                {renderOptionNameCanInputSearch(txtSubcontractorNumberOption)}
              </Select>
              <Space size={20} align="center">
                <Button
                  className="buttonPC button--info wAuto ml15"
                  disabled={!isTxtSubcontractor.value}
                  onClick={showModalContractNumber}
                >
                  <PlusOutlined />
                  {" " + lang.ADD_CONTRACTOR_NUMBER}
                </Button>
              </Space>
            </div>
          </RowInput>
          <BreakLine />
          <RowInput name={lang.SUBCONTRACTOR_TYPE} box>
            {snychscd}
          </RowInput>
          <BreakLine />
          <RowInput name={lang.CAPITAL} box>
            {sihonkg ? Number.parseFloat(sihonkg).toFixed(1) : 0}{" "}
            <span>&emsp;&emsp;&emsp;&emsp;&emsp;(単位 :100万円)</span>
          </RowInput>
          <BreakLine />
          <RowInput name={lang.DATE_FINSHED_CHECK} box>
            {/* {dataDetail.knsymdFrom} */}
            <DatePicker
              format={isDateFrom.format || KEY.DATE_DEFAULT}
              ref={txtDateFromRef}
              value={isDateFrom.value}
              picker={isDateFrom.picker}
              placeholder={isDateFrom.format || KEY.DATE_DEFAULT}
              className={"w200px " + (isDateFrom.error ? STYLE.BORDER_RED : "")}
              onChange={(v, dateString) => {
                setDateFrom({
                  ...isDateFrom,
                  value: v,
                });
              }}
              {...isDateFrom.input}
            />
          </RowInput>
          <BreakLine />
          <RowInput name={lang.DELIVERY_DATE} box>
            {/* {dataDetail.knsymdTo} */}
            <DatePicker
              format={isDateTo.format || KEY.DATE_DEFAULT}
              ref={txtDateToRef}
              value={isDateTo.value}
              picker={isDateTo.picker}
              placeholder={isDateTo.format || KEY.DATE_DEFAULT}
              className={"w200px " + (isDateTo.error ? STYLE.BORDER_RED : "")}
              onChange={(v, dateString) => {
                setDateTo({
                  ...isDateTo,
                  value: v,
                });
              }}
              {...isDateTo.input}
            />
          </RowInput>
          <BreakLine />
          <RowInput name={lang.ACCEPT_TYPE} box>
            {/* {dataDetail.dnk} */}
            <Select
              style={{ width: 200 }}
              options={dataDetail.listDnk}
              value={isDnk.value}
              onChange={(v) => {
                setDnk({
                  ...isDnk,
                  value: v,
                });
              }}
            />
          </RowInput>
          <BreakLine />
          <RowInput name={lang.SUBCONTRACT_PAYMENT_RULES} box>
            {paymentRules}
          </RowInput>
          <BreakLine />
          <RowInput name={lang.PAYMENT_RULES} box>
            {paymentTerms}
          </RowInput>
          <BreakLine />
          <RowInput name={lang.SUBCONTRACT_PAYMENT_STATUS} box>
            {dataDetail.paymentStatus}
          </RowInput>
          <BreakLine />
          <RowInput name={lang.PRODUCT_NAME} box>
            <Input value={txtProductName} onChange={onChangeProductName} />
          </RowInput>
          <BreakLine />
          <RowInput name={lang.ORDER_AMOUNT2} box>
            <InputNumber min={0}
              value={txtPrchseAmount}
              onChange={onChangePrchseAmount}
            />
          </RowInput>
          <BreakLine />
          <RowInput name={lang.ATTACHED_DOCUMENT} box>
            <div>
              <Space>
                <p style={{ fontWeight: "bold", padding: "10px" }}>
                  一般ユーザ表示:
                </p>
              </Space>
              <Space align="right">
                <Switch
                  checked={checkSwich}
                  disabled={getRole != "1"}
                  onChange={onChangeSwich}
                />
              </Space>
            </div>
            <Space size={2} align="center">
              <Upload
                {...props}
                style={{ color: "black" }}
                multiple={true}
                fileList={fileList}
                onChange={handleUpload}
                beforeUpload={() => false}
              >
                <Button icon={<UploadOutlined />}>添付ファイル</Button>
              </Upload>
            </Space>
          </RowInput>
          <BreakLine />

          <Row justify="space-between">
            <Col span={24} className="right">
              {dataDetail.screen > 1 ? (
                <>
                  {checkButtonSave == 1 || checkButtonSave == 2 ? (
                    <>
                      {checkButtonSave == 1 ? (
                        <Space size={20} align="center">
                          <Button
                            className="buttonPC button--info wAuto ml15"
                            onClick={() => {
                              confirmSaveData(3);
                            }}
                          >
                            <SaveOutlined />
                            {COMMON_CONSTANT.CONFIRM_SAVE}
                          </Button>
                        </Space>
                      ) : (
                        <></>
                      )}
                      <Space size={20} align="center">
                        <Button
                          className="buttonPC button--info wAuto ml15"
                          onClick={() => {
                            confirmSaveData(2);
                          }}
                        >
                          <SaveOutlined />
                          {COMMON_CONSTANT.UPDATE}
                        </Button>
                      </Space>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <Space size={20} align="center">
                    <Button
                      className="buttonPC button--info wAuto ml15"
                      onClick={() => {
                        confirmSaveData(1);
                      }}
                    >
                      <SaveOutlined />
                      {COMMON_CONSTANT.SAVE}
                    </Button>
                  </Space>
                </>
              )}
              <Space size={20} align="center">
                <Button
                  className="buttonPC button--outline --todo-- wAuto ml20 mt30"
                  onClick={() => history.goBack()}
                >
                  <RollbackOutlined />
                  {" " + COMMON_CONSTANT.BACK}
                </Button>
              </Space>
            </Col>
          </Row>
        </GroupItem>
        <Loading show={showLoading}></Loading>
      </div>
      <PopupSearch
        show={isModalOpenSearch}
        setIsModalOpenSearch={setIsModalOpenSearch}
      />
      <PopupContractNumber
        show={isModalOpenContractNumber}
        sbubcontractorName={isTxtSubcontractor.value}
        setIsModalOpenContractNumber={setIsModalOpenContractNumber}
        listContractNumber={newlistContractNumber}
        setNewlistContractNumber={setNewlistContractNumber}
        trhsakcdold={trhsakcdold}
        oyaseino={oyaseino}
        thino={thino}
      />
    </>
  );
}
export default SubContractCreate;
