import { createSlice } from "@reduxjs/toolkit";
import API from "@src/api/backend/setting";
import { loadingPage } from "./loading";
import Notification from "@src/component/Popup/notification";
const initialState = {
  allGroupsName: [],
  allChecklist: null,
  checklistsCurrent: null,
  listAddChecklist: null,
  isLoadingGroup: false,
  isLoadingAllChecklist: false,
  groupId: null,
  arrayName: [],
};
const confirmSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    updateGroupsCLName: (state, { payload }) => {
      state.allGroupsName = payload;
    },
    updateGroupChecklist: (state, { payload }) => {
      const { arrayName } = state;
      let data = JSON.parse(JSON.stringify(state.allGroupsName));
      if (payload.type === "create") {
        data.unshift({ ...payload.data, listChecklist: [] });
        arrayName.unshift(payload.data.name);
      }
      if (payload.type === "delete") {
        data = data.filter((item) => item.id !== payload.data);
        state.arrayName = data.map((item) => item.name);
      }
      if (payload.type === "update") {
        data.forEach((item) => {
          if (item.id === payload.data.id) {
            item.name = payload.data.name;
          }
        });
        state.arrayName = data.map((item) => item.name);
      }
      state.allGroupsName = data;
    },
    updateLoadingGroups: (state, { payload }) => {
      state.isLoadingGroup = payload;
    },
    updateLoadingAllCL: (state, { payload }) => {
      state.isLoadingAllChecklist = payload;
    },
    updateGroupId: (state, { payload }) => {
      state.groupId = payload;
    },
    updateChecklistsInGroup: (state, { payload }) => {
      state.checklistsCurrent = payload;
    },
    updateAllChecklist: (state, { payload }) => {
      state.allChecklist = payload;
    },
    updateListAddGroup: (state, { payload }) => {
      state.listAddChecklist = payload;
    },
    addChecklist: (state, { payload }) => {
      state.checklistsCurrent.push(payload);
      let data = JSON.parse(JSON.stringify(state.allGroupsName));
      data.forEach((item) => {
        if (item.id === state.groupId) {
          item.listChecklist.push(payload);
        }
      });
      state.allGroupsName = data;
    },
    removeChecklist: (state, { payload }) => {
      state.checklistsCurrent = state.checklistsCurrent.filter(
        (item) => item.id !== payload.id
      );
      let data = JSON.parse(JSON.stringify(state.allGroupsName));
      data.forEach((item) => {
        if (item.id === state.groupId) {
          item.listChecklist = item.listChecklist.filter(
            (item) => item.id !== payload.id
          );
        }
      });
      state.allGroupsName = data;
    },
    updateArrayName: (state, { payload }) => {
      state.arrayName = payload;
    },
  },
});
// the action
export const {
  updateGroupsCLName,
  updateLoadingGroups,
  updateGroupChecklist,
  updateLoadingAllCL,
  updateChecklistsInGroup,
  updateAllChecklist,
  updateListAddGroup,
  updateArrayName,
  updateGroupId,
  addChecklist,
  removeChecklist,
} = confirmSlice.actions;

// the selector
export const confirmSelector = (state) => state.confirm;

//the reducer
export default confirmSlice.reducer;

// get all groups checklist name
export const getAllGroupsName = () => (dispatch) => {
  dispatch(updateLoadingGroups(false));
  API.getAllGroupsName().then((res) => {
    if (res && res.status === 200 && res.data.length) {
      dispatch(updateGroupsCLName(res.data));
      dispatch(updateChecklistsInGroup(res.data[0].listChecklist));
      dispatch(updateLoadingGroups(true));
      dispatch(updateGroupId(res.data[0].id));
      let arrayName = [];
      res.data.forEach((item) => {
        arrayName.push(item.name);
      });
      dispatch(updateArrayName(arrayName));
    }
  });
};
export const getAllChecklist = () => (dispatch) => {
  dispatch(updateLoadingAllCL(true));
  API.getAllChecklist().then((res) => {
    if (res && res.status === 200) {
      dispatch(updateLoadingAllCL(false));
      dispatch(updateAllChecklist(res.data));
    }
  });
};
export const addGroup = (value) => (dispatch) => {
  dispatch(loadingPage(true));
  API.createGroupChecklist(value)
    .then((res) => {
      dispatch(loadingPage(false));
      if (res && res.status === 200) {
        Notification({
          type: "success",
          text: value + "グループの成功を追加する",
        });
        dispatch(updateGroupChecklist({ type: "create", data: res.data }));
      } else {
        Notification({
          type: "error",
          text: value + "グループの追加に失敗する",
        });
      }
    })
    .catch((err) => {
      Notification({
        type: "error",
        text: value + "グループの追加に失敗する",
      });
    });
};
export const deleteGroup = (id) => (dispatch) => {
  dispatch(loadingPage(true));
  API.deleteGroupChecklist(id)
    .then((res) => {
      dispatch(loadingPage(false));
      if (res && res.status === 200) {
        Notification({
          type: "success",
          text: "グループの成功を削除する",
        });
        dispatch(updateGroupChecklist({ type: "delete", data: id }));
      } else {
        Notification({
          type: "error",
          text: "グループの削除に失敗しました",
        });
      }
    })
    .catch(() => {
      Notification({
        type: "error",
        text: "グループの削除に失敗しました",
      });
    });
};
export const updateGroup = (id, name) => (dispatch) => {
  dispatch(loadingPage(true));
  API.updateGroupChecklist(id, name)
    .then((res) => {
      dispatch(loadingPage(false));
      if (res && res.status === 200) {
        Notification({
          type: "success",
          text: "グループの成功を更新する",
        });
        dispatch(
          updateGroupChecklist({ type: "update", data: { id: id, name: name } })
        );
      } else {
        Notification({
          type: "error",
          text: "グループの更新に失敗する",
        });
      }
    })
    .catch(() => {
      Notification({
        type: "error",
        text: "グループの更新に失敗する",
      });
    });
};
export const addChecklistInGroup = (groupId, checklist) => (dispatch) => {
  dispatch(loadingPage(true));
  API.addChecklistInGroup(groupId, checklist.id).then((res) => {
    if (res && res.status === 200) {
      dispatch(addChecklist(checklist));
      dispatch(loadingPage(false));
    }
  });
};
export const removeChecklistInGroup = (groupId, checklist) => (dispatch) => {
  dispatch(loadingPage(true));
  API.removeChecklistInGroup(groupId, checklist.id).then((res) => {
    if (res && res.status === 200) {
      dispatch(removeChecklist(checklist));
      dispatch(loadingPage(false));
    }
  });
};
