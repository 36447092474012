import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  Table,
  Space,
  Input,
  Modal,
  Button,
  DatePicker,
  Form,
  Tooltip,
  Checkbox,
  Select,
} from "antd";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import { useHistory } from "react-router-dom";
import TitlePage from "../../component/Desktop/titlePage";
import Loading from "../../component/Desktop/loading/loading";
import API from "../../api/backend/contractParent";
import {
  MSG_CATCH,
  INPUT_DATE,
  showMessage,
  getQuerySearch,
  int2DateString,
  pushObject2Query,
  getDateValue,
  getTxtValue,
  renderOption,
} from "../../constants/utils";
import FooterPage from "../../component/footer/footerPage";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";
import STATUS from "@src/constants/status.const";
import "./indexCss.css";
import {
  SearchOutlined,
  ClearOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import BoxContent from "../../component/Desktop/content";
import { useMediaQuery } from "react-responsive";
import objContractRevert from "./objContractRevert";
import classDdl from "../../models/control/dropdownlist";
import { formatNumber } from "../../utils/helper";
import { setSearchParam } from "../../redux/searchContract";

const objClear = { value: null, disabled: true, checked: false };
let objSearchOrigin = {
  oyaseino: null,
  kjiryknm: null,
  jyuymd1: null,
  jyuymd2: null,
  seinoknrymd1: null,
  seinoknrymd2: null,
  officenm: null,
  departmentnm: null,
  representativenm: null,
  customernn: null,
  typeFlg: null,
};

let objSearch = { ...objSearchOrigin };
objSearch = getQuerySearch();

function ParentContractIsDeleted({ history: historyPage }) {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  // Step1: Update param
  if (historyPage.action === "POP") {
    objSearch = getQuerySearch();
  } else {
    objSearch = { ...objSearchOrigin };
  }

  const { allowRoute } = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  const [formSearch] = Form.useForm();

  //  Grid
  const [grid, setGrid] = useState(new classGrid());
  // const [lstNoLicense, setLstNoLicense] = useState();
  // Input search
  // Group 1
  // 1
  let listRevertContract = [];
  const prepareDataRevert = () => {
    grid.data.map((v) => {
      let contractRevert = new objContractRevert();
      if (v.checked) {
        contractRevert.Oyaseino = v.oyaseino;
        contractRevert.Csncb1 = v.csncB1;
        listRevertContract.push(contractRevert);
      }
      return v;
    });
  };
  const [txtOyaseino, setTxtOyaseino] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.oyaseino),
  });

  const [ddlOffice, setDdlOffice] = useState({
    ...new classDdl(),
    value: getTxtValue(objSearch.officenm),
  });

  const [txtDepartmentnm, setTxtDepartmentnm] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.departmentnm),
  });

  const [txtRepresentativenm, setTxtRepresentativenm] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.representativenm),
  });

  const [txtKjiryknm, setTxtKjiryknm] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.kjiryknm),
  });

  const [txtCustomernn, setTxtCustomernn] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.customernn),
  });

  const [txtJyuymd1, setTxtJyuymd1] = useState({
    ...new classText(),
    value: getDateValue(objSearch.jyuymd1),
  });

  const [txtJyuymd2, setTxtJyuymd2] = useState({
    ...new classText(),
    value: getDateValue(objSearch.jyuymd2),
  });

  const [txtSeinoknrymd1, setTxtSeinoknrymd1] = useState({
    ...new classText(),
    value: getDateValue(objSearch.seinoknrymd1),
  });

  const [txtSeinoknrymd2, setTxtSeinoknrymd2] = useState({
    ...new classText(),
    value: getDateValue(objSearch.seinoknrymd2),
  });

  // Step2: Sync input <-> url
  const syncInput = () => {
    objSearch.oyaseino = txtOyaseino.value ? txtOyaseino.value.trim() : null;
    objSearch.officenm = ddlOffice.value;
    objSearch.departmentnm = txtDepartmentnm.value
      ? txtDepartmentnm.value.trim()
      : null;
    objSearch.representativenm = txtRepresentativenm.value
      ? txtRepresentativenm.value.trim()
      : null;
    objSearch.kjiryknm = txtKjiryknm.value ? txtKjiryknm.value.trim() : null;
    objSearch.customernn = txtCustomernn.value
      ? txtCustomernn.value.trim()
      : null;
    objSearch.jyuymd1 = txtJyuymd1.value ? txtJyuymd1.value : null;
    objSearch.jyuymd2 = txtJyuymd2.value ? txtJyuymd2.value : null;
    objSearch.seinoknrymd1 = txtSeinoknrymd1.value
      ? txtSeinoknrymd1.value
      : null;
    objSearch.seinoknrymd2 = txtSeinoknrymd2.value
      ? txtSeinoknrymd2.value
      : null;
    // Push to url
    pushObject2Query(objSearch);
  };
  // Search
  const search_onClick = () => {
    // Loading
    openLoadingSpinner();
    // input to objectSearch; push url
    // Get list ParentContract no liscence
    objSearch.typeFlg = "1";
    // API.getListSubNoLicense({
    //   params: {
    //     typeFlg: '1'
    //   },
    // }).then((res) => {
    //   setLstNoLicense(res ? res.data : []);
    // });
    syncInput();
    // Update format
    objSearch = getQuerySearch();
    objSearch.jyuymd1 = int2DateString(Number(objSearch.jyuymd1));
    objSearch.jyuymd2 = int2DateString(Number(objSearch.jyuymd2));
    objSearch.seinoknrymd1 = int2DateString(Number(objSearch.seinoknrymd1));
    objSearch.seinoknrymd2 = int2DateString(Number(objSearch.seinoknrymd2));
    // Call
    return API.LoadGridviewContractRM({
      params: {
        ...objSearch,
      },
    })
      .then((res) => {
        let grid = res.data;
        grid = grid.map((v, i) => {
          v.rowKey = i;
          v.checked = false;
          return v;
        });
        setGrid({ ...grid, data: grid, count: res.data.length });
        if (res.data.length < 16) {
          window.scroll(0, 20);
        }
      })
      .catch(() => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Close loading
        closeLoadingSpinner();
      });
  };
  // clearInputSearch
  const clearInputSearch = () => {
    setTxtOyaseino(objClear);
    setDdlOffice({ ...ddlOffice, ...objClear });
    setTxtDepartmentnm(objClear);
    setTxtRepresentativenm(objClear);
    setTxtCustomernn(objClear);
    setTxtKjiryknm(objClear);
    setTxtJyuymd1(objClear);
    setTxtJyuymd2(objClear);
    setTxtSeinoknrymd1(objClear);
    setTxtSeinoknrymd2(objClear);
    formSearch.resetFields();
    formSearch.submit();
    onClickClear();
  };
  //Hanle checkbox Start
  const [checkAll, setCheckAll] = useState(false);
  const handleChangeChecked = (rowKey) => (e) => {
    const newGrid = JSON.parse(JSON.stringify(grid));
    const index = newGrid.data.findIndex((item) => item.rowKey === rowKey);
    if (index >= 0) {
      newGrid.data[index].checked = e.target.checked;
      setGrid(newGrid);
    }
  };
  const [disabledButtonRevertAll, setDisabledButtonRevertAll] = useState(true);
  useEffect(() => {
    if (grid.data.length) {
      if (
        grid.data.length ===
        grid.data.filter((item) => item.checked === true).length
      ) {
        setCheckAll(true);
      } else {
        setCheckAll(false);
      }
      if (grid.data.filter((item) => item.checked === true).length > 0) {
        setDisabledButtonRevertAll(false);
      } else {
        setDisabledButtonRevertAll(true);
      }
    } else {
      setDisabledButtonRevertAll(true);
    }
  }, [grid.data.filter((item) => item.checked === true).length]);
  useEffect(() => {
    const newGrid = JSON.parse(JSON.stringify(grid));
    if (checkAll) {
      newGrid.data = newGrid.data.map((item) => {
        item.checked = true;
        return item;
      });
      setGrid(newGrid);
    } else {
      if (
        grid.data.length === grid.data.filter((item) => item.checked).length
      ) {
        newGrid.data = newGrid.data.map((item) => {
          item.checked = false;
          return item;
        });
        setGrid(newGrid);
      }
    }
  }, [checkAll]);
  const initColumns = [
    {
      title: (
        <Checkbox
          disabled={!grid.data.length}
          checked={checkAll}
          onChange={(e) => setCheckAll(e.target.checked)}
        ></Checkbox>
      ),
      align: "center",
      dataIndex: "checked",
      fixed: "left",
      render: (text, row) => (
        <Checkbox
          checked={row.checked}
          onChange={handleChangeChecked(row.rowKey)}
        />
      ),
      width: "2%",
    },
    {
      title: lang.MADE,
      dataIndex: "oyaseino",
      width: "7%",
      align: "center",
      fixed: "left",
      render: (text, row) => {
        return (
          <span
            className="underline"
            style={{ color: STATUS.TYPE_HOME_BANNER[row.type] }}
            onClick={() => {
              dispatch(setSearchParam("/RevertParentContract?typeFlg=1"));
              history.push(
                `ContractParent/Detail?oyaseino=${row.oyaseino}&&csncb1=${row.csncB1}`
              );
              setLocalStorage();
            }}
          >
            {row.typeValue}
            {text}
          </span>
        );
      },
    },
    {
      title: lang.SUBCONTRACTOR_PERMISSION_NUMBER,
      width: "7%",
      align: "center",
      render: (row) => {
        // let valKSGKYKBNG;
        // if (lstNoLicense) {
        //   let oya = lstNoLicense.find((item) => item.oyaseino === row.oyaseino);
        //   if (oya) {
        //     valKSGKYKBNG = "!!下請有　登録無";
        //   }
        // }
        return <span style={{ color: "red" }}>{row.ksgkykbng}</span>;
      },
    },
    {
      title: lang.REPRESENTATIVE_OFF_NAME,
      dataIndex: "officename",
      width: "9%",
      align: "center",
    },
    {
      title: lang.DEPARTMENT_NAME,
      dataIndex: "bunm",
      width: "12%",
      align: "left",
    },
    {
      title: lang.REPRESENTATIVE_NAME,
      dataIndex: "representativename",
      width: "9%",
      align: "center",
    },
    {
      title: lang.CONSTRUCTION_NAME,
      dataIndex: "kjiryknm",
      width: "30%",
      align: "left",
    },
    {
      title: lang.CONTRACT_TERM,
      dataIndex: "display_JYUYMD",
      width: "6%",
      align: "center",
    },
    {
      title: lang.BOOKER_NAME,
      dataIndex: "trhsaknM1",
      align: "left",
      width: "15%",
    },
    {
      title: lang.CONFIRM_CHECKED_DATE,
      dataIndex: "display_KYKNKIYMD",
      width: "6%",
      align: "center",
    },
    {
      title: () => { 
        return <div>{lang.ORDER_AMOUNT}<br/><div style={{fontSize: 10}}>（千円）（税抜）</div></div>;
      } ,
      align: "right",
      width: "6%",
      render: (text, row) => {
        return formatNumber(row.jyuznkkg);
      },
    },
    {
      title: lang.ACTION,
      align: "center",
      width: 120,
      fixed: "right",
      render: (row) => {
        return (
          <>
            <Tooltip title={lang.REVERT}>
              <Button
                className="buttonPC button--outline --todo-- wAuto ml10"
                onClick={() => {
                  showMessageDeleteConfirm(row);
                }}
              >
                <RollbackOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  const showMessageDeleteConfirm = (rowData) => {
    // Action del
    const funcDel = async () => {
      openLoadingSpinner();

      // API Del
      try {
        // Close warning
        Modal.destroyAll();
        const objContractRV = new objContractRevert(
          rowData.oyaseino,
          rowData.csncB1
        );
        listRevertContract.push(objContractRV);
        await API.revert(listRevertContract);
        // API Search
        await search_onClick();

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C039);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.BOM_C00108,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };
  //event revert by checkbox checked
  const RevertALl_click = () => {
    const funcDel = async () => {
      openLoadingSpinner();

      // API Del
      try {
        // Close warning
        Modal.destroyAll();
        prepareDataRevert();
        await API.revert(listRevertContract);
        // API Search
        await search_onClick();

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C039);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.REVERT_MANY,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };
  // Init
  useEffect(() => {
    // Open loading
    openLoadingSpinner();

    let typeOption;
    Promise.all([API.getOfficeName()], search_onClick())
      .then((res) => {
        typeOption = res[0] ? res[0].data : []; // ddlOffice
      })
      .catch((err) => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Data detail
        setDdlOffice({ ...ddlOffice, options: typeOption });
      });
  }, [allowRoute]);

  // Event click button "Clear" then init screen
  const [currentPage, setCurrentPage] = useState("");
  const [pagingImplement, setPagingImplement] = useState({
    position: ["bottomCenter"],
    pageSize: 15,
    hideOnSinglePage: true,
  });
  const [btnclear, setBtnclear] = useState(0);
  const onClickClear = () => {
    setBtnclear(1);
    clearLocalStorage();
  };
  const onChange = (pagination) => {
    setCurrentPage(pagination.current);
    setBtnclear(2);
    clearLocalStorage();
  };

  const setLocalStorage = () => {
    localStorage.setItem("Page", currentPage);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("Page");
  };

  useEffect(() => {
    let pageNum = localStorage.getItem("Page");
    if (currentPage.length === 0 || btnclear == 1) {
      setCurrentPage(1);
      setPagingImplement({ ...pagingImplement, current: currentPage });
      setBtnclear(2);
    } else if (btnclear == 2) {
      setPagingImplement({ ...pagingImplement, current: currentPage });
      setBtnclear(0);
    } else if (pageNum != null) {
      setPagingImplement({ ...pagingImplement, current: parseInt(pageNum) });
      setBtnclear(0);
      clearLocalStorage();
    }
  });
  //-------------------------------------------------

  // Render
  return (
    <div className="des-content">
      <TitlePage name={lang.TITLE_DEL_PARENT_CONTRACT} />
      <BoxContent
        style={{
          margin: isPC ? "24px 16px" : 0,
          padding: isPC ? 24 : "0 16px",
        }}
      >
        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          onFinish={search_onClick}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col span={4}>
              <div className="ant-form-item-label">{lang.MADE}</div>
              <Input
                placeholder={lang.MADE}
                value={txtOyaseino.value}
                maxLength={8}
                onChange={(e) =>
                  setTxtOyaseino({
                    ...txtOyaseino,
                    value: e.target.value.toUpperCase(),
                  })
                }
              />
            </Col>
            <Col span={4}>
              <div className="ant-form-item-label">{lang.OFFICE_NAME}</div>
              <Select
                placeholder={lang.OFFICE_NAME}
                value={ddlOffice.value}
                className="w100"
                onChange={(v) => {
                  setDdlOffice({ ...ddlOffice, value: v });
                }}
              >
                {renderOption(ddlOffice.options)}
              </Select>
            </Col>
            <Col span={4}>
              <div className="ant-form-item-label">{lang.DEPARTMENT_NAME}</div>
              <Input
                placeholder={lang.DEPARTMENT_NAME}
                value={txtDepartmentnm.value}
                onChange={(e) =>
                  setTxtDepartmentnm({
                    ...txtDepartmentnm,
                    value: e.target.value.toUpperCase(),
                  })
                }
              />
            </Col>
            <Col span={4}>
              <div className="ant-form-item-label">
                {lang.REPRESENTATIVE_NAME}
              </div>
              <Input
                placeholder={lang.REPRESENTATIVE_NAME}
                value={txtRepresentativenm.value}
                onChange={(e) =>
                  setTxtRepresentativenm({
                    ...txtRepresentativenm,
                    value: e.target.value.toUpperCase(),
                  })
                }
              />
            </Col>
          </Row>
          {/* 2 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col lg={8} md={12} xs={24}>
              <div className="ant-form-item-label">
                {lang.CONSTRUCTION_NAME}
              </div>
              <Input
                placeholder={lang.CONSTRUCTION_NAME}
                value={txtKjiryknm.value}
                maxLength={255}
                onChange={(e) =>
                  setTxtKjiryknm({
                    ...txtKjiryknm,
                    value: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={8} md={12} xs={24}>
              <div className="ant-form-item-label">{lang.CUSTOMER_NAME}</div>
              <Input
                placeholder={lang.CUSTOMER_NAME}
                value={txtCustomernn.value}
                onChange={(e) =>
                  setTxtCustomernn({
                    ...txtCustomernn,
                    value: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          {/* 3 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col lg={8} md={12} xs={24}>
              <div className="ant-form-item-label">{lang.CONTRACT_TERM}</div>
              <Row>
                <Col span={8}>
                  <DatePicker
                    // size="large"
                    format={KEY.DATE_DEFAULT}
                    value={txtJyuymd1.value}
                    placeholder={KEY.DATE_DEFAULT}
                    className="w100"
                    onChange={(v) => {
                      setTxtJyuymd1({
                        ...txtJyuymd1,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Col>
                <Col span={4} className="center" style={{ padding: "7px" }}>
                  ～
                </Col>
                <Col span={8}>
                  <DatePicker
                    // size="large"
                    format={KEY.DATE_DEFAULT}
                    value={txtJyuymd2.value}
                    placeholder={KEY.DATE_DEFAULT}
                    className="w100"
                    onChange={(v) => {
                      setTxtJyuymd2({
                        ...txtJyuymd2,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={8} md={12} xs={24}>
              <div className="ant-form-item-label">
                {lang.CONFIRM_CHECKED_DATE}
              </div>
              <Row>
                <Col span={8}>
                  <DatePicker
                    // size="large"
                    format={KEY.DATE_DEFAULT}
                    value={txtSeinoknrymd1.value}
                    placeholder={KEY.DATE_DEFAULT}
                    className="w100"
                    onChange={(v) => {
                      setTxtSeinoknrymd1({
                        ...txtSeinoknrymd1,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Col>
                <Col span={4} className="center" style={{ padding: "7px" }}>
                  ～
                </Col>
                <Col span={8}>
                  <DatePicker
                    // size="large"
                    format={KEY.DATE_DEFAULT}
                    value={txtSeinoknrymd2.value}
                    placeholder={KEY.DATE_DEFAULT}
                    className="w100"
                    onChange={(v) => {
                      setTxtSeinoknrymd2({
                        ...txtSeinoknrymd2,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Col>
              </Row>
            </Col>
            <Col flex="auto"></Col>
            {/* Button Search/Clear */}
            <Col
              flex="none"
              className="right"
              style={isPC ? null : { padding: "8px 4px" }}
            >
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col>
                  <Button
                    onClick={onClickClear}
                    htmlType="submit"
                    className="buttonPC button--info wAuto"
                  >
                    <SearchOutlined className="" />
                    {lang.SEARCH}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--outline --todo-- wAuto ml10"
                    onClick={clearInputSearch}
                  >
                    <ClearOutlined />
                    {lang.CLEAR}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </BoxContent>

      <BoxContent
        style={{
          margin: isPC ? "24px 16px 0px 16px" : 0,
          padding: isPC ? 24 : "12px 24px",
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}></Col>
          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        {isPC ? (
          <Table
            rowKey={(record) => record.id}
            columns={initColumns}
            dataSource={grid.data}
            current={currentPage}
            pagination={pagingImplement}
            onChange={onChange}
            scroll={{ x: 2450 }}
            sticky={{ offsetHeader: "54px", offsetScroll: "55px" }}
          />
        ) : (
          []
        )}
      </BoxContent>
      {/* two button START */}
      <FooterPage>
        <Row justify="space-between " style={{ marginRight: "20px" }}>
          <col span={10}></col>
          <Col>
            <Button
              lg={8}
              md={12}
              xs={24}
              htmlType="submit"
              disabled={disabledButtonRevertAll}
              className="buttonPC --todo-- wAuto ml10 disabled"
              onClick={RevertALl_click}
            >
              <RollbackOutlined />
              一括復旧
            </Button>
            <Button
              lg={8}
              md={12}
              xs={24}
              htmlType="submit"
              className="buttonPC button--outline --todo-- wAuto ml10"
              onClick={() => history.push(`/ContractParentManagement`)}
            >
              <RollbackOutlined />
              戻る
            </Button>
          </Col>
        </Row>
      </FooterPage>
      {/* two button END */}
      <Loading show={showLoading}></Loading>
    </div>
  );
}

export default ParentContractIsDeleted;
