import React from "react";
import { Col, Row } from "antd";

function RowInput({ box, text, required, labelCol = 6, ...props }) {
  return (
    <div className={box ? "row-input-box" : "row-input"}>
      <Row>
        <Col span={labelCol} className={text ? "" : "row-input-col-first"}>
          <Row justify="space-between">
            <Col span={24} className="right pr20">
              <label className="row-input-label ">{props.name}</label>
              {required && <label className="blod ml5 mark-required">*</label>}
            </Col>
          </Row>
        </Col>
        <Col span={24 - labelCol} className="row-input-col-2">
          {props.children}
        </Col>
      </Row>
    </div>
  );
}

export default RowInput;
