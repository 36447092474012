import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  Table,
  Space,
  Input,
  Modal,
  Button,
  DatePicker,
  Form,
  Tooltip,
  Checkbox,
  Select,
} from "antd";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import FooterPage from "../../component/footer/footerPage";
import { useHistory } from "react-router-dom"; //useLocation
import TitlePage from "../../component/Desktop/titlePage";
import Loading from "../../component/Desktop/loading/loading";
import API from "../../api/backend/contractParent";
import APIUser from "../../api/backend/managementRole";
import classDdl from "../../models/control/dropdownlist";

import {
  MSG_CATCH,
  INPUT_DATE,
  showMessage,
  getQuerySearch,
  int2DateString,
  pushObject2Query,
  getDateValue,
  getTxtValue,
  renderOptionCanInputSearch,
} from "../../constants/utils";

import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";

import {
  CheckCircleOutlined,
  MinusCircleOutlined,
  SearchOutlined,
  ClearOutlined,
  DeleteOutlined,
  CloudDownloadOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import BoxContent from "../../component/Desktop/content";
import { useMediaQuery } from "react-responsive";
import objContractDelete from "./objContractDelete";
import { CSVLink } from "react-csv";
import { formatNumber } from "../../utils/helper";
import { setSearchParam } from "../../redux/searchContract";

const objClear = { value: null, disabled: true, checked: false };
let objSearchOrigin = {
  title: null,
  oyaseino: null,
  kjiryknm: null,
  startDate1: null,
  endDate1: null,
  startDate2: null,
  endDate2: null,
  officenm: null,
  departmentnm: null,
  representativenm: null,
  customernn: null,
  initS: null,
  dateS: null,
  typeFlg: null,
};

let objSearch = { ...objSearchOrigin };
objSearch = getQuerySearch();

function ContractParent_List({ history: historyPage }) {
  const [checkAll, setCheckAll] = useState(false);
  const [disabledButtonDeleteAll, setDisabledButtonDeleteAll] = useState(true);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  // Step1: Update param
  objSearch = getQuerySearch();

  // if (historyPage.action === "POP") {
  //   objSearch = getQuerySearch();
  // } else {
  //   objSearch = { ...objSearchOrigin };
  // }

  const { allowRoute } = useSelector((state) => state.auth);

  const history = useHistory();
  const dispatch = useDispatch();
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };
  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  const [formSearch] = Form.useForm();

  //  Grid
  const [grid, setGrid] = useState(new classGrid());
  // const [lstNoLicense, setLstNoLicense] = useState();

  const [isDownloadCSV, setIsDownloadCSV] = useState(true);

  // Input search
  // DungDV21
  const [txtOyaseino, setTxtOyaseino] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.oyaseino),
  });

  const [ddlOffice, setDdlOffice] = useState({
    ...new classDdl(),
    value: getTxtValue(objSearch.officenm),
  });

  const [ddlDepartmentnm, setDdlDepartmentnm] = useState({
    ...new classDdl(),
    value: getTxtValue(objSearch.departmentnm),
  });

  const [ddlRepresentativenm, setDdlRepresentativenm] = useState({
    ...new classDdl(),
    value: getTxtValue(objSearch.representativenm),
  });

  const [txtKjiryknm, setTxtKjiryknm] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.kjiryknm),
  });

  const [txtCustomernn, setTxtCustomernn] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.customernn),
  });

  const [txtStartDate1, setTxtStartDate1] = useState({
    ...new classText(),
    value: getDateValue(objSearch.startDate1),
  });
  const [txtEndDate1, settxtEndDate1] = useState({
    ...new classText(),
    value: getDateValue(objSearch.endDate1),
  });

  const [txtStartDate2, setTxtStartDate2] = useState({
    ...new classText(),
    value: getDateValue(objSearch.startDate2),
  });

  const [txtEndDate2, settxtEndDate2] = useState({
    ...new classText(),
    value: getDateValue(objSearch.endDate2),
  });

  const [txtInitInfo, setTxtInitInfo] = useState({
    ...new classText(),
    value: getTxtValue(objSearch.initS),
  });

  let listContractDelete = [];
  const prepareDataDelete = () => {
    grid.data.map((v) => {
      let contractDelete = new objContractDelete();
      if (v.checked) {
        contractDelete.Oyaseino = v.oyaseino;
        contractDelete.Csncb1 = v.csncB1;
        listContractDelete.push(contractDelete);
      }
    });
  };

  let [ddlType] = useState({
    //setddlType
    ...new classText(),
    value: getTxtValue(objSearch.typeNotify),
  });

  const [eventClearFlg, setEventClearFlg] = useState(
    getTxtValue(objSearch.typeFlg) || true
  );

  // Step2: Sync input <-> url
  const syncInput = () => {
    // DungDV21
    objSearch.oyaseino = txtOyaseino.value ? txtOyaseino.value.trim() : null;
    objSearch.officenm = ddlOffice.value;
    objSearch.departmentnm = ddlDepartmentnm.value;
    objSearch.representativenm = ddlRepresentativenm.value;
    objSearch.kjiryknm = txtKjiryknm.value ? txtKjiryknm.value.trim() : null;
    objSearch.customernn = txtCustomernn.value
      ? txtCustomernn.value.trim()
      : null;
    objSearch.startDate1 = txtStartDate1.value;
    objSearch.endDate1 = txtEndDate1.value;
    objSearch.startDate2 = txtStartDate2.value;
    objSearch.endDate2 = txtEndDate2.value;
    objSearch.initS = txtInitInfo.value ? txtInitInfo.value.trim() : null;
    objSearch.typeFlg = eventClearFlg;

    // Push to url
    pushObject2Query(objSearch);
  };

  // Search
  const search_onClick = () => {
    // Loading
    openLoadingSpinner();

    // input to objectSearch; push url
    syncInput();

    // Get list ParentContract no liscence
    // API.getListSubNoLicense({
    //   params: {
    //     typeFlg: '0'
    //   },
    // }).then((res) => {
    //   setLstNoLicense(res ? res.data : []);
    // });

    // Update format
    objSearch = getQuerySearch();
    objSearch.startDate1 = int2DateString(Number(objSearch.startDate1));
    objSearch.endDate1 = int2DateString(Number(objSearch.endDate1));
    objSearch.startDate2 = int2DateString(Number(objSearch.startDate2));
    objSearch.endDate2 = int2DateString(Number(objSearch.endDate2));
    objSearch.type = ddlType.value;
    //objSearch.typeFlg = eventClearFlg;

    // Call
    return API.search({
      params: {
        ...objSearch,
      },
    })
      .then((res) => {
        // Update key
        let grid = res.data;
        grid = grid.map((v, i) => {
          v["rowKey"] = i;
          v.checked = false;
          return v;
        });

        // Set data to grid
        setGrid({ ...grid, data: grid, count: res.data.length });
        if (res.data.length < 16) {
          window.scroll(0, 20);
        }
      })
      .catch(() => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        closeLoadingSpinner();
      });
  };

  useEffect(() => {
    if (txtOyaseino.value || ddlOffice.value || ddlDepartmentnm.value || ddlRepresentativenm.value ||
      txtKjiryknm.value || txtCustomernn.value || txtStartDate1.value || txtEndDate1.value || txtStartDate2.value || txtEndDate2.value) {
      setIsDownloadCSV(false);
    } else {
      setIsDownloadCSV(true);
    }
  }, [txtOyaseino, ddlOffice, ddlDepartmentnm, ddlRepresentativenm,
    txtKjiryknm, txtCustomernn, txtStartDate1, txtEndDate1, txtStartDate2, txtEndDate2]);

  // clearInputSearch
  const clearInputSearch = () => {
    setTxtOyaseino(objClear);
    setDdlOffice({ ...ddlOffice, ...objClear });
    setDdlDepartmentnm({ ...ddlDepartmentnm, ...objClear });
    setDdlRepresentativenm({ ...ddlRepresentativenm, ...objClear });
    setTxtCustomernn(objClear);
    setTxtKjiryknm(objClear);
    setTxtStartDate1(objClear);
    settxtEndDate1(objClear);
    setTxtStartDate2(objClear);
    settxtEndDate2(objClear);
    formSearch.resetFields();
    formSearch.submit();
    onClickClear();
  };

  // Column GRID
  const initColumns = [
    {
      title: (
        <Checkbox
          disabled={!grid.data.length}
          checked={checkAll}
          onChange={(e) => setCheckAll(e.target.checked)}
        ></Checkbox>
      ),
      align: "center",
      dataIndex: "checked",
      fixed: "left",
      render: (text, row) => (
        <Checkbox
          checked={row.checked}
          onChange={hancleChangeChecked(row.rowKey)}
        />
      ),
      width: "2%",
    },
    {
      title: lang.MADE,
      dataIndex: "oyaseino",
      width: "7%",
      fixed: "left",
      align: "center",

      render: (text, row) => {
        return (
          <span
            className="underline"
            onClick={() => {
              history.push(
                `/ContractParent/Detail?oyaseino=${row.oyaseino}&&csncb1=${row.csncB1}`,
                { from: `${window.location.pathname}${window.location.search}` }
              );
              setLocalStorage();
            }}
          >
            {row.typeValue}
            {text}
          </span>
        );
      },
    },
    {
      title: lang.SUBCONTRACTOR_PERMISSION_NUMBER,
      width: "7%",
      align: "center",
      render: (row) => {
        // let valKSGKYKBNG;
        // if (lstNoLicense) {
        //   let oya = lstNoLicense.find((item) => item.oyaseino === row.oyaseino);
        //   if (oya) {
        //     valKSGKYKBNG = "!!下請有　登録無";
        //   }
        // }
        return <span style={{ color: "red" }}>{row.ksgkykbng}</span>;
      },
    },
    {
      title: lang.REPRESENTATIVE_OFF_NAME,
      dataIndex: "officeName",
      width: "9%",
      align: "center",
    },
    {
      title: lang.DEPARTMENT_NAME,
      dataIndex: "hm17_BUNM",
      width: "12%",
      align: "left",
    },
    {
      title: lang.REPRESENTATIVE_NAME,
      dataIndex: "representativeName",
      width: "9%",
      align: "center",
    },
    {
      title: lang.CONSTRUCTION_NAME,
      dataIndex: "kjiryknm",
      width: "30%",
      align: "left",
    },
    {
      title: lang.CONTRACT_TERM,
      dataIndex: "display_JYUYMD",
      align: "center",
      width: "6%",
    },
    {
      title: lang.BOOKER_NAME,
      dataIndex: "hm20_TRHSAKNM1",
      align: "left",
      width: "15%",
    },
    {
      title: lang.CONFIRM_CHECKED_DATE,
      dataIndex: "display_KYKNKIYMD",
      align: "center",
      width: "6%",
    },
    {
      title: () => {
        return <div>{lang.ORDER_AMOUNT}<br /><div style={{ fontSize: 10 }}>（千円）（税抜）</div></div>;
      },
      dataIndex: "jyuznkkg",
      align: "right",
      width: "6%",
      render: (text, row) => {
        return formatNumber(row.jyuznkkg);
      },
    },
    {
      title: lang.ACTION,
      align: "right",
      width: 180,
      fixed: "right",
      render: (row) => {
        let disabledDelete = false;
        return (
          <>
            {/* Edit */}
            <Tooltip title={lang.HOVER_CONFIRM}>
              {/* DungDV21 */}
              <Button
                lg={8}
                md={12}
                xs={24}
                htmlType="submit"
                className="buttonPC button--info wAuto"
                style={{
                  right: "10px",
                }}
                onClick={() => {
                  showMessageAceptConfirm(row);
                }}
                hidden={row.flaG_DEL === "2"}
              >
                <CheckCircleOutlined className="" />
                {lang.HOVER_CONFIRM}
              </Button>
            </Tooltip>
            <Tooltip title={lang.HOVER_CANCEL}>
              {/* DungDV21 */}
              <Button
                lg={8}
                md={12}
                xs={24}
                htmlType="submit"
                className="buttonPC button--info wAuto"
                style={{
                  right: "10px",
                }}
                onClick={() => {
                  showMessageReturnAceptConfirm(row);
                }}
                hidden={row.flaG_DEL === null}
              >
                <CloseCircleOutlined className="" />
                {lang.HOVER_CANCEL}
              </Button>
            </Tooltip>
            {/* Delete */}
            <Tooltip title={lang.HOVER_DELETE}>
              <Button
                disabled={disabledDelete}
                className="mr5 btnDelete"
                onClick={() => {
                  showMessageDeleteConfirm(row);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },

  ];

  //checked
  const hancleChangeChecked = (rowKey) => (e) => {
    const newGrid = JSON.parse(JSON.stringify(grid));
    const index = newGrid.data.findIndex((item) => item.rowKey === rowKey);
    if (index >= 0) {
      newGrid.data[index].checked = e.target.checked;
      setGrid(newGrid);
    }
  };

  // Popup
  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  function NewLineContent(props) {
    const text = props.text;
    const newText = text.split("\n");
    return (
      <div>
        <p>{newText[0]}</p>
        <p style={{ color: "red" }}>{newText[1]}</p>
      </div>
    );
  }

  const showMessageDeleteConfirm = (rowData) => {
    // Action del
    const funcDel = async () => {
      openLoadingSpinner();

      // API Del
      try {
        // Close warning
        Modal.destroyAll();
        const objContractDel = new objContractDelete(
          rowData.oyaseino,
          rowData.csncB1
        );
        listContractDelete.push(objContractDel);
        await API.delete(listContractDelete);
        // API Search
        await search_onClick();

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.CONFIRM_DELETE,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  const DeleteAll_Click = () => {
    const funcDel = async () => {
      openLoadingSpinner();

      // API Del
      try {
        // Close warning
        Modal.destroyAll();
        prepareDataDelete();
        await API.delete(listContractDelete);
        // API Search
        await search_onClick();

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.DELETE_CHECKEDBOX_CONFIRM,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  const showMessageAceptConfirm = (rowData) => {
    // Action update
    const funcDel = async () => {
      openLoadingSpinner();

      // API Update
      try {
        // Close warning
        Modal.destroyAll();

        await API.accept(rowData.oyaseino, rowData.csncB1, true);
        // API Search
        await search_onClick();

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: (
        <NewLineContent
          text={
            COMMON_CONSTANT.CONFIRM_CONTRACT_ACCEPT +
            "\n" +
            COMMON_CONSTANT.NOTE_ACCEPT
          }
        />
      ),
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  const showMessageReturnAceptConfirm = (rowData) => {
    // Action update
    const funcDel = async () => {
      openLoadingSpinner();

      // API Update
      try {
        // Close warning
        Modal.destroyAll();

        await API.accept(rowData.oyaseino, rowData.csncB1, false);
        // API Search
        await search_onClick();

        // Open success
        showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      } catch (ex) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: (
        <NewLineContent
          text={
            COMMON_CONSTANT.CONFIRM_RETURN_ACCEPT +
            "\n" +
            COMMON_CONSTANT.NOTE_RETURN_ACCEPT
          }
        />
      ),
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  // Init
  useEffect(() => {
    // Open loading
    openLoadingSpinner();
    let typeOption;
    let lstDepartmentName;
    let lstRepresentativeName;
    Promise.all(
      [
        API.getOfficeName(),
        API.getDepartmentName(),
        API.getRepresentativeName(),
      ],
      search_onClick()
    )
      .then((res) => {
        typeOption = res[0] ? res[0].data : []; // ddlOffice
        lstDepartmentName = res[1] ? res[1].data : []; // ddlDepartment
        lstRepresentativeName = res[2] ? res[2].data : [];
      })
      .catch((err) => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Data detail
        if (objSearch.officenm) {
          let arr = Object.keys(typeOption).reduce((array, key) => {
            return [...array, { key: typeOption[key] }];
          }, []);
          let ddlOffSel = arr.find(
            (item) => item.key.key == objSearch.officenm
          );
          setDdlOffice({
            ...ddlOffice,
            options: typeOption,
            value: ddlOffSel.key.value,
          });
        } else {
          setDdlOffice({ ...ddlOffice, options: typeOption });
        }
        // Data combobox department name
        if (objSearch.departmentnm) {
          let arr = Object.keys(lstDepartmentName).reduce((array, key) => {
            return [...array, { key: lstDepartmentName[key] }];
          }, []);
          let ddlOffSel = arr.find(
            (item) => item.key.key == objSearch.departmentnm
          );
          setDdlDepartmentnm({
            ...ddlDepartmentnm,
            options: lstDepartmentName,
            value: ddlOffSel.key.value,
          });
        } else {
          setDdlDepartmentnm({
            ...ddlDepartmentnm,
            options: lstDepartmentName,
          });
        }
        // Data detail
        if (objSearch.representativenm) {
          let arr = Object.keys(lstRepresentativeName).reduce((array, key) => {
            return [...array, { key: lstRepresentativeName[key] }];
          }, []);
          let ddlOffSel = arr.find(
            (item) => item.key.key == objSearch.representativenm
          );
          setDdlRepresentativenm({
            ...ddlRepresentativenm,
            options: lstRepresentativeName,
            value: ddlOffSel.key.value,
          });
        } else {
          setDdlRepresentativenm({
            ...ddlRepresentativenm,
            options: lstRepresentativeName,
          });
        }
      })
      .then(() => {
        closeLoadingSpinner();
      });
  }, [allowRoute]); //[allowRoute]

  useEffect(() => {
    const newGrid = JSON.parse(JSON.stringify(grid));
    if (checkAll) {
      newGrid.data = newGrid.data.map((item) => {
        item.checked = true;
        return item;
      });
      setGrid(newGrid);
    } else {
      if (
        grid.data.length === grid.data.filter((item) => item.checked).length
      ) {
        newGrid.data = newGrid.data.map((item) => {
          item.checked = false;
          return item;
        });
        setGrid(newGrid);
      }
    }
  }, [checkAll]);

  useEffect(() => {
    if (grid.data.length) {
      if (
        grid.data.length === grid.data.filter((item) => item.checked).length
      ) {
        setCheckAll(true);
      } else {
        setCheckAll(false);
      }
    }
  }, [grid.data]);

  useEffect(() => {
    if (grid.data.length) {
      if (
        grid.data.length ===
        grid.data.filter((item) => item.checked == true).length
      ) {
        setCheckAll(true);
      } else {
        setCheckAll(false);
      }
      if (grid.data.filter((item) => item.checked == true).length > 0) {
        setDisabledButtonDeleteAll(false);
      } else {
        setDisabledButtonDeleteAll(true);
      }
    } else {
      setDisabledButtonDeleteAll(true);
    }
  }, [grid.data.filter((item) => item.checked == true).length]);

  // Event click button "Clear" then init screen
  const [currentPage, setCurrentPage] = useState("");
  const [pagingImplement, setPagingImplement] = useState({
    position: ["bottomCenter"],
    pageSize: 15,
    hideOnSinglePage: true,
  });
  const userId = JSON.parse(localStorage.getItem("uInfo")).userName || '';
  const [btnclear, setBtnclear] = useState(0);
  const onClickClear = () => {
    setBtnclear(1);
    clearLocalStorage();
  };
  const onChange = (pagination) => {
    setCurrentPage(pagination.current);
    setBtnclear(2);
    clearLocalStorage();
    localStorage.removeItem("Back");
  };

  const setLocalStorage = () => {
    localStorage.setItem("Page", currentPage);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("Page");
  };

  useEffect(() => {
    let pageNum = localStorage.getItem("Page");
    let pageNumBack = localStorage.getItem("Back");
    if (currentPage.length === 0 || btnclear == 1) {
      setCurrentPage(1);
      setPagingImplement({ ...pagingImplement, current: currentPage });
      setBtnclear(2);
    } else if (btnclear == 2) {
      setPagingImplement({ ...pagingImplement, current: currentPage });
      setBtnclear(0);
    } else if (pageNum != null) {
      if (typeof pageNumBack === "undefined" || pageNumBack == null) {
        localStorage.setItem("Back", parseInt(pageNum));
        pageNumBack = pageNum;
      }
      if (pageNumBack != pageNum) {
        pageNum = pageNumBack;
      }
      setPagingImplement({ ...pagingImplement, current: parseInt(pageNum) });
      setBtnclear(0);
      clearLocalStorage();
    }
    //update infomation user
    const statusUpdate = localStorage.getItem("StatusUpdateUser");
    if ((statusUpdate == null || statusUpdate == 0) && userId != '') {
      localStorage.setItem("StatusUpdateUser", 1);
      UpdateInfoUser();
    }
  });

  // Search
  const UpdateInfoUser = async () => {
    onClickClear();
    // Loading
    openLoadingSpinner();
    //Call
    return await APIUser.searchMappingUserAD({
      fullname: '',
      shiID: userId,
      offset: 0,
      limit: 100,
    })
      .then((res) => {
        if (res.data) {
          let listUser = [];
          let userData = res.data.data;
          if (userData.length > 0) {
            const userArr = userData[0].mail.split("@");
            let user = {
              userName: userArr[0],
              displayName: userData[0].displayName,
              givenName: userData[0].givenName,
              surname: userData[0].surname,
              companyName: userData[0].companyName,
              department: userData[0].department,
              canDelete: true,
              email: userData[0].mail !== undefined ? userData[0].mail : null,
              isChange: 4,
              role: "",
              siteOfficeMasterCode: null,
            };
            listUser.push(user);

            // Call
            return APIUser.SaveMappingUser(listUser)
              .then((res) => {
                if (res) {
                  if (res.data === 0) {
                  } else {
                    // localStorage.setItem("StatusUpdateUser", 0);
                    // showMessageError(MSG_CATCH());
                  }
                }
              })
              .catch((error) => {
                console.log(error);
                // localStorage.setItem("StatusUpdateUser", 0);
                // showMessageError(MSG_CATCH());
              })
              .then(() => {
              });
          }

        } else {
          // localStorage.setItem("StatusUpdateUser", 0);
          // showMessageError(MSG_CATCH());
        }
      })
      .catch((error) => {
        // localStorage.setItem("StatusUpdateUser", 0);
        // showMessageError(MSG_CATCH());
      })
      .then(() => {
        // Close loading
        closeLoadingSpinner();
      });
  };
  //-------------------------------------------------

  // Export CSV
  const headers = [
    // { label: "営業所名", key: "officeName" },
    // { label: "部門名", key: "bunm" },
    // { label: "代表者名", key: "representativeName" },
    { label: "製番", key: "oyaseino" },
    { label: "工事名称", key: "kji1nm" },
    // { label: "所在地名", key: "trhsaknm1_1" },
    // { label: "納入先住所（都道府県）", key: "trhadrs_1" },
    // { label: "納入先住所（市町村以下）", key: "trhadrs_2" },
    // { label: "元請契約年月日", key: "jyuymd" },
    { label: "契約先名称", key: "trhsaknm1_2" },
    // { label: "元請検査完了年月日", key: "kyknkiymd" },
    // { label: "確定（未・済）", key: "approve" },
    { label: "下請契約年月日", key: "hacymd" },
    { label: "下請人名", key: "trhsaknm1_3" },
    { label: "品名", key: "producName" },
    { label: "下請人住所", key: "trhadrs_3" },
    { label: "下請人の建設業許可番号", key: "ksgkykbng" },
    { label: "下請人の区分", key: "snychscd" },
    { label: "資本金", key: "sihonkg" },
    { label: "下請検査完了年月日", key: "knsymd" },
    { label: "下請引渡年月日", key: "deliveryKnsymd" },
    { label: "検収区分", key: "dnk" },
    { label: "下請代金支払条件", key: "shrzykcd" },
    { label: "表示用支払条件", key: "termOfPayName" },
    { label: "下請代金支払状況", key: "paymentStatus" },
    { label: "添付書類", key: "attachedDocument" },
    { label: "下請簿エントリー有無", key: "subcontractorEntryValue" },
  ];
  const [dataCSV, setDataCSV] = useState([]);
  const csvLink = useRef();

  const getTransactionData = async () => {
    await export_CSV();
    // csvLink.current.link.click();
  };

  const export_CSV = () => {
    // Loading
    openLoadingSpinner();

    // // Update format
    objSearch = getQuerySearch();
    objSearch.oyaseino = txtOyaseino.value;
    objSearch.officenm = ddlOffice.value;
    objSearch.departmentnm = ddlDepartmentnm.value;
    objSearch.representativenm = ddlRepresentativenm.value;
    objSearch.kjiryknm = txtKjiryknm.value;
    objSearch.customernn = txtCustomernn.value;
    objSearch.startDate1 = int2DateString(Number(txtStartDate1.value));
    objSearch.endDate1 = int2DateString(Number(txtEndDate1.value));
    objSearch.startDate2 = int2DateString(Number(txtStartDate2.value));
    objSearch.endDate2 = int2DateString(Number(txtEndDate2.value));
    objSearch.type = ddlType.value;

    // Call
    return API.exportcsv({
      params: {
        ...objSearch,
      },
    })
      .then((res) => {
        if (res.data && res.data.length > 0) {
          let dataOuput = res.data;
          setDataCSV(dataOuput);
          csvLink.current.link.click();
        } else {
          showMessage(KEY.WARMING, COMMON_CONSTANT.THERE_IS_NO_SUBCONTRACTOR);
        }
      })
      .catch(() => {
        showMessageError(MSG_CATCH());
      })
      .then(() => {
        closeLoadingSpinner();
      });
  };
  //-------------------------------------------------

  // Render
  return (
    <div className="des-content">
      <TitlePage name={lang.CONTRACT_PARENT} />
      <BoxContent
        style={{
          margin: isPC ? "24px 16px" : 0,
          padding: isPC ? 24 : "0 16px",
        }}
      >
        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          onFinish={search_onClick}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col span={0}>
              <Input
                value={txtInitInfo.value}
                maxLength={8}
                onChange={(e) =>
                  setTxtInitInfo({
                    ...txtInitInfo,
                    value: e.target.value.toUpperCase(),
                  })
                }
              />
            </Col>
            <Col span={4}>
              <div className="ant-form-item-label">{lang.OYASEINO}</div>
              <Input
                placeholder={lang.OYASEINO}
                value={txtOyaseino.value}
                maxLength={8}
                onChange={(e) =>
                  setTxtOyaseino({
                    ...txtOyaseino,
                    value: e.target.value.toUpperCase(),
                  })
                }
              />
            </Col>
            <Col span={4}>
              <div className="ant-form-item-label">{lang.OFFICE_NAME}</div>
              <Select
                allowClear
                showSearch
                placeholder={lang.OFFICE_NAME}
                optionFilterProp="children"
                value={ddlOffice.value}
                className="w100"
                onChange={(v) => {
                  setDdlOffice({ ...ddlOffice, value: v });
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(input.toUpperCase()) >= 0
                }
              >
                {renderOptionCanInputSearch(ddlOffice.options)}
              </Select>
            </Col>
            <Col span={8}>
              <div className="ant-form-item-label">{lang.DEPARTMENT_NAME}</div>
              <Select
                allowClear
                showSearch
                placeholder={lang.DEPARTMENT_NAME}
                optionFilterProp="children"
                value={ddlDepartmentnm.value}
                className="w100"
                onChange={(v) => {
                  setDdlDepartmentnm({
                    ...ddlDepartmentnm,
                    value: v === undefined ? null : v,
                  });
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(input.toUpperCase()) >= 0
                }
              >
                {renderOptionCanInputSearch(ddlDepartmentnm.options)}
              </Select>
            </Col>
            <Col span={4}>
              <div className="ant-form-item-label">
                {lang.REPRESENTATIVE_NAME}
              </div>
              <Select
                allowClear
                showSearch
                placeholder={lang.REPRESENTATIVE_NAME}
                optionFilterProp="children"
                value={ddlRepresentativenm.value}
                className="w100"
                onChange={(v) => {
                  setDdlRepresentativenm({ ...ddlRepresentativenm, value: v });
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(input.toUpperCase()) >= 0
                }
              >
                {renderOptionCanInputSearch(ddlRepresentativenm.options)}
              </Select>
            </Col>
          </Row>
          {/* 2 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col lg={8} md={12} xs={24}>
              <div className="ant-form-item-label">{lang.KJIRYKNM}</div>
              <Input
                placeholder={lang.KJIRYKNM}
                value={txtKjiryknm.value}
                onChange={(e) =>
                  setTxtKjiryknm({
                    ...txtKjiryknm,
                    value: e.target.value,
                  })
                }
              />
            </Col>
            <Col lg={8} md={12} xs={24}>
              <div className="ant-form-item-label">{lang.CUSTOMER_NAME}</div>
              <Input
                placeholder={lang.CUSTOMER_NAME}
                value={txtCustomernn.value}
                onChange={(e) =>
                  setTxtCustomernn({
                    ...txtCustomernn,
                    value: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          {/* 3 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col lg={8} md={12} xs={24}>
              <div className="ant-form-item-label">{lang.DATE_FROM}</div>
              <Row>
                <Col span={8}>
                  <DatePicker
                    // size="large"
                    format={KEY.DATE_DEFAULT}
                    value={txtStartDate1.value}
                    placeholder={KEY.DATE_DEFAULT}
                    className="w100"
                    onChange={(v) => {
                      setTxtStartDate1({
                        ...txtStartDate1,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Col>
                <Col span={4} className="center" style={{ padding: "7px" }}>
                  ～
                </Col>
                <Col span={8}>
                  <DatePicker
                    // size="large"
                    format={KEY.DATE_DEFAULT}
                    value={txtEndDate1.value}
                    placeholder={KEY.DATE_DEFAULT}
                    className="w100"
                    onChange={(v) => {
                      settxtEndDate1({
                        ...txtEndDate1,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Col>
              </Row>
            </Col>

            <Col lg={8} md={12} xs={24}>
              <div className="ant-form-item-label">{lang.DATE_TO}</div>

              <Row>
                <Col span={8}>
                  <DatePicker
                    // size="large"
                    format={KEY.DATE_DEFAULT}
                    value={txtStartDate2.value}
                    placeholder={KEY.DATE_DEFAULT}
                    className="w100"
                    onChange={(v) => {
                      setTxtStartDate2({
                        ...txtStartDate2,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Col>
                <Col span={4} className="center" style={{ padding: "7px" }}>
                  ～
                </Col>
                <Col span={8}>
                  <DatePicker
                    // size="large"
                    format={KEY.DATE_DEFAULT}
                    value={txtEndDate2.value}
                    placeholder={KEY.DATE_DEFAULT}
                    className="w100"
                    onChange={(v) => {
                      settxtEndDate2({
                        ...txtEndDate2,
                        value: v,
                      });
                    }}
                    {...INPUT_DATE}
                  />
                </Col>
              </Row>
            </Col>

            <Col flex="auto"></Col>
            {/* Button Search/Clear */}
            <Col
              flex="none"
              className="right"
              style={isPC ? null : { padding: "8px 4px" }}
            >
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col>
                  <Button
                    onClick={() => {
                      onClickClear();
                      txtInitInfo.value = "true";
                      localStorage.setItem("DATE_SEARCH", txtStartDate1.value);
                      setEventClearFlg(false);
                    }}
                    htmlType="submit"
                    className="buttonPC button--info wAuto"
                  >
                    <SearchOutlined className="" />
                    {lang.SEARCH}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--outline --todo-- wAuto ml10"
                    onClick={() => {
                      clearInputSearch();
                      dispatch(setSearchParam(""));
                      txtInitInfo.value = "false";
                      localStorage.setItem("DATE_SEARCH", txtStartDate1.value);
                      setEventClearFlg(true);
                    }}
                  >
                    <ClearOutlined />
                    {lang.CLEAR}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </BoxContent>

      <BoxContent
        style={{
          margin: isPC ? "24px 16px" : 0,
          padding: isPC ? 24 : "12px 24px",
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12} className="left">
            {/* Count */}
            <Space size={10} align="end"></Space>
          </Col>
          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="end">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        {isPC ? (
          <Table
            rowKey={(record) => record.id}
            columns={initColumns}
            dataSource={grid.data}
            current={currentPage}
            pagination={pagingImplement}
            onChange={onChange}
            scroll={{ x: 2450 }}
            sticky={{ offsetHeader: "54px", offsetScroll: "55px" }}
          />
        ) : (
          []
        )}
      </BoxContent>
      <FooterPage>
        <Row
          justify="space-between "
          style={{ float: "right", marginRight: "20px" }}
        >
          <col span={10}></col>
          <Col>
            <Button
              lg={8}
              md={12}
              xs={24}
              style={{
                right: "10px",
              }}
              htmlType="submit"
              className="buttonPC button--info wAuto"
              onClick={getTransactionData}
              disabled={isDownloadCSV}
            >
              <CloudDownloadOutlined />
              建設業帳簿CSV出力
            </Button>
            <CSVLink
              data={dataCSV}
              headers={headers}
              filename="建設業帳簿CSVデータ.csv"
              className="hidden"
              ref={csvLink}
              target="_blank"
            />
            <Button
              lg={8}
              md={12}
              xs={24}
              style={{
                right: "10px",
              }}
              htmlType="submit"
              disabled={disabledButtonDeleteAll}
              className="buttonPC button--outline --todo-- wAuto ml10"
              onClick={DeleteAll_Click}
            >
              <DeleteOutlined />
              {lang.DELETE_ALL}
            </Button>
            <Button
              lg={8}
              md={12}
              xs={24}
              htmlType="submit"
              className="buttonPC button--info wAuto"
              onClick={() => {
                history.push(`/RevertParentContract`);
                setLocalStorage();
              }}
            >
              <MinusCircleOutlined />
              取消一覧
            </Button>
          </Col>
        </Row>
      </FooterPage>
      <Loading show={showLoading}></Loading>
    </div>
  );
}
export default ContractParent_List;
