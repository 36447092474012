import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import { loadingPage } from "./loading";
import Constants from "@src/constants/status.const";
import { getUnixTime, parseISO, fromUnixTime } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import CheckListGroupsClass from "@src/models/checkListGroups";
import { Modal } from "antd";
import format from "@src/utils/format";
import { Row, Col } from "antd";
import {
  lang,
  KEY,
  TYPE_INPUT,
} from "@src/constants/common.const";
import STATUS from "@src/constants/status.const";
const initialState = {
  currentUser: null,
  currentRouter: "/",
  data: [],
  dataTemplate: [],
  dataTemplateFiltered: [],
  dataSaved: [],
  dataRequests: [],
  dataHistory: [],
  checkListGroupSelected: [],
  dataGroups: [],
  checkListsName: [],
  checkResultStatus: [],
  allGroupsName: [],
  currentTabCLGroup: [],
  lengthCheckItem: 0,
  checkTime: null,
  timeChecks: null,
  checkTimeLength: 0,
  checklistGroupId: null,
  currentChecklistGroup: null,
  checklistTmp: null,
  checklistSelected: null,
  checklistId: "",
  machineItem: null,
  machineId: "",
  currentMachine: null,
  nextMachine: null,
  prevMachine: null,
  currentNote: {},
  currentStatus: null,
  currentWorkingStatus: {},
  currentSaveStatus: {},
  currentAttachment: {},
  isSearch: false,
  isUpdated: false,
  isUpdatedTime: false,
  selectedTab: lang.COMMON,
  indexSelectedTab: 0,
  modeTab: KEY.GROUP_TYPE_COMMON,
  idSelectTabCommon: "0",
  idSelectTabUser: "0",
  historyParams: {},
  page: "",
  tempBookmarked: false,
  tempBookmarkId: null,
  tempChecklistId: null,
  isLoadingTemplate: false,
  isLoadMore: false,
  isLoadMoreFiltered: false,
  isLoadingSaved: false,
  isLoadingHistory: false,
  isLoadingFilter: false,
  isLoadingGroupCL: false,
  isLoadingBookmark: false,
  offsetHistory: 0,
  totalHistory: 0,
  selectedGroup: "",
  currentRefCheckOrder: "",
  fullTimeCheck: false,
  offsetFilteredChecklist: 0,
  totalFilteredChecklist: 0,
  totalFilteredChecklist2: 0,
  offsetSavedChecklist: 0,
  totalSavedChecklist: 0,
  offsetTemplateChecklist: 0,
  offsetGroupChecklist: {},
  totalTemplateChecklist: 0,
  inputJudgment: null,
  modeInput: false,
  arrayWorkingStatus: [],
  historySearch: null,
  showAlert: false,
  updateDone: false,
  changeRoute: null,
  checklistUpdated: null,
  templateUpdatedStatus: false,
  showMessUploadImage: false,
  selectRow: {},
  showMultiChecker: false,
  dataMultiCheck: {},
  arrayCLToyota: [],
  arrayStatusTabs: [],
  isImportTemplate: false,
  arrayItemChart: [],
};
const temporaryId = "new-checklist";
const checkListSlice = createSlice({
  name: "checklist",
  initialState,
  reducers: {
    // this reducers = commit + mutation in vuex
    updateIsImportTemplate: (state, { payload }) => {
      state.isImportTemplate = payload;
    },
    addItemChart: (state, { payload }) => {
      state.arrayItemChart.push(payload);
    },
    removeItemChart: (state, { payload }) => {
      const arr = JSON.parse(JSON.stringify(state.arrayItemChart));
      state.arrayItemChart = arr.filter((item) => item !== payload);
    },
    updateArrayCLToyota: (state, { payload }) => {
      state.arrayCLToyota = payload;
    },
    updateArrayStatusTabs: (state, { payload }) => {
      state.arrayStatusTabs = payload;
    },
    updateAndChangeCurrentRoute: (state, { payload }) => {
      state.changeRoute = payload;
    },
    updateShowMultiChecker: (state, { payload }) => {
      state.showMultiChecker = payload;
    },
    updateDataMultiCheck: (state, { payload }) => {
      state.dataMultiCheck = payload;
    },
    updateShowMessageUploadImage: (state, { payload }) => {
      state.showMessUploadImage = payload;
    },
    selectRowSearch: (state, { payload }) => {
      state.selectRow = payload;
    },
    updateCurrentStatusCL: (state, { payload }) => {
      state.currentStatus = payload;
    },
    updateCurrentRouter: (state, { payload }) => {
      state.currentRouter = payload;
    },
    updateCurrentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    setShowAlert: (state, { payload }) => {
      state.showAlert = payload;
    },
    setLoadingBookmark: (state, { payload }) => {
      state.isLoadingBookmark = payload;
    },
    setStatusUpdateState: (state, { payload }) => {
      state.updateDone = payload;
    },
    setModeInput: (state, { payload }) => {
      state.modeInput = payload;
    },
    setArrayWorkingStatus: (state, { payload }) => {
      state.arrayWorkingStatus = payload;
    },
    setTab: (state, { payload }) => {
      state.indexSelectedTab = payload.indexSelectedTab;
      state.checklistGroupId = payload.checklistGroupId;
    },
    setTabMode: (state, { payload }) => {
      state.modeTab = payload;
    },
    setTabCommon: (state, { payload }) => {
      state.idSelectTabCommon = payload;
    },
    setTabUser: (state, { payload }) => {
      state.idSelectTabUser = payload;
    },
    updateChecklistName: (state, { payload }) => {
      state.checkListsName = payload;
    },
    updateCheckResultStatus: (state, { payload }) => {
      state.checkResultStatus = payload;
    },
    updateOffsetFilterChecklist: (state, { payload }) => {
      state.offsetFilteredChecklist = payload;
    },
    updateGroupsCLName: (state, { payload }) => {
      state.allGroupsName = payload;
    },
    setCurrentMachine: (state, { payload }) => {
      state.currentMachine = payload;
    },
    setNextMachine: (state, { payload }) => {
      state.nextMachine = payload;
    },
    setPreviousMachine: (state, { payload }) => {
      state.prevMachine = payload;
    },
    setCurrentNote: (state, { payload }) => {
      state.currentNote[payload.id] = payload.note;
    },
    setCurrentWorkingStatus: (state, { payload }) => {
      state.currentWorkingStatus[payload.id] = payload.value;
    },
    setCurrentSaveStatus: (state, { payload }) => {
      state.currentSaveStatus[payload.id] = payload.value;
    },
    setCurrentAttachment: (state, { payload }) => {
      state.currentAttachment[payload.id] = payload.attachment;
    },
    setCurrentCLGroup: (state, { payload }) => {
      state.currentChecklistGroup = payload;
      if (payload) {
        state.indexSelectedTab = payload.index;
        state.checklistGroupId = payload.id;
      }
    },
    setInputJudgment: (state, { payload }) => {
      state.inputJudgment = payload;
    },
    setChecklistId: (state, { payload }) => {
      const { id, page, isUpdated, checklist } = payload;
      let dataChecklist = JSON.parse(JSON.stringify(checklist));
      state.checklistId = id;
      state.page = page;
      if (isUpdated) {
        state.currentMachine = null;
        let currentChecklist = JSON.parse(JSON.stringify(getChecklist(state)));
        // dataChecklist
        const arrayMachine = [];
        dataChecklist.checklistGroups.forEach((group) => {
          group.checklistMachines.forEach((machine) => {
            arrayMachine.push(machine);
          });
        });
        const arrayStatusMachine = updateStatusMachine({
          timeLength: currentChecklist.arrayCheckTimes.length,
          arrayMachine: arrayMachine,
        });

        const arrayValue = [];
        // get Value change from dataChecklist
        dataChecklist.checklistGroups.forEach((group) => {
          group.checklistMachines.forEach((machine) => {
            machine.checkItems.forEach((item) => {
              arrayValue.push({
                id: item.id,
                value: item.value,
                checkTime: item.checkTime,
              });
            });
          });
        });
        // map status machine from cl_import to cl_current
        currentChecklist.ChecklistMachine.forEach((machineCurrent) => {
          machineCurrent.CheckResultContent.forEach((itemCurrent) => {
            arrayValue.forEach((itemUpdated) => {
              if (
                itemCurrent.checkItemId === itemUpdated.id &&
                Number(itemCurrent.checkTime) === Number(itemUpdated.checkTime)
              ) {
                itemCurrent.value = itemUpdated.value;
              }
            });
          });
          arrayStatusMachine.forEach((machine) => {
            if (machine.id === machineCurrent.ChecklistMachineID) {
              machineCurrent.noneInput = machine.noneInput;
              machineCurrent.resultMachineStatus = machine.resultMachineStatus;
              machineCurrent.errorId = machine.errorId;
            }
          });
        });
        if (currentChecklist.checklistGroups[0].title === lang.COMMON) {
          currentChecklist.checklistGroups.shift();
        }
        currentChecklist.checklistGroups.forEach((group) => {
          group.checklistMachines.forEach((machine) => {
            machine.checkItems.forEach((item) => {
              arrayValue.forEach((itemUpdated) => {
                if (
                  item.id === itemUpdated.id &&
                  Number(item.checkTime) === Number(itemUpdated.checkTime)
                ) {
                  item.value = itemUpdated.value;
                }
              });
            });
            arrayStatusMachine.forEach((mc) => {
              if (mc.id === machine.checklistMachineId) {
                machine.noneInput = mc.noneInput;
                machine.resultMachineStatus = mc.resultMachineStatus;
                machine.errorId = mc.errorId;
              }
            });
          });
        });
        dataChecklist = Object.assign({}, currentChecklist);
      }

      let groups = dataChecklist.checklistGroups.map(
        (item) => new CheckListGroupsClass(item)
      );
      groups.unshift(
        new CheckListGroupsClass({
          checklistGroupName: lang.COMMON,
          index: 0,
        })
      );
      groups.forEach((item, index) => {
        item["index"] = index;
      });
      state.currentTabCLGroup = groups;
      if (!isUpdated) {
        state.checkTimeLength = dataChecklist.arrayCheckTimes
          ? dataChecklist.arrayCheckTimes.length
          : 0;
      }
      if (!isUpdated) {
        if (id === temporaryId) {
          state.checklistTmp = {
            ...dataChecklist,
          };
          payload.history.push(`/list/${temporaryId}`);
          state.tempBookmarkId = dataChecklist.bookmarkId;
          state.tempChecklistId = dataChecklist.ChecklistId;
        } else if (page === "list") {
          state.checklistSelected = {
            ...dataChecklist,
          };
          payload.history.push(`/list/${id}`);
          state.tempBookmarkId = dataChecklist.bookmarkId
            ? dataChecklist.bookmarkId
            : null;
          state.tempChecklistId = dataChecklist.checklistId;
        } else {
          state.checklistSelected = {
            ...dataChecklist,
          };
        }
        if (dataChecklist.bookmarkId) {
          dataChecklist.isBookmark = true;
        } else {
          dataChecklist.isBookmark = false;
        }
        state.tempBookmarked = dataChecklist.isBookmark;
      } else {
        if (id === temporaryId) {
          state.checklistTmp = Object.assign({}, dataChecklist);
        } else {
          state.checklistSelected = Object.assign({}, dataChecklist);
        }
        state.checklistUpdated = Object.assign({}, dataChecklist);
      }
    },
    deleteRequestByIndex: (state, { payload }) => {
      state.dataRequests.splice(payload.index, 1);
    },
    resetStateIsUpdated: (state) => {
      // Reset selected checklist
      state.checklistSelected = null;
      state.checklistTmp = null;
      state.currentTabCLGroup = [];
      state.currentChecklistGroup = null;
      state.currentNote = {};
      state.currentWorkingStatus = {};
      state.currentAttachment = {};
      state.currentMachine = null;
      state.dataHistory = [];
      state.isUpdated = false;
      state.isUpdatedTime = false;
      state.isLoadMore = false;
      state.isLoadingBookmark = false;
      state.checkTime = null;
      state.timeChecks = null;
      state.modeInput = false;
      state.checklistUpdated = null;
      state.errorImport = null;
      state.dataMultiCheck = {};
      state.showMultiChecker = false;
      state.arrayCLToyota = [];
      state.arrayItemChart = [];
    },
    resetStateWhenOffline: (state) => {
      state.currentTabCLGroup = [];
      state.currentChecklistGroup = null;
      state.currentNote = {};
      state.currentWorkingStatus = {};
      state.currentAttachment = {};
      state.currentMachine = null;
      state.isUpdatedTime = false;
      state.isLoadingBookmark = false;
      state.isUpdated = false;
      state.isLoadMore = false;
      state.checkTime = null;
      state.timeChecks = null;
      state.modeInput = false;
      state.arrayItemChart = [];
    },
    resetWhenChangeFactory: (state) => {
      state.dataTemplateFiltered = [];
      state.dataSaved = [];
      state.dataTemplate = [];
      state.dataGroups = [];
      state.modeTab = KEY.GROUP_TYPE_COMMON;
    },
    updateCheckResultItemDataOffline: (state, { payload }) => {
      const { dataSaved, dataRequests } = state;

      let result = JSON.parse(JSON.stringify(payload));
      const newDataSaved = JSON.parse(JSON.stringify(dataSaved));
      const newDataRequest = JSON.parse(JSON.stringify(dataRequests));

      const { id } = result;
      const indexDataSaved = newDataSaved.findIndex(
        (item) => item.id.toString() === id.toString()
      );

      if (indexDataSaved !== -1 && indexDataSaved !== undefined) {
        newDataSaved.splice(indexDataSaved, 1);
        newDataSaved.unshift(result);
      }

      const indexDataRequest = newDataRequest.findIndex(
        (item) => item.id.toString() === id.toString()
      );

      if (indexDataRequest !== -1 && indexDataRequest !== undefined) {
        newDataRequest[indexDataRequest] = JSON.parse(JSON.stringify(result));
      }
      state.dataSaved = [...newDataSaved];
      state.dataRequests = [...newDataRequest];
    },
    updateCheckResultItemDataSavedOffline: (state, { payload }) => {
      let { dataSaved } = state;
      if (payload.override) {
        dataSaved = dataSaved.filter(
          (item) =>
            (item["checklistId"] !== payload.checkResult["checklistId"] &&
              item["ChecklistId"] !== payload.checkResult["checklistId"]) ||
            Number(item.status) !== Number(payload.checkResult.status)
        );
      }
      const result = JSON.parse(JSON.stringify(payload.checkResult));
      const { id } = result;
      const indexDataSaved = dataSaved.findIndex(
        (item) => Number(item.id) === Number(id)
      );

      if (indexDataSaved !== -1 && indexDataSaved !== undefined) {
        dataSaved.splice(indexDataSaved, 1);
        dataSaved.unshift(result);
      }
      state.dataSaved = dataSaved;
    },
    createNewRequest: (state, { payload }) => {
      // method is create, just add new item into array
      const { dataRequests } = state;
      const data = [...dataRequests];
      if (payload.override) {
        for (let i = 0; i < dataRequests.length; i++) {
          if (
            (data[i].checklistId
              ? data[i].checklistId === payload.checkResult.checklistId
              : data[i].id === payload.checkResult.id) &&
            Number(data[i].status) === Number(payload.checkResult.status)
          ) {
            data.splice(i, 1);
            i--;
          }
        }
      }
      data.unshift(payload.checkResult);
      state.dataRequests = data;
    },
    updateTimeCheck: (state, { payload }) => {
      let { checklistTmp, checklistSelected, checklistId } = state;
      state.checkTime = payload.checkTime;
      state.timeChecks = payload.timeChecks;
      state.isUpdatedTime = true;
      let checklist;
      if (checklistId === temporaryId) {
        checklist = JSON.parse(JSON.stringify(checklistTmp));
      } else {
        checklist = JSON.parse(JSON.stringify(checklistSelected));
      }
      if (checklist) {
        checklist.arrayCheckTimes[payload.checkTime - 1].timeChecks =
          payload.timeChecks;
      }
      if (checklistId === temporaryId) {
        state.checklistTmp = checklist;
      } else {
        state.checklistSelected = checklist;
      }
    },
    updateCheckItemLength: (state, { payload }) => {
      state.lengthCheckItem = payload;
    },
    setChecklist(state, { payload }) {
      const { type } = payload;
      const checklists = payload.list;

      // Modify checklists, add machine list
      checklists.forEach((checklist) => {
        updateEachChecklist(checklist, type);
      });

      // LoadMore for `data` (history)
      if (
        type === "data" ||
        type === "dataTemplateFiltered" ||
        type === "dataSaved" ||
        type === "dataTemplate" ||
        type === "dataHistory"
      ) {
        const { resData } = payload;
        const { offsetStart, offsetEnd, isLoadMore, offsetName } = resData;
        if (isLoadMore) {
          state.isLoadMore = true;
          if (type === "data") {
            state.offsetHistory = offsetEnd;
          }
          if (type === "dataTemplate") {
            state.offsetTemplateChecklist = offsetEnd;
          }
          if (type === "dataTemplateFiltered") {
            state.offsetFilteredChecklist = offsetEnd;
            state.isLoadMoreFiltered = true;
          }
          if (type === "dataSaved") {
            state.offsetSavedChecklist = offsetEnd;
          }
          if (type === "dataHistory") {
            state.offsetHistory = offsetEnd;
          }
          if (offsetName) {
            state[offsetName] = offsetEnd;
          }
        } else {
          state.isLoadMore = false;
          if (type === "dataTemplateFiltered") {
            state.isLoadMoreFiltered = false;
          }
          if (type === "data") {
            state.offsetHistory = 0;
          } else {
            state.offsetFilteredChecklist = 0;
          }

          if (offsetName) {
            state[offsetName] = 0;
          }
        }

        if (offsetStart === 0) {
          state[payload.type] = checklists;
        } else {
          state[payload.type] = state[payload.type].concat(checklists);
        }

        if (type === "data") {
          state.totalHistory = payload.total;
          state.historyParams = {
            ...payload.historyParams,
          };
        } else {
          if (type === "dataTemplateFiltered") {
            state.totalFilteredChecklist2 = payload.total;
          } else {
            state.totalFilteredChecklist = payload.total;
          }
        }
      } else {
        state[payload.type] = checklists;
      }
      state.isSearch = false;
    },
    setGroupList: (state, { payload }) => {
      let groupChecklist = payload;
      if (groupChecklist && groupChecklist.length) {
        groupChecklist.forEach((groupItem) => {
          const checklists = groupItem.checklists;
          state.offsetGroupChecklist[groupItem.id] = 0;
          checklists.forEach((checklist) => {
            updateEachChecklist(checklist, "dataTemplate");
          });
          if (
            state.offsetGroupChecklist[groupItem.id] < groupItem.total &&
            groupItem.total > 5
          ) {
            groupItem["isLoadMore"] = true;
            groupItem["offset"] = state.offsetGroupChecklist[groupItem.id];
            state.offsetGroupChecklist[groupItem.id] =
              state.offsetGroupChecklist[groupItem.id] + 5;
          } else {
            groupItem["isLoadMore"] = false;
            groupItem["offset"] = state.offsetGroupChecklist[groupItem.id];
          }
          groupItem.checklists = checklists;
          groupItem.title = groupItem.name;
        });
      }
      state.dataGroups = groupChecklist;
      if (payload.length) {
        state.selectedGroup = groupChecklist[0];
      }
    },
    addDataGroup: (state, { payload }) => {
      let checklists = payload.resData.data;
      checklists.forEach((checklist) => {
        updateEachChecklist(checklist, "dataTemplate");
      });
      const offGroupCL = JSON.parse(
        JSON.stringify(state.offsetGroupChecklist[payload.id])
      );
      payload["isLoadMore"] = false;
      if (payload.resData.count < 5 || !payload.resData.count) {
        payload["isLoadMore"] = false;
      } else {
        payload["isLoadMore"] = true;
        state.offsetGroupChecklist[payload.id] =
          offGroupCL + payload.resData.count;
      }
      payload["offset"] = offGroupCL + payload.resData.count;
      const data = JSON.parse(JSON.stringify(state.dataGroups));
      data.forEach((groupItem) => {
        if (groupItem.id === payload.id && groupItem.offset < groupItem.total) {
          groupItem.checklists = groupItem.checklists.concat(checklists);
          if (payload["offset"] === groupItem.total) {
            groupItem["isLoadMore"] = false;
          } else {
            groupItem["isLoadMore"] = payload["isLoadMore"];
          }
          groupItem["offset"] = payload["offset"];
        }
      });
      state.dataGroups = data;
    },
    updateHistoryParams(state, { payload }) {
      state.historyParams = {
        ...payload.params,
      };
    },
    updateBookmarkChecklist: (state, { payload }) => {
      const { checklistTmp, checklistSelected, checklistId } = state;
      if (payload.isBookmark) {
        if (checklistId === temporaryId) {
          state.checklistTmp = {
            ...checklistTmp,
            bookmarkId: payload.bookmarkId,
          };
          state.tempBookmarkId = payload.bookmarkId;
        } else {
          state.checklistSelected = {
            ...checklistSelected,
            bookmarkId: payload.bookmarkId,
          };
          state.tempBookmarkId = payload.bookmarkId;
        }
      }

      state.tempBookmarked = payload.isBookmark;
    },
    updateCheckResultItemData: (state, { payload }) => {
      const { index, id } = payload;
      state.dataSaved[index]["id"] = id;
    },

    createNewResultInSaved: (state, { payload }) => {
      const { dataSaved } = state;
      const data = dataSaved;
      if (payload.override) {
        for (let i = 0; i < dataSaved.length; i++) {
          if (
            (data[i]["checklistId"] === payload.checkResult["ChecklistId"] ||
              data[i]["ChecklistId"] === payload.checkResult["ChecklistId"]) &&
            Number(data[i].status) === Number(payload.checkResult.status)
          ) {
            data.splice(i, 1);
            i--;
          }
        }
      }
      data.unshift(payload.checkResult);
      state.dataSaved = data;
    },
    updateTempTimeCheck: (state, { payload }) => {
      state.isUpdated = true;
      let result = JSON.parse(JSON.stringify(payload));

      const { checklistId } = state;

      if (checklistId === temporaryId) {
        state.checklistTmp = result;
      } else {
        state.checklistSelected = result;
      }
    },
    setMachineId: (state, { payload }) => {
      state.machineId = payload;
    },
    updateStatusLoading: (state, { payload }) => {
      state[payload.name] = payload.value;
    },
    updateOffsetTemplateChecklist: (state, { payload }) => {
      state.offsetTemplateChecklist = payload;
    },
    updateCurrentCLGroup: (state) => {
      const {
        currentChecklistGroup,
        machineId,
        currentMachine,
        checklistId,
        checkTime,
        isUpdated,
      } = state;
      let checklist;
      if (checklistId === temporaryId) {
        checklist = JSON.parse(JSON.stringify(state.checklistTmp));
      } else {
        checklist = JSON.parse(JSON.stringify(state.checklistSelected));
      }
      if (isUpdated && checklist) {
        let clGroup = JSON.parse(JSON.stringify(currentChecklistGroup));
        let currentM = JSON.parse(JSON.stringify(currentMachine));
        let arrayMachine = [];
        let total = 0;
        clGroup.checklistMachines.forEach((machine) => {
          if (machine.id === machineId) {
            arrayMachine.push(currentM);
          } else {
            arrayMachine.push(machine);
          }
        });
        clGroup.checklistMachines = arrayMachine;
        //update status complete of tab
        arrayMachine.forEach((machine) => {
          if (machine.resultMachineStatus === 3) {
            total++;
          }
        });
        if (total === arrayMachine.length) {
          clGroup.tabComplete = true;
        } else {
          clGroup.tabComplete = false;
        }
        state.currentChecklistGroup = clGroup;
        // update checklist machine
        let arrayMachine1 = [];
        checklist.ChecklistMachine.forEach((itemMachine) => {
          if (itemMachine.machineId === machineId) {
            let arrayMachineItems = [];
            currentM.checkItems.forEach((item) => {
              arrayMachineItems.push({
                checklistItemID: item.checklistItemId,
                checkItemId: item.id,
                value: item.value,
                checkTime: item.checkTime ? item.checkTime : checkTime,
                previousTime: item.previousTime,
                inspectionTime: item.inspectionTime,
                inputJudgment: item.inputJudgment,
                previousValue: item.previousValue,
                replenishmentAmount: item.replenishmentAmount,
                note: item.note,
                previousAccumulatedValue: item.previousAccumulatedValue,
                normalLowerControlValue: item.normalLowerControlValue,
                normalUpperControlValue: item.normalUpperControlValue,
                type: item.type,
              });
            });
            arrayMachine1.push({
              ...itemMachine,
              errorId: currentM.errorId,
              note: currentM.note,
              attachments: currentM.attachments,
              noneInput: currentM.noneInput,
              workingStatus: currentM.workingStatus,
              resultMachineStatus: currentM.resultMachineStatus,
              CheckResultContent: arrayMachineItems,
            });
          } else {
            arrayMachine1.push(itemMachine);
          }
        });
        checklist.ChecklistMachine = arrayMachine1;
        if (checklistId === temporaryId) {
          state.checklistTmp = checklist;
        } else {
          state.checklistSelected = checklist;
        }
        state.currentMachine = null;
        state.machineId = null;
      }
    },
    updateAttachmentMachine: (state) => {
      let checklist;
      const {
        machineId,
        checklistId,
        checklistTmp,
        checklistSelected,
        checklistGroupId,
        currentMachine,
        currentAttachment,
      } = state;
      if (currentMachine && currentAttachment[machineId]) {
        let machine = JSON.parse(JSON.stringify(currentMachine));
        let attachment = JSON.parse(
          JSON.stringify(currentAttachment[machineId])
        );
        if (
          (machine.attachments &&
            attachment.length !== machine.attachments.length) ||
          (!machine.attachments && attachment.length)
        ) {
          state.isUpdated = true;
        }
        machine.attachments = attachment;
        state.currentMachine = machine;
        if (checklistId === temporaryId) {
          checklist = JSON.parse(JSON.stringify(checklistTmp));
        } else {
          checklist = JSON.parse(JSON.stringify(checklistSelected));
        }
        if (checklist) {
          checklist.ChecklistMachine.forEach((machine) => {
            if (machine.Id === machineId || machine.machineId === machineId) {
              machine.attachments = attachment;
            }
          });
          checklist.checklistGroups.forEach((clGroups) => {
            if (clGroups.id === checklistGroupId) {
              clGroups.checklistMachines.forEach((machine) => {
                if (machine.id === machineId) {
                  machine.attachments = attachment;
                }
              });
            }
          });
          if (checklistId === temporaryId) {
            state.checklistTmp = JSON.parse(JSON.stringify(checklist));
          } else {
            state.checklistSelected = JSON.parse(JSON.stringify(checklist));
          }
        }
      }
    },
    updateNoteMachine: (state) => {
      let checklist;
      const {
        machineId,
        checklistId,
        checklistTmp,
        checklistSelected,
        checklistGroupId,
        currentMachine,
        currentNote,
      } = state;
      if (currentMachine) {
        let machine = JSON.parse(JSON.stringify(currentMachine));
        if (currentNote[machineId] !== machine.note) {
          state.isUpdated = true;
        }
        machine.note = currentNote[machineId];
        state.currentMachine = machine;
        if (checklistId === temporaryId) {
          checklist = JSON.parse(JSON.stringify(checklistTmp));
        } else {
          checklist = JSON.parse(JSON.stringify(checklistSelected));
        }
        if (checklist) {
          checklist.ChecklistMachine.forEach((machine) => {
            if (machine.Id === machineId || machine.machineId === machineId) {
              machine.Note = currentNote[machineId];
            }
          });
          checklist.checklistGroups.forEach((clGroups) => {
            if (clGroups.id === checklistGroupId) {
              clGroups.checklistMachines.forEach((machine) => {
                if (machine.id === machineId) {
                  machine.note = currentNote[machineId];
                }
              });
            }
          });
          if (checklistId === temporaryId) {
            state.checklistTmp = JSON.parse(JSON.stringify(checklist));
          } else {
            state.checklistSelected = JSON.parse(JSON.stringify(checklist));
          }
        }
      }
    },
    updateWorkingStatusMachine: (state) => {
      let checklist;
      const {
        machineId,
        checklistId,
        checklistTmp,
        checklistSelected,
        checklistGroupId,
        currentMachine,
        currentWorkingStatus,
      } = state;
      if (currentMachine) {
        let machine = JSON.parse(JSON.stringify(currentMachine));
        if (currentWorkingStatus[machineId] !== machine.workingStatus) {
          state.isUpdated = true;
        }
        machine.workingStatus = currentWorkingStatus[machineId];
        state.currentMachine = machine;
        if (checklistId === temporaryId) {
          checklist = JSON.parse(JSON.stringify(checklistTmp));
        } else {
          checklist = JSON.parse(JSON.stringify(checklistSelected));
        }
        if (checklist) {
          checklist.ChecklistMachine.forEach((machine) => {
            if (machine.Id === machineId || machine.machineId === machineId) {
              machine.Note = currentWorkingStatus[machineId];
            }
          });
          checklist.checklistGroups.forEach((clGroups) => {
            if (clGroups.id === checklistGroupId) {
              clGroups.checklistMachines.forEach((machine) => {
                if (machine.id === machineId) {
                  machine.workingStatus = currentWorkingStatus[machineId];
                }
              });
            }
          });
          if (checklistId === temporaryId) {
            state.checklistTmp = JSON.parse(JSON.stringify(checklist));
          } else {
            state.checklistSelected = JSON.parse(JSON.stringify(checklist));
          }
        }
      }
    },
    updateSaveStatusMachine: (state) => {
      let checklist;
      const {
        machineId,
        checklistId,
        checklistTmp,
        checklistSelected,
        checklistGroupId,
        currentMachine,
        currentSaveStatus,
      } = state;
      if (currentMachine) {
        let machine = JSON.parse(JSON.stringify(currentMachine));
        if (currentSaveStatus[machineId] !== machine.saveStatus) {
          state.isUpdated = true;
        }
        machine.saveStatus = currentSaveStatus[machineId];
        state.currentMachine = machine;
        if (checklistId === temporaryId) {
          checklist = JSON.parse(JSON.stringify(checklistTmp));
        } else {
          checklist = JSON.parse(JSON.stringify(checklistSelected));
        }
        if (checklist) {
          checklist.ChecklistMachine.forEach((machine) => {
            if (machine.Id === machineId || machine.machineId === machineId) {
              machine.saveStatus = currentSaveStatus[machineId];
            }
          });
          checklist.checklistGroups.forEach((clGroups) => {
            if (clGroups.id === checklistGroupId) {
              clGroups.checklistMachines.forEach((machine) => {
                if (machine.id === machineId) {
                  machine.saveStatus = currentSaveStatus[machineId];
                }
              });
            }
          });
          if (checklistId === temporaryId) {
            state.checklistTmp = JSON.parse(JSON.stringify(checklist));
          } else {
            state.checklistSelected = JSON.parse(JSON.stringify(checklist));
          }
        }
      }
    },
    updateCurrentMachine: (state, { payload }) => {
      const { currentMachine } = state;
      let machine = JSON.parse(JSON.stringify(currentMachine));
      let lengthCheckItem = 0;
      let itemHasDone = 0;
      let hasDone = false;
      if (machine && !machine.errorId) {
        machine.errorId = [];
      }
      let isUnCheck = true;

      const validateData = (item) => {
        if (
          (item.id !== payload.id &&
            (item.initValue !== null ||
              (item.value !== null &&
                item.value !== "" &&
                item.initValue === null))) ||
          (item.id === payload.id &&
            (item.initValue !== null ||
              (payload.value !== null &&
                payload.value !== "" &&
                item.initValue === null))) ||
          (Number(item.accumulatedType) === 3 &&
            (item.replenishmentAmount !== "" ||
              item.replenishmentAmount !== null)) ||
          (payload.valueReplenishment !== "" &&
            payload.valueReplenishment !== null)
        ) {
          isUnCheck = false;
        }
        if (Number(item.accumulatedType) !== 3) {
          if (
            item.id !== payload.id &&
            item.value !== null &&
            item.value !== ""
          ) {
            itemHasDone += 1;
          }
          if (
            item.id === payload.id &&
            payload.value !== null &&
            payload.value !== ""
          ) {
            itemHasDone += 1;
          }
        } else {
          if (
            item.id !== payload.id &&
            item.value !== null &&
            item.value !== "" &&
            item.replenishmentAmount !== "" &&
            item.replenishmentAmount !== null
          ) {
            itemHasDone += 1;
          }
          if (
            item.id === payload.id &&
            payload.value !== null &&
            payload.value !== "" &&
            payload.valueReplenishment &&
            payload.valueReplenishment !== "" &&
            payload.valueReplenishment !== null
          ) {
            itemHasDone += 1;
          }
        }
      };
      machine &&
        machine.checkItems.forEach((item) => {
          // if has check time
          if (
            (payload.checkTime &&
              Number(item.checkTime) === Number(payload.checkTime)) ||
            (payload.checkTime && Number(item.checkTime) === 0)
          ) {
            lengthCheckItem++;
            validateData(item);
            if (item.id === payload.id) {
              if (payload.isUpdate) {
                item.value = payload.value !== "" ? payload.value : null;
                item.inspectionTime = payload.inspectionTime;
                item.replenishmentAmount =
                  payload.valueReplenishment !== ""
                    ? payload.valueReplenishment
                    : null;
                item.note = payload.note;
                if (item.itemJudgement && payload.valueJudgement)
                  item.inputJudgment = payload.valueJudgement;
              }
              if (payload.isUpdateRange) {
                item.normalLowerControlValue = payload.lowerValue;
                item.normalUpperControlValue = payload.upperValue;
              }
              if (
                (payload.isUpdate && !payload.isError) ||
                (payload.isUpdateRange && !payload.isError)
              ) {
                state.isUpdated = true;
                machine.errorId[payload.checkTime] = machine.errorId[
                  payload.checkTime
                ].length
                  ? machine.errorId[payload.checkTime].filter(
                      (item) => item !== payload.id
                    )
                  : [];
              }
              if (payload.isError) {
                state.isUpdated = true;
                if (
                  machine.errorId[payload.checkTime].indexOf(payload.id) === -1
                ) {
                  machine.errorId[payload.checkTime].push(payload.id);
                }
              }
            }
          }
          if (!payload.checkTime) {
            lengthCheckItem++;
            validateData(item);
            if (item.id === payload.id) {
              if (payload.isUpdate) {
                item.value = payload.value !== "" ? payload.value : null;
                item.inspectionTime = payload.inspectionTime;
                item.replenishmentAmount =
                  payload.valueReplenishment !== ""
                    ? payload.valueReplenishment
                    : null;
                item.note = payload.note;
                if (item.itemJudgement && payload.valueJudgement)
                  item.inputJudgment = payload.valueJudgement;
              }
              if (payload.isUpdateRange) {
                item.normalLowerControlValue = payload.lowerValue;
                item.normalUpperControlValue = payload.upperValue;
              }
              if (
                (payload.isUpdate && !payload.isError) ||
                (payload.isUpdateRange && !payload.isError)
              ) {
                state.isUpdated = true;
                machine.errorId = machine.errorId.filter(
                  (item) => item !== payload.id
                );
              }
              if (payload.isError) {
                state.isUpdated = true;
                if (machine.errorId.indexOf(payload.id) === -1) {
                  machine.errorId.push(payload.id);
                }
              }
            }
          }
        });
      if (itemHasDone === lengthCheckItem) {
        hasDone = true;
      }
      if (machine) {
        if (payload.isUpdate || payload.isUpdateRange) {
          if (payload.checkTime) {
            if (isUnCheck) {
              machine.resultMachineStatus[payload.checkTime] = 0;
              machine.noneInput[payload.checkTime] = null;
            } else {
              if (machine.errorId[payload.checkTime].length) {
                machine.resultMachineStatus[payload.checkTime] = 2;
                machine.noneInput[payload.checkTime] =
                  lengthCheckItem - itemHasDone;
              } else if (hasDone) {
                machine.resultMachineStatus[payload.checkTime] = 3;
                machine.itemHasDone = null;
                machine.noneInput[payload.checkTime] = 0;
              } else {
                machine.resultMachineStatus[payload.checkTime] = 1;
                machine.noneInput[payload.checkTime] =
                  lengthCheckItem - itemHasDone;
              }
            }
          } else {
            if (isUnCheck) {
              machine.resultMachineStatus = 0;
              machine.noneInput = null;
            } else {
              if (machine.errorId.length) {
                machine.resultMachineStatus = 2;
                machine.noneInput = lengthCheckItem - itemHasDone;
              } else if (hasDone) {
                machine.resultMachineStatus = 3;
                machine.itemHasDone = null;
                machine.noneInput = 0;
              } else {
                machine.resultMachineStatus = 1;
                machine.noneInput = lengthCheckItem - itemHasDone;
              }
            }
          }
        }
        state.currentMachine = machine;
      }
    },
    updateCurrentChecklist: (state) => {
      const { checklistId, currentTabCLGroup, isUpdatedTime, isUpdated } =
        state;
      let checklist;
      let tabCLG = JSON.parse(JSON.stringify(currentTabCLGroup));
      if (checklistId === temporaryId) {
        checklist = JSON.parse(JSON.stringify(state.checklistTmp));
      } else {
        checklist = JSON.parse(JSON.stringify(state.checklistSelected));
      }
      if (isUpdatedTime) {
        // const arrayTime = checklist.arrayCheckTimes;
        checklist.ChecklistMachine.forEach((machines) => {
          let isDisable = false;
          if (Number(machines.workingStatus) !== 1) {
            isDisable = true;
          }
          machines.CheckResultContent.forEach((item) => {
            // if (Number(item.type) !== 1) {
            //   item.inspectionTime = arrayTime[Number(item.checkTime) - 1]
            //     ? arrayTime[Number(item.checkTime) - 1].timeChecks
            //     : null;
            // }
            if (isDisable && item.value === "") {
              item.value = null;
            }
          });
        });
      }
      if (isUpdated) {
        checklist.checklistGroups = tabCLG;
      }
      if (checklistId === temporaryId) {
        state.checklistTmp = checklist;
      } else {
        state.checklistSelected = checklist;
      }
      state.updateDone = true;
    },
    updateCurrentTabChecklistGroup: (state) => {
      let { currentChecklistGroup, checklistGroupId, currentTabCLGroup } =
        state;
      let arrayChecklistGroup = [];
      currentTabCLGroup.forEach((item) => {
        if (item.id && item.id === checklistGroupId) {
          arrayChecklistGroup.push(
            JSON.parse(JSON.stringify(currentChecklistGroup))
          );
        } else {
          arrayChecklistGroup.push(item);
        }
      });
      state.currentTabCLGroup = arrayChecklistGroup;
    },
    saveHistorySearch: (state, { payload }) => {
      state.historySearch = payload;
    },
  },
});
// the action in redux ~ the commit in vuex
export const {
  updateArrayCLToyota,
  updateAndChangeCurrentRoute,
  updateCurrentUser,
  updateCurrentRouter,
  updateCurrentStatusCL,
  setModeInput,
  selectedTab,
  setTab,
  setTabMode,
  setTabUser,
  setTabCommon,
  setChecklistId,
  setCurrentNote,
  setArrayWorkingStatus,
  setCurrentWorkingStatus,
  setCurrentAttachment,
  resetStateIsUpdated,
  resetWhenChangeFactory,
  deleteRequestByIndex,
  updateCheckResultItemDataOffline,
  updateCheckResultItemDataSavedOffline,
  updateCheckResultItemData,
  createNewRequest,
  setChecklist,
  setGroupList,
  addDataGroup,
  updateOffsetFilterChecklist,
  updateHistoryParams,
  updateBookmarkChecklist,
  createNewResultInSaved,
  updateTempTimeCheck,
  setMachineId,
  updateStatusLoading,
  updateOffsetTemplateChecklist,
  updateAttachmentMachine,
  updateNoteMachine,
  updateWorkingStatusMachine,
  updateChecklistName,
  updateCheckResultStatus,
  updateGroupsCLName,
  updateCurrentMachine,
  updateCurrentCLGroup,
  setCurrentMachine,
  setCurrentCLGroup,
  updateCurrentChecklist,
  updateCurrentTabChecklistGroup,
  updateTimeCheck,
  updateCheckItemLength,
  setInputJudgment,
  saveHistorySearch,
  setWorkingStatus,
  setShowAlert,
  setNextMachine,
  setPreviousMachine,
  setStatusUpdateState,
  resetStateWhenOffline,
  setLoadingBookmark,
  updateShowMessageUploadImage,
  selectRowSearch,
  updateShowMultiChecker,
  updateDataMultiCheck,
  updateArrayStatusTabs,
  updateIsImportTemplate,
  addItemChart,
  removeItemChart,
  setCurrentSaveStatus,
  updateSaveStatusMachine,
} = checkListSlice.actions;

// the selector
export const checkListSelector = (state) => state.checklist;

// the reducer ~ the mutation in vuex
export default checkListSlice.reducer;

// ************  ACTION: create action for state ************ //

//import template checklist
export const checklistApplyForToyota = () => (dispatch) => {
};
export const importTemplateChecklist = (checklist, history) => (dispatch) => {  
};

// create temporary checklist when click a checklist
export const createTempChecklist =
  (checklist, history, updatedTemp) => (dispatch) => {
    let result = JSON.parse(JSON.stringify(checklist));
    const date = getUnixTime(new Date());
    const userName = localStorage.getItem("displayName") || "";
    const userId = localStorage.getItem("userName") || null;

    // Main information
    result.templateId = result.id;
    result.id = temporaryId;
    result.status = "temporary";
    // Account information
    result.inspectorId = userId;
    result.inspector = userName;
    result.inspector1Id = null;
    result.inspector1 = "";
    result.inspector2Id = null;
    result.inspector2 = "";
    // Datetime
    result.checkDate = date;
    result.timeStartCheck = date;
    result.timeEndCheck = "";
    result.created = date;
    result.modified = date;
    // Add isUpdate and isError for all items
    result.checklistGroups.forEach((item) => {
      item["isUpdate"] = false;
      item["isError"] = false;
      item["value"] = "";
    });
    dispatch(
      setTab({
        selectedTab: lang.COMMON,
        indexSelectedTab: 0,
      })
    );
    dispatch(
      setChecklistId({
        id: result.id,
        page: "list",
        checklist: {
          ...result,
        },
        history: history,
        isUpdated: updatedTemp,
      })
    );
  };
// update data request when offline or online
export const updateResultPOMM =
  (params, state, history, checklistSelected, kind, mergeStatus) =>
  (dispatch) => {
    const { isOnline, type, data, hideRemark } = params;
    const { dataSaved, dataRequests } = state;
    let checklist;
    let Type;
    if (type === "normal") {
      Type = 4;
    } else if (type === "draft") {
      Type = 3;
    } else {
      Type = 5;
    }
    if (data) {
      checklist = JSON.parse(JSON.stringify(data));
    } else {
      checklist = JSON.parse(JSON.stringify(checklistSelected));
    }
    if (checklist) {
      let draftResult = JSON.parse(JSON.stringify(checklist));
      let modifiedResult = {
        ChecklistName: draftResult.checklistName,
        ChecklistId: draftResult.checklistId || draftResult.ChecklistId,
        ChecklistVersion: draftResult.checklistVersion
          ? draftResult.checklistVersion
          : draftResult.version,
        CheckDate: Number(draftResult.checkDate),
        TimeStartCheck: draftResult.timeStartCheck,
        TimeEndCheck: draftResult.timeEndCheck,
        Inspector: draftResult.inspector,
        Inspector1: draftResult.inspector1,
        Inspector2: draftResult.inspector2,
        Status: draftResult.status,
        SaveType: Type,
        ArrayCheckTime: draftResult.arrayCheckTimes,
        Id: draftResult.id,
        ChecklistMachine: JSON.parse(
          JSON.stringify(draftResult.ChecklistMachine)
        ),
        uuid: draftResult.uuid ? draftResult.uuid : "",
        MergeStatus:
          mergeStatus === undefined ? draftResult.MergeStatus : mergeStatus,
        HideRemark:
          hideRemark !== undefined ? hideRemark : draftResult.hideRemark,
      };

      if (isOnline) {
        dispatch(loadingPage(true));        
      } else {
        const dataDraftDuplicated = dataSaved.filter(
          (item) =>
            (item.checklistId === draftResult.ChecklistId ||
              item.ChecklistId === draftResult.ChecklistId) &&
            Number(item.status) === 3 &&
            type === "draft"
        );
        if (dataDraftDuplicated.length) {
          const data = dataDraftDuplicated[0];
          const time = format(fromUnixTime(Number(data.timeStartCheck)));
          Modal.confirm({
            title: lang.WARMING,
            centered: true,
            content: (
              <Row>
                <Col span={24}>
                  <span className="info--text">{data.checklistName}</span> が
                  <span className="info--text">{time}</span>
                  {lang.SAVE_CL_DRAFT}
                </Col>
                <Col span={24}>{lang.DO_YOU_WANT_TO_OVERRIDE}</Col>
              </Row>
            ),
            okText: lang.OK,
            cancelText: lang.CANCEL,
            onOk: () =>
              updateCheckResultOffline(
                draftResult,
                type,
                dispatch,
                history,
                dataRequests,
                true
              ),
          });
        } else {
          updateCheckResultOffline(
            draftResult,
            type,
            dispatch,
            history,
            dataRequests,
            false
          );
        }
      }
    }
  };
function updateCheckResultOffline(
  draftResult,
  type,
  dispatch,
  history,
  dataRequests,
  override
) {
  let checklistResultItemOffline = JSON.parse(JSON.stringify(draftResult));
  checklistResultItemOffline["type"] = type;
  checklistResultItemOffline["Override"] = !!override;
  if (type === "draft") {
    checklistResultItemOffline["status"] = 3;
    checklistResultItemOffline["statusValue"] = STATUS.STATUS_CL.WORKING;
    checklistResultItemOffline["color"] = STATUS.STATUS_COLOR.WORKING;
  } else if (type === "normal") {
    checklistResultItemOffline["status"] = 4;
    checklistResultItemOffline["statusValue"] = STATUS.STATUS_CL.COMPLETE;
    checklistResultItemOffline["color"] = STATUS.STATUS_COLOR.COMPLETE;
  } else {
    checklistResultItemOffline["status"] = 5;
    checklistResultItemOffline["statusValue"] =
      STATUS.STATUS_CL.COMPLETE_ABNORMAL;
    checklistResultItemOffline["color"] = STATUS.STATUS_COLOR.COMPLETE_ABNORMAL;
  }
  checklistResultItemOffline["updateTime"] = getUnixTime(new Date());
  const indexInRequests = dataRequests.findIndex(
    (item) => item.id === checklistResultItemOffline.id
  );
  // if check result has existed,update data requests,don't add to new data request
  if (indexInRequests >= 0) {
    dispatch(updateCheckResultItemDataOffline(checklistResultItemOffline));
  } else {
    // Add request to dataRequests
    dispatch(
      updateCheckResultItemDataSavedOffline({
        checkResult: checklistResultItemOffline,
        override: override,
      })
    );
    dispatch(
      createNewRequest({
        checkResult: checklistResultItemOffline,
        override: override,
      })
    );
  }
  // Close loading
  dispatch(loadingPage(false));
  dispatch(resetStateWhenOffline());
  history.push("/checklist");
}
// Create new result from POMM
export const createResultPOMM =
  (params, state, history, checklistTemp, kind, mergeStatus) => (dispatch) => {
    const { isOnline, data, type, syncRequest, isUpdateOffline, hideRemark } =
      params;
    let { checklistTmp, checklistSelected, dataSaved, dataRequests } = state;
    let checklist;
    if (checklistTemp) {
      checklistTmp = checklistTemp;
    }
    if (data) {
      checklist = JSON.parse(JSON.stringify(data));
    } else {
      if (isUpdateOffline) {
        checklist = JSON.parse(JSON.stringify(checklistSelected));
      } else {
        checklist = JSON.parse(JSON.stringify(checklistTmp));
      }
    }
    let Type;
    if (type === "normal") {
      Type = 4;
    } else if (type === "draft") {
      Type = 3;
    } else {
      Type = 5;
    }
    if (checklist) {
      let draftResult = JSON.parse(JSON.stringify(checklist));
      let modifiedResult = {
        ChecklistName: draftResult.checklistName,
        ChecklistId: draftResult.ChecklistId,
        ChecklistVersion: draftResult.version
          ? draftResult.version
          : draftResult.checklistVersion,
        CheckDate: getUnixTime(parseISO(draftResult.checkDate)),
        TimeStartCheck: draftResult.timeStartCheck,
        TimeEndCheck: draftResult.timeEndCheck || getUnixTime(new Date()),
        Inspector: draftResult.inspector,
        Inspector1: draftResult.inspector1,
        Inspector2: draftResult.inspector2,
        SaveType: Type,
        updateTime: draftResult.updateTime ? draftResult.updateTime : null,
        Status: draftResult.status,
        Id: 0,
        ArrayCheckTime: draftResult.arrayCheckTimes,
        uuid: draftResult.uuid ? draftResult.uuid : null,
        ChecklistMachine: JSON.parse(
          JSON.stringify(draftResult.ChecklistMachine)
        ),
        MergeStatus:
          mergeStatus === undefined ? draftResult.MergeStatus : mergeStatus,
        HideRemark:
          hideRemark !== undefined ? hideRemark : draftResult.hideRemark,
      };
      if (isOnline) {
        dispatch(loadingPage(true));        
      } else {
        const dataDraftDuplicated = dataSaved.filter(
          (item) =>
            (item.checklistId === draftResult.ChecklistId ||
              item.ChecklistId === draftResult.ChecklistId) &&
            Number(item.status) === 3 &&
            type === "draft"
        );
        if (dataDraftDuplicated.length) {
          const data = dataDraftDuplicated[0];
          const time = format(fromUnixTime(Number(data.timeStartCheck)));
          Modal.confirm({
            title: lang.WARMING,
            centered: true,
            content: (
              <Row>
                <Col span={24}>
                  <span className="info--text">{data.checklistName}</span> が
                  <span className="info--text">{time}</span>
                  {lang.SAVE_CL_DRAFT}
                </Col>
                <Col span={24}>{lang.DO_YOU_WANT_TO_OVERRIDE}</Col>
              </Row>
            ),
            okText: lang.OK,
            cancelText: lang.CANCEL,
            onOk: () =>
              addCheckResultOffline(draftResult, type, dispatch, history, true),
            // onCancel: () => history.push("/checklist"),
          });
        } else {
          addCheckResultOffline(draftResult, type, dispatch, history, false);
        }
      }
    }
  };
function addCheckResultOffline(draftResult, type, dispatch, history, override) {
  // Update checklist result item in `dataSaved` || `data`
  let checklistResultItemOffline = JSON.parse(JSON.stringify(draftResult));

  // Create new field `method`, type of request
  checklistResultItemOffline["method"] = "create";
  checklistResultItemOffline["type"] = type;
  checklistResultItemOffline["Override"] = !!override;
  if (type === "draft") {
    checklistResultItemOffline["status"] = 3;
    checklistResultItemOffline["statusValue"] = STATUS.STATUS_CL.WORKING;
    checklistResultItemOffline["color"] = STATUS.STATUS_COLOR.WORKING;
  } else if (type === "normal") {
    checklistResultItemOffline["status"] = 4;
    checklistResultItemOffline["statusValue"] = STATUS.STATUS_CL.COMPLETE;
    checklistResultItemOffline["color"] = STATUS.STATUS_COLOR.COMPLETE;
  } else {
    checklistResultItemOffline["status"] = 5;
    checklistResultItemOffline["statusValue"] =
      STATUS.STATUS_CL.COMPLETE_ABNORMAL;
    checklistResultItemOffline["color"] = STATUS.STATUS_COLOR.COMPLETE_ABNORMAL;
  }
  checklistResultItemOffline["updateTime"] = getUnixTime(new Date());
  if (
    checklistResultItemOffline.uuid &&
    checklistResultItemOffline.id.indexOf("checklistResult") !== -1
  ) {
    // Exists in dataRequest && dataSaved
    dispatch(updateCheckResultItemDataOffline(checklistResultItemOffline));
  } else {
    // Create new
    checklistResultItemOffline.uuid = uuidv4();
    checklistResultItemOffline.id =
      "checklistResult_" + checklistResultItemOffline.uuid;
    // Add results to dataSaved
    dispatch(
      createNewResultInSaved({
        checkResult: checklistResultItemOffline,
        override: override,
      })
    );
    // Add request to dataRequests
    dispatch(
      createNewRequest({
        checkResult: checklistResultItemOffline,
        override: override,
      })
    );
  }
  // Close loading
  dispatch(loadingPage(false));
  dispatch(resetStateWhenOffline());
  history.push("/checklist");
}
// Get bookmarked checklists
export const getChecklistsBookMark =
  (offset, factoryCode, limit) => (dispatch) => {
    let { offsetTemplateChecklist } = initialState;
    if (offset === "first") {
      offsetTemplateChecklist = 0;
    } else {
      offsetTemplateChecklist = offset;
    }
    dispatch(
      updateStatusLoading({
        name: "isLoadingTemplate",
        value: true,
      })
    );    
  };
// Get filtered checklists
export const getChecklistsFiltered =
  (params, factoryCode, offset) => (dispatch) => {
    const { type } = params;
    let { offsetFilteredChecklist } = initialState;
    if (type === "first") {
      offsetFilteredChecklist = 0;
    } else {
      offsetFilteredChecklist = offset;
    }
    dispatch(
      updateStatusLoading({
        name: "isLoadingFilter",
        value: true,
      })
    );    
  };
// Get saved checklist(checklist result)
export const getChecklistsSaved =
  (offset, factoryCode, limit) => (dispatch) => {
    let { offsetSavedChecklist } = initialState;

    if (offset === "first") {
      offsetSavedChecklist = 0;
    } else {
      offsetSavedChecklist = offset;
    }
    dispatch(
      updateStatusLoading({
        name: "isLoadingSaved",
        value: true,
      })
    );    
  };
// Get group checklist
export const getGroups = (params) => (dispatch) => {
  dispatch(
    updateStatusLoading({
      name: "isLoadingGroupCL",
      value: true,
    })
  ); 
};
// Get results (histories)
export const getResults = (params) => (dispatch) => {
  let { offsetHistory } = initialState;
};
// select check result
export const selectChecklist =
  (checklist, history, updatedTemp) => (dispatch) => {
    let result = JSON.parse(JSON.stringify(checklist));
    const userId = localStorage.getItem("userName") || null;
    result.inspectorId = userId;
    if (!result.timeEndCheck) {
      result.timeEndCheck = getUnixTime(new Date());
    }
    dispatch(
      setTab({
        selectedTab: lang.COMMON,
        indexSelectedTab: 0,
      })
    );
    dispatch(
      setChecklistId({
        id: result.id,
        page: "list",
        checklist: {
          ...result,
        },
        history: history,
        isUpdated: updatedTemp,
      })
    );
  };
// go to history detail
export const selectHistoryCheckResult =
  (checklist, history, isChangeMode) => (dispatch) => {
    let result = JSON.parse(JSON.stringify(checklist));
    dispatch(
      setTab({
        selectedTab: lang.COMMON,
        indexSelectedTab: 0,
      })
    );
    dispatch(
      setChecklistId({
        id: result.id,
        page: "history-detail",
        checklist: {
          ...result,
        },
        history: history,
        isChangeMode: isChangeMode,
      })
    );
  };
// handle bookmark or delete bookmark
export const bookmarkById = (params) => (dispatch) => {
  dispatch(setLoadingBookmark(true));  
};
// get checklist (history)
export const getChecklistsHistory =
  (factoryCode, offset, params) => (dispatch) => {
    let { offsetHistory } = initialState;

    if (offset === "first") {
      offsetHistory = 0;
    } else {
      offsetHistory = offset;
    }
    dispatch(
      updateStatusLoading({
        name: "isLoadingHistory",
        value: true,
      })
    );    
  };
// update check time
export const updateCheckTime = (payload, state) => (dispatch) => {
  const { checklistSelected, checklistTmp, checklistId } = state;
  let checklist;

  if (checklistId === temporaryId) {
    checklist = JSON.parse(JSON.stringify(checklistTmp));
  } else {
    checklist = JSON.parse(JSON.stringify(checklistSelected));
  }

  checklist[payload.name] = payload.value;
  dispatch(updateTempTimeCheck(checklist));
};
export const getDataJudgment = () => (dispatch) => {  
};
export const getWorkingStatus = () => (dispatch) => {  
};
// update checkTime label
export const updateCheckTimeLabel = (payload) => (dispatch) => {
  const { id, time } = payload;

  const { checklistSelected, checklistTmp, checklistId } = initialState;
  let checklist;

  if (checklistId === temporaryId) {
    checklist = JSON.parse(JSON.stringify(checklistTmp));
  } else {
    checklist = JSON.parse(JSON.stringify(checklistSelected));
  }

  if (checklist["timeGroups"]) {
    const index = checklist["timeGroups"].findIndex((item) => item.id === id);

    if (index >= 0) {
      checklist["timeGroups"][index]["time"] = time;

      dispatch(updateTempTimeCheck(checklist));
    }
  }
};
// get list checklist name
export const getChecklistName = (factoryCode) => (dispatch) => {  
};
// get status of check result
export const getCheckResultStatus = (factoryCode) => (dispatch) => {  
};
// get all groups checklist name
export const getAllGroupsName = () => (dispatch) => {  
};
// ************ GETTER: filter state to get data ************//

export const getChecklist = (state) => {
  const { checklistId } = state;
  if (checklistId === temporaryId) {
    return state.checklistTmp;
  } else {
    return state.checklistSelected;
  }
};

function updateEachChecklist(checklist, type) {
  const groups = [];
  let arrayTypes = [];
  let objMachineStatus = {};
  let objNoneInput = {};
  let objErrorId = {};
  if (Number(checklist.type) === 2) {
    let arrayTimes = checklist.times.split(",");
    arrayTimes.forEach((item, index) => {
      arrayTypes.push({
        checkTime: index + 1,
        timeChecks: item,
      });
    });
    arrayTypes.forEach((item) => {
      objMachineStatus = Object.assign({}, objMachineStatus, {
        [item.checkTime]: 0,
      });
      objNoneInput = Object.assign({}, objNoneInput, {
        [item.checkTime]: null,
      });
      objErrorId = Object.assign({}, objErrorId, {
        [item.checkTime]: [],
      });
    });
  }
  const handleValueInit = (item, a) => {
    switch (Number(item.defaultType)) {
      case 1:
        return item.value;
      default:
        return item.defaultValue;
    }
  };
  checklist["arrayCheckTimes"] = arrayTypes;
  if (checklist) {
    checklist.checklistGroups.forEach((item) => {
      groups.push(item);
    });
    // Create machines list - Enable/Disable machine
    const machines = [];
    groups.forEach((g) => {
      const parts = g.checklistMachines;
      let total = 0;
      parts.forEach((p) => {
        if (p.resultMachineStatus === 3) {
          total++;
        }
        let arrayCheckItem = [];
        let arrayCheckItemMain = [];
        p.checkItems.forEach((item) => {
          if (Number(item.type) === 2 && item.checkTime === 0) {
            // checkItem is type check more times
            let arrTimes = item.times.split(",");
            arrTimes.forEach((time) => {
              arrayCheckItem.push({
                checklistItemId: item.checklistItemId,
                value: item.value !== null ? item.value : handleValueInit(item),
                checkItemId: item.id,
                checkTime: time,
                inputJudgment: item.inputJudgment,
                inspectionTime: item.inspectionTime,
                previousValue: item.previousValue,
                previousTime: item.previousTime,
                replenishmentAmount: item.replenishmentAmount,
                note: item.note,
                previousAccumulatedValue: item.previousAccumulatedValue,
                normalLowerControlValue: item.normalLowerControlValue,
                normalUpperControlValue: item.normalUpperControlValue,
                type: item.type,
              });
              arrayCheckItemMain.push(
                Object.assign({}, item, {
                  checkTime: time,
                  initValue:
                    item.value !== null ? item.value : handleValueInit(item),
                })
              );
            });
          }
          if (Number(item.type) !== 2 && item.checkTime === 0) {
            // checkItem is type check one time
            arrayCheckItem.push({
              checklistItemId: item.checklistItemId,
              value:
                item.value !== null ? item.value : handleValueInit(item, 1),
              checkItemId: item.id,
              checkTime: item.checkTime,
              inspectionTime: item.inspectionTime,
              previousValue: item.previousValue,
              previousTime: item.previousTime,
              inputJudgment: item.inputJudgment,
              replenishmentAmount: item.replenishmentAmount,
              note: item.note,
              previousAccumulatedValue: item.previousAccumulatedValue,
              normalLowerControlValue: item.normalLowerControlValue,
              normalUpperControlValue: item.normalUpperControlValue,
              type: item.type,
            });
            arrayCheckItemMain.push(
              Object.assign({}, item, {
                initValue:
                  item.value !== null ? item.value : handleValueInit(item),
              })
            );
          }
          if (item.checkTime) {
            // checkItem has input
            arrayCheckItem.push({
              checklistItemId: item.checklistItemId,
              value: item.value !== null ? item.value : handleValueInit(item),
              checkItemId: item.id,
              checkTime: item.checkTime,
              inspectionTime: item.inspectionTime,
              inputJudgment: item.inputJudgment,
              replenishmentAmount: item.replenishmentAmount,
              note: item.note,
              previousAccumulatedValue: item.previousAccumulatedValue,
              previousValue: item.previousValue,
              previousTime: item.previousTime,
              normalLowerControlValue: item.normalLowerControlValue,
              normalUpperControlValue: item.normalUpperControlValue,
              type: item.type,
            });
            arrayCheckItemMain.push(
              Object.assign({}, item, {
                initValue:
                  item.value !== null ? item.value : handleValueInit(item),
              })
            );
          }
        });
        p.checkItems = arrayCheckItemMain;
        if (p.needCalculateStatus) {
          const machineStatusUpdated = updateStatusMachine({
            timeLength: arrayTypes.length,
            arrayMachine: [p],
          });
          p = Object.assign(p, machineStatusUpdated[0]);
        }
        if (
          p.errorId === null ||
          (!p.errorId.length && Number(checklist.type) === 2)
        ) {
          p["errorId"] = objErrorId[1] === undefined ? [] : objErrorId;
        }
        if (!p.resultMachineStatus && Number(checklist.type) === 2) {
          p.resultMachineStatus = objMachineStatus;
          p.noneInput = objNoneInput;
        }
        if (p.workingStatus === null) {
          p.workingStatus = "1";
        }
        if (Number(checklist.type) !== 2 && !p.resultMachineStatus) {
          p.resultMachineStatus = null;
          p.noneInput = null;
        }
        if (type === "dataSaved" || type === "dataHistory") {
          // checkresult has id,check list don't have
          let arrayAttachment = [];
          if (p.attachments && p.attachments.length) {
            p.attachments.forEach((item) => {
              arrayAttachment.push(
                Object.assign(item, { url: item.attachmentUrl })
              );
            });
          }
          machines.push({
            Id: p.id,
            machineId: p.id,
            attachments: arrayAttachment,
            machineForSiteOfficeName: p.machineForSiteOfficeName,
            ChecklistMachineID: p.checklistMachineId,
            ChecklistGroupID: p.checklistGroupId,
            ItemOrder: p.itemOrder,
            resultMachineStatus: p.resultMachineStatus,
            machineForSiteOfficeCode: p.machineForSiteOfficeCode,
            Note: p.note,
            CheckResultContent: arrayCheckItem,
            errorId: p.errorId,
            noneInput: p.noneInput,
            saveStatus: true,
            workingStatus: p.workingStatus,
          });
        } else {
          machines.push({
            Id: 0,
            machineId: p.id,
            attachments: p.attachments ? p.attachments : null,
            machineForSiteOfficeName: p.machineForSiteOfficeName,
            ChecklistMachineID: p.checklistMachineId,
            ChecklistGroupID: p.checklistGroupId,
            ItemOrder: p.itemOrder,
            Note: "",
            resultMachineStatus: p.resultMachineStatus,
            machineForSiteOfficeCode: p.machineForSiteOfficeCode,
            CheckResultContent: arrayCheckItem,
            saveStatus: true,
            errorId: [],
            noneInput: p.noneInput,
            workingStatus: p.workingStatus,
          });
        }
      });
      if (total === parts.length) {
        g.tabComplete = true;
      } else {
        g.tabComplete = false;
      }
    });
    // save data for api
    checklist["ChecklistMachine"] = machines;
    if (checklist.bookmarkId) {
      checklist["isBookmark"] = true;
    } else {
      checklist["isBookmark"] = false;
    }
    // Create status, status color for detect status's color
    if (checklist.status) {
      const colorIndex = Constants.STATUS_LIST.findIndex(
        (item) => item.key === checklist.status
      );

      if (colorIndex !== -1) {
        const colorCode = Constants.STATUS_LIST[colorIndex]["color"];

        checklist["color"] = colorCode;
        checklist["statusValue"] = Constants.STATUS_LIST[colorIndex].value;
      }
    }
    if (type === "dataSaved" || type === "dataHistory") {
      checklist["CheckResultId"] = checklist.id;
    }
    if (type === "dataTemplate" || type === "dataTemplateFiltered") {
      checklist["ChecklistId"] = checklist.id;
    }
  }
  return checklist;
}

function updateStatusMachine(data) {
  const { timeLength, arrayMachine } = data;
  const arrayMachineUpdated = [];
  if (timeLength) {
    arrayMachine.forEach((machine) => {
      let noneInput = {};
      let errorId = {};
      let resultMachineStatus = {};
      for (let i = 1; i <= timeLength; i++) {
        let noneInputChild = 0;
        let errorIdChild = [];
        let resultMachineStatusChild = null;
        let itemsLength = 0;
        machine.checkItems.forEach((item) => {
          if (Number(item.checkTime) === i || Number(item.checkTime) === 0) {
            itemsLength++;
            if (item.value === "" || item.value === null) {
              noneInputChild++;
            } else {
              if (Number(item.mode) === TYPE_INPUT.NUMBER) {
                const val = parseFloat(Number(item.value));
                const normalUpperControlValue = item.normalUpperControlValue;
                const normalLowerControlValue = item.normalLowerControlValue;
                if (
                  normalUpperControlValue !== null &&
                  normalLowerControlValue !== null &&
                  !(
                    normalLowerControlValue <= val &&
                    val <= normalUpperControlValue
                  )
                ) {
                  errorIdChild.push(item.id);
                }

                // Min
                if (
                  normalUpperControlValue === null &&
                  normalLowerControlValue !== null &&
                  val < normalLowerControlValue
                ) {
                  errorIdChild.push(item.id);
                }

                // Max
                if (
                  normalUpperControlValue !== null &&
                  normalLowerControlValue === null &&
                  val > normalUpperControlValue
                ) {
                  errorIdChild.push(item.id);
                }
              }
            }
          }
        });
        if (errorIdChild.length) {
          resultMachineStatusChild = 2;
        } else {
          if (noneInputChild) {
            if (noneInputChild !== itemsLength) {
              resultMachineStatusChild = 1;
            } else {
              noneInputChild = null;
            }
          } else {
            resultMachineStatusChild = 3;
          }
        }
        noneInput[i] = noneInputChild;
        errorId[i] = errorIdChild;
        resultMachineStatus[i] = resultMachineStatusChild;
      }
      arrayMachineUpdated.push({
        id: machine.id,
        noneInput: noneInput,
        errorId: errorId,
        resultMachineStatus: resultMachineStatus,
      });
    });
  } else {
    arrayMachine.forEach((machine) => {
      let noneInput = 0;
      let errorId = [];
      let resultMachineStatus = null;
      machine.checkItems.forEach((item) => {
        if (item.value === "" || item.value === null) {
          noneInput++;
        } else {
          if (Number(item.mode) === TYPE_INPUT.NUMBER) {
            const val = parseFloat(Number(item.value));
            const normalUpperControlValue = item.normalUpperControlValue;
            const normalLowerControlValue = item.normalLowerControlValue;
            if (
              normalUpperControlValue !== null &&
              normalLowerControlValue !== null &&
              !(
                normalLowerControlValue <= val && val <= normalUpperControlValue
              )
            ) {
              errorId.push(item.id);
            }

            // Min
            if (
              normalUpperControlValue === null &&
              normalLowerControlValue !== null &&
              val < normalLowerControlValue
            ) {
              errorId.push(item.id);
            }

            // Max
            if (
              normalUpperControlValue !== null &&
              normalLowerControlValue === null &&
              val > normalUpperControlValue
            ) {
              errorId.push(item.id);
            }
          }
        }
      });
      if (errorId.length) {
        resultMachineStatus = 2;
      } else {
        if (noneInput) {
          if (noneInput !== machine.checkItems.length) {
            resultMachineStatus = 1;
          } else {
            noneInput = null;
          }
        } else {
          resultMachineStatus = 3;
        }
      }
      arrayMachineUpdated.push({
        id: machine.id,
        noneInput: noneInput,
        errorId: errorId,
        resultMachineStatus: resultMachineStatus,
      });
    });
  }
  return arrayMachineUpdated;
}
