const STATUS_LIST = [
  {
    key: "3",
    label: "作業中", // Doing
    value: "作業中",
    color: "#f16900",
  },
  {
    key: "4",
    label: "完了", // Save
    value: "完了",
    color: "#0080CB",
  },
  {
    key: "5",
    label: "完了(異常終了)", // Save Ab
    value: "完了(異常終了)",
    color: "#006aa9",
  },
  {
    key: "2",
    label: "拒否済", // Reject
    value: "拒否済",
    color: "#7200cb",
  },
  {
    key: "1",
    label: "承認解除", // Unapproved
    value: "承認解除",
    color: "#CA5C54",
  },
  {
    key: "0",
    label: "承認済", // Approve
    value: "承認済",
    color: "#2FAD5A",
  },
];
const STATUS_CL = {
  APPROVED: "承認済",
  UNAPPROVED: "承認解除",
  REJECT: "拒否済",
  COMPLETE: "完了",
  COMPLETE_ABNORMAL: "完了(異常終了)",
  WORKING: "作業中",
};
const STATUS_CL_MANAGEMENT = {
  // Draft
  0: "#6c757d",
  下書: "#6c757d",
  // Save
  1: "#0080CB",
  正式: "#0080CB",
};
const STATUS_COLOR = {
  APPROVED: "#2FAD5A",
  UNAPPROVED: "#CA5C54",
  REJECT: "#7200cb",
  COMPLETE: "#0080CB",
  COMPLETE_ABNORMAL: "#006aa9",
  WORKING: "#f16900",
};
const STATUS_MACHINE = [
  { key: 0, value: "uncheck" },
  { key: 1, value: "updated" },
  { key: 2, value: "error" },
];
export const MACHINE_MANAGEMENT_STARUS = [
  {
    key:1,
    value : "稼働中"
  },
  {
    key:2,
    value : "停止中"
  },
  {
    key:3,
    value : "休止中"
  },
  {
    key:4,
    value : "故障中"
  },
  {
    key:5,
    value : "修理中"
  },
  {
    key:6,
    value : "廃棄"
  },
  
]


const STATUS_HOME_BANNER = {
  NOTSEEN: "0",
  SEEN: "1",
};

const TYPE_HOME_BANNER = {
  // Regular
  1: "#1fc42f",
  // Emergency
  2: "#ED3A3E",
  // Operation
  3: "#0080CB",
  // Other
  4: "#7200cb",
};

export default {
  STATUS_LIST: STATUS_LIST,
  STATUS_MACHINE: STATUS_MACHINE,
  STATUS_CL: STATUS_CL,
  STATUS_COLOR: STATUS_COLOR,
  STATUS_CL_MANAGEMENT: STATUS_CL_MANAGEMENT,
  STATUS_HOME_BANNER: STATUS_HOME_BANNER,
  TYPE_HOME_BANNER: TYPE_HOME_BANNER
};

export const PROCESS_STATUS = [
  {
    key:"1",
    value : "稼働中"
  },
  {
    key:"2",
    value : "停止中"
  },
  {
    key:"3",
    value : "休止中"
  },
  {
    key:"4",
    value : "故障中"
  },
  {
    key:"5",
    value : "修理中"
  },
  {
    key:"6",
    value : "廃棄"
  },
  
]
