import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  searchParam: "",
};

const searchContractSlice = createSlice({
  name: "searchContract",
  initialState,
  reducers: {
    setSearchParam: (state, { payload }) => {
      state.searchParam = payload;
    },
  },
});

// the action
export const {
  setSearchParam,
} = searchContractSlice.actions;

//the selector
export const seachContractSelector = (state) => state.searchContract;

// the reducer
export default searchContractSlice.reducer;
