import { KEY, lang } from "@src/constants/common.const";
import ROUTER_PAGE from "@src/constants/router.const";
import { collapsedSideBar, updateTitleHeader } from "@src/redux/sideBar";
import CheckedIcon from "@src/styles/icon/checked.svg";
import { Button, Col, Drawer, Input, Layout, Menu, Row } from "antd";
import React, { Fragment, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./sideBar.scss";
// icon
import { CaretDownOutlined } from "@ant-design/icons";
import { openConfirm } from "@src/redux/confirm";
import Logo from "@src/styles/icon/logo.svg";
import { useMediaQuery } from "react-responsive";
import { listAllMenu } from "../../constants/menu.const";

const { Sider } = Layout;

function SideBar() {
  const { collapsed, listMenu } = useSelector((state) => state.sideBar);
  const { inMultipleSites, factories, selectedFactoryName } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, updateCurrentPage] = useState(null);
  const [openSelectOffice, setOpenSelectOffice] = useState(false);
  const [arrayFactories, updateArrayFactories] = useState([]);
  const { isUpdated } = useSelector((state) => state.checklist);
  const toggleButton = () => {
    dispatch(collapsedSideBar(!collapsed));
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  useEffect(() => {
    updateArrayFactories(factories);
  }, [factories]);
  const updateArraySearch = (e) => {
    const value = String(e.target.value).toLowerCase();
  };
  const currentRouter = (route) => {
    if (isUpdated && route) {
      dispatch(
        openConfirm({
          open: true,
          data: { left: "OK", right: "cancel" },
          typeConfirm: "error",
          redirectUrl: route,
        })
      );
    } else {
      let routers = ROUTER_PAGE.ROUTER_PAGE;
      let routerPath = history.location.pathname;
      let keyCurrent = currentPage;
      let isUpdatedRouter = false;
      for (const [key, value] of Object.entries(routers)) {
        if (
          key !== keyCurrent &&
          [
            "HOME_BANNER",
            "WORK_REPORT",
            "CL_MANAGEMENT",
            "INPUT_REST_LAST_VALUE",
            "IMPORT_INVENTORY",
            "OFFICE_MANAGEMENT",
            "EXPORT_INVENTORY",
            "WAREHOUSES_MASTER",
            "CATEGORY_MASTER",
            "ITEM_MASTER",
            "MACHINE_MANAGEMENT",
            "FACILITY_MANAGEMENT",
            "PROCESS_MANAGEMENT",
            "CONTRACT_PARENT",
            "REPRESENTATIVE_MASTER",
          ].includes(key)
        ) {
          let isUpdated1 = false;
          value.forEach((item) => {
            if (routerPath.includes(item)) {
              isUpdated1 = true;
            }
          });
          if (isUpdated1) {
            keyCurrent = key;
            isUpdatedRouter = true;
          }
        } else {
          if (routerPath.includes(value) && key !== keyCurrent) {
            keyCurrent = key;
            isUpdatedRouter = true;
          }
        }
      }
      if (isUpdatedRouter) {
        dispatch(updateTitleHeader(keyCurrent));
        updateCurrentPage(keyCurrent);
      }
    }
  };
  useEffect(() => {
    if (!isUpdated) {
      currentRouter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, isUpdated]);

  const renderMenuItem = (menuItem, isSub) => {
    if (menuItem.haveScreen === 1) {
      return !isSub ? (
        <Fragment key={menuItem.key}>
          <Menu.Item
            key={menuItem.key}
            icon={<menuItem.icon style={{ fontSize: "20px" }} />}
            className="customMenuItemMain"
            onClick={() => currentRouter(menuItem.path)}
          >
            {!isUpdated ? (
              <Link to={menuItem.path}>
                <span>{menuItem.menuName}</span>
              </Link>
            ) : (
              <span>{menuItem.menuName}</span>
            )}
          </Menu.Item>
          <hr className="menu-bottom-divider" />
        </Fragment>
      ) : (
        <Menu.Item
          key={menuItem.key}
          icon={<menuItem.icon style={{ fontSize: "20px" }} />}
          className="customMenuItemMain"
          onClick={() => currentRouter(menuItem.path)}
        >
          {!isUpdated ? (
            <Link to={menuItem.path}>
              <span>{menuItem.menuName}</span>
            </Link>
          ) : (
            <span>{menuItem.menuName}</span>
          )}
        </Menu.Item>
      );
    }
    if (menuItem.haveScreen === 0 && menuItem.subMenus.length > 0) {
      return (
        <Fragment key={menuItem.key}>
          <Menu.SubMenu
            key={menuItem.key}
            icon={<menuItem.icon style={{ fontSize: "20px" }} />}
            title={menuItem.menuName}
            className={`${
              isDesktopOrLaptop ? "customSubMenu" : "customSubMenu--mobile"
            }`}
          >
            {menuItem.subMenus.map((item) => renderMenuItem(item, true))}
          </Menu.SubMenu>
          <hr className="menu-bottom-divider" />
        </Fragment>
      );
    }
  };

  const filterMenu = (allMenu, currentMenu) => {
    return allMenu.filter(
      (item) =>
        currentMenu.findIndex(
          (currentItem) => currentItem.menuCode === item.menuCode
        ) >= 0
    );
  };
  const findMenuItem = (menuItem, listCurrent) =>
    listCurrent.find((item) => item.menuCode === menuItem.menuCode);

  const listMenuFinal = (listAllMenu, currentMenu) => {
    let menuFilter = filterMenu(listAllMenu, currentMenu);
    return menuFilter.map((menuFilterItem) => {
      let newSub;
      if (
        menuFilterItem.haveScreen === 0 &&
        menuFilterItem.subMenus.length > 0
      ) {
        const listSubCurrent = findMenuItem(menuFilterItem, currentMenu);
        if (listSubCurrent) {
          newSub = filterMenu(menuFilterItem.subMenus, listSubCurrent.subMenus);
        }
      }
      return { ...menuFilterItem, subMenus: newSub };
    });
  };
  const renderMenu = useMemo(
    () =>
      listMenuFinal(listAllMenu, listMenu).map((item) =>
        renderMenuItem(item, false)
      ),
    [listMenu, isUpdated]
  );

  return isDesktopOrLaptop ? (
    <Sider
      collapsible
      collapsed={collapsed}
      width={220}
      onCollapse={toggleButton}
      className="navigation-background"
      collapsedWidth={70}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <div className="logo">
        <Row className="rowNotMargin" align="middle" justify="center">
          <Col style={{ marginTop: "20px" }}>
            {collapsed ? (
              <img style={{ height: "44px" }} src={Logo} alt="logo" />
            ) : (
              <img style={{ height: "60px" }} src={Logo} alt="logo" />
            )}
          </Col>
        </Row>
      </div>
      {!collapsed && (
        <Row
          justify="center"
          style={{
            color: "#fff",
            fontSize: "14px",
            fontWeight: "bold",
            paddingTop: "8px",
          }}
        >
          {lang.NAME_SYSTEM}
        </Row>
      )}
      <Menu
        mode="vertical"
        theme="dark"
        selectedKeys={[currentPage]}
        style={{
          width: "100%",
          marginTop: collapsed ? "10px" : "20px",
          paddingBottom: 50,
        }}
      >
        {renderMenu}
      </Menu>
      {!collapsed && (
        <Row className="copy-right">
          <Col>{lang.COPYRIGHT}</Col>
        </Row>
      )}
    </Sider>
  ) : (
    <>
      <Drawer
        placement="bottom"
        onClose={() => setOpenSelectOffice(false)}
        visible={openSelectOffice}
        className="custom-drawer--select"
        height="90%"
        title={<span className="title">{lang.SELECT}</span>}
      >
        <Input onChange={updateArraySearch} allowClear />
        <Row className="rowNotMargin">
          {arrayFactories.length !== 0 &&
            arrayFactories.map((item, index) => (
              <Col span={24} key={index}>
                <Row className="rowNotMargin">
                  <Col flex="auto" className="text-center name-office"></Col>
                  <Col flex="none" className="icon">
                    <img src={CheckedIcon} alt="icon" />
                  </Col>
                </Row>
              </Col>
            ))}
        </Row>
      </Drawer>
      <Drawer
        placement="left"
        className="custom-drawer"
        onClose={toggleButton}
        closable={false}
        visible={collapsed}
        key={"left"}
        footer={
          <Row className="copy-right__mobile" justify="center">
            <Col className="text-center">{lang.COPYRIGHT}</Col>
          </Row>
        }
      >
        <div className="logo">
          <Row className="rowNotMargin" align="middle" justify="center">
            <Col
              span={20}
              style={{ marginTop: "20px" }}
              className="text-center"
            >
              <img
                style={{ height: "44px", paddingLeft: "30px" }}
                src={Logo}
                alt="logo"
              />
            </Col>
            <Col span={4}>
              <button className="hamburger opened" onClick={toggleButton}>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </Col>
          </Row>
        </div>
        {!collapsed && (
          <Row
            justify="center"
            style={{
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              paddingTop: "8px",
            }}
          >
            {lang.NAME_SYSTEM}
          </Row>
        )}
        <Row className="rowNotMargin" style={{ padding: "24px 8px 8px" }}>
          {!!inMultipleSites ? (
            <Button
              style={{ width: "100%" }}
              onClick={() => {
                setOpenSelectOffice(true);
                toggleButton();
              }}
              className="selectOffice"
            >
              <Row className="rowNotMargin">
                <Col flex="auto" className="selectOffice_name">
                  {selectedFactoryName}
                </Col>
                <Col flex="none">
                  <CaretDownOutlined />
                </Col>
              </Row>
            </Button>
          ) : (
            selectedFactoryName && (
              <Input
                className="selectOffice"
                value={selectedFactoryName}
                readOnly
              />
            )
          )}
        </Row>

        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={[currentPage]}
          style={{ width: "100%", marginTop: collapsed ? "10px" : "20px" }}
          onClick={() => toggleButton()}
        >
          {renderMenu}
        </Menu>
      </Drawer>
    </>
  );
}

export default SideBar;
