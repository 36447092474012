import Api from "@src/api";

export default {
  // 1: Search-----
  search(data) {
    return Api().get("ContractParent/SearchContractParent", data);
  },
  // 2. Update
  update(oyaseino, csncb1) {
    return Api().put(
      `ContractParent/Accept?oyaseino=${oyaseino}&&csncb1=${csncb1}`
    );
  },
  // 3. Delete
  delete(data) {
    return Api().put(`ContractParent/Delete`, data);
  },
  revert(data) {
    return Api().put(`ContractParent/Revert`, data);
  },

  //Get parent contract is deleted TamNM10
  LoadGridviewContractRM(datasearch) {
    return Api().get("ContractParent/SearchContractIsDeleted", datasearch);
  },

  //HieuLD6
  //1: detail
  detail(oyaseino, csncb1) {
    return Api().get(
      `ContractParent/Detail?oyaseino=${oyaseino}&&csncb1=${csncb1}`
    );
  },
  //2 : get status contract
  getStatusContract(oyaseino, csncb1) {
    return Api().get(
      `ContractParent/GetStatus?oyaseino=${oyaseino}&&csncb1=${csncb1}`
    );
  },
  //3: accept contract
  accept(oyaseino, csncb1, acceptFlg) {
    return Api().put(
      `ContractParent/Accept?oyaseino=${oyaseino}&&csncb1=${csncb1}&&acceptFlg=${acceptFlg}`
    );
  },

  //TuanNA95
  getOfficeName() {
    return Api().get(`RepresentativeMaster/GetOfficeName`);
  },

  getListSubNoLicense(data) {
    return Api().get(`ContractSub/GetSubContractNotLicense`, data);
  },

  exportcsv(data) {
    return Api().get("ContractParent/ExportCSV", data);
  },

  getDepartmentName() {
    return Api().get(`ContractParent/GetDepartmentName`);
  },

  getRepresentativeName() {
    return Api().get(`ContractParent/GetRepresentativeName`);
  },

  getDataPktphm20(trhsakcd, trhsaknm1, flagSearch, page) {
    return Api().get(
      `ContractParent/GetDataPktphm20?trhsakcd=${trhsakcd}&&trhsaknm1=${trhsaknm1}&&flagSearch=${flagSearch}&&page=${page}`
    );
  },

  updateContractParent(formData) {
    return Api().post(
      "ContractParent/UpdateContractParent",
      formData,
      {
        // CREATE
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  uploadFilesToStorage(formData) {
    return Api().post(
      "ContractParent/ImportFile",
      formData,
      {
        // CREATE
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  acceptSubContract(thino, acceptFlg) {
    return Api().put(
      `ContractSub/Accept?thino=${thino}&&acceptFlg=${acceptFlg}`
    );
  },

  getDataByChangeComboboxDepartment(oyaseino, csncb1, bunm) {
    return Api().get(
      `ContractParent/GetDataByChangeComboboxDepartment?oyaseino=${oyaseino}&&csncb1=${csncb1}&&bunm=${bunm}`
    );
  },

  updateLockContract(formData) {
    return Api().post(
      "ContractParent/updateLockContract",
      formData,
      {
        // CREATE
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
};
