import React, { useEffect } from "react";
import "./App.scss";
import "./styles/css/common.scss";
import "./styles/css/mobile.scss";
import "./styles/css/vars.scss";
import Router from "@src/routers/router";
import { Layout } from "antd";
import Loading from "@src/component/Popup/loading";
import Confirm from "@src/component/Popup/confirm";
import { useDispatch, useSelector } from "react-redux";
import { changeStatusNetWork, getUserInfo } from "@src/redux/auth";
import { useHistory } from "react-router-dom";
import { InteractionType } from "@azure/msal-browser";
import {
  MsalAuthenticationTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "@src/api/config";
import axios from "axios";

const { Content } = Layout;
// reset item page to select menu when reload page or destroy app
window.onbeforeunload = function () {
  localStorage.removeItem("page");
};

function App() {
  const { instance, accounts } = useMsal();
  const { currentUser, currentRouter, changeRoute } = useSelector(
    (state) => state.checklist
  );
  // const { inMultipleSites } = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const onOffline = () => {
    dispatch(changeStatusNetWork(false));
  };
  const onOnline = () => {
    dispatch(changeStatusNetWork(true));
  };

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      const getInfo = async () => {
        await instance
          .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          })
          .then((response) => {
            // Setting axios
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${response.accessToken}`;
            // Get user info
            dispatch(getUserInfo(currentUser));
          });
      };
      getInfo();
    }
  }, [accounts]);

  useEffect(() => {
    if (changeRoute !== null) {
      let route = history.location.pathname;
      if (changeRoute) {
        history.push("/");
      } else {
        // in case open new tab with new route below while using input checklist
        if (
          !(route.includes("/ViewReport") || route.includes("/history-detail") || route.includes("/ViewMachine"))
        ) {
          if (currentRouter.includes("/list")) {
            history.push(currentRouter);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeRoute]);
  let vh = window.innerHeight * 0.01;
  // set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
  useEffect(() => {
    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);
    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Layout className="layout" id="layout">
          <Content>
            <Router />
          </Content>
          <Loading />
          <Confirm />
        </Layout>
      </MsalAuthenticationTemplate>
    </>
  );
}
export default App;
