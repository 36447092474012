import _ from "lodash";
export function checkMenu(arrMenu, arrCode) {
  return arrMenu.filter(({ menuCode }) => {
    return arrCode.includes(menuCode);
  });
}

export function getAllowedRoutes(routes, listItemCode) {
  // Filter menu parent
  const listRoute = _.cloneDeep(routes);
  let menuNew = checkMenu(listRoute, listItemCode);
  // Filter menu child exist
  menuNew = menuNew.map((m) => {
    if (m.children) {
      m.children = checkMenu(m.children, listItemCode);
    }
    return m;
  });
  return menuNew;
}

export function checkRoleScreen(screen, allowRoute) {
  let isRedirect = false;
  allowRoute.forEach((item) => {
    if(checkCoincidePath(item.path,screen)){
      isRedirect = true;
    }
  });
  return isRedirect;
}

export const checkCoincidePath = (path1,path2) =>{
  //path1 "/abc/:id"
  //path2 "/abc/123"
  const indexColon = path1.indexOf(":");
  if(indexColon >= 0){
    path1 = path1.substring(0,indexColon);
    return path2.includes(path1)
  }
  return path2 === path1;
}