import axios from "axios";
import { config } from "./config";
import { logOut } from "./config";
let unauthen = false;
export default (timeout) => {
  const apiClient = axios.create({
    baseURL: config.backendEndpoint,
    timeout:
      timeout !== undefined && timeout !== null
        ? timeout
        : config.timeoutDefault,
  });
  apiClient.interceptors.request.use(
    async (config) => {
      return { ...config };
    },
    (error) => {
      if (error.request.status === 401) {
        logOut();
      }
      return Promise.reject(error);
    }
  );

  apiClient.interceptors.response.use(
    (response) => {
      unauthen = false;
      return response;
    },
    (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (!unauthen) {
            unauthen = true;
            logOut();
          }
        }
        // return error.response;
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log(error);
        logOut();
      }
      return Promise.reject(error);
    }
  );
  return apiClient;
};
