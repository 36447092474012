import React from "react";
import "./footerPage.scss";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { KEY } from "../../constants/common.const";

function FooterPage({ children }) {
  const { collapsed } = useSelector((state) => state.sideBar);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  return (
    <>
      <div className="footer-page-top"></div>
      <div
        className="footer-page"
        style={{
          width: isPC
            ? `calc(100vw - ${collapsed ? "70px" : "220px"})`
            : "100%",
          height: "auto",
        }}
      >
        {children}
      </div>
    </>
  );
}

export default FooterPage;
