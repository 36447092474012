import { combineReducers } from "redux";
import navBarReducer from "./navBar";
import loadingReducer from "./loading";
import confirmReducer from "./confirm";
import checkListReducer from "./checkList";
import settingReducer from "./setting";
import reportReducer from "./reportManagement";
import authReducer from "./auth";
import sideBarReducer from "./sideBar";
import searchContractReducer from "./searchContract";

const rootReducer = combineReducers({
  auth: authReducer,
  navBar: navBarReducer,
  loading: loadingReducer,
  confirm: confirmReducer,
  checklist: checkListReducer,
  setting: settingReducer,
  reportManagement: reportReducer,
  sideBar: sideBarReducer,
  searchContract: searchContractReducer,
});

export default rootReducer;
