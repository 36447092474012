import React, { useState, useEffect } from "react";
import TitlePage from "../../component/Desktop/titlePage";
import GroupItem from "../../component/Desktop/groupItem";
import RowInput from "../../component/Desktop/rowInput";
import Loading from "../../component/Desktop/loading/loading";
import { KEY, lang, COMMON_CONSTANT } from "../../constants/common.const";
import API from "../../api/backend/contractSub";
import BreakLine from "../../component/Desktop/breakLine";
import {
  MSG_CATCH,
  showMessage,
  statusRes,
  getAttachName
} from "../../constants/utils";
import { useHistory, useParams } from "react-router-dom";
import { RollbackOutlined, CheckCircleOutlined, EditFilled } from "@ant-design/icons";

import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  Col,
  Row,
  Space,
  Button,
  Modal
} from "antd";
import { formatNumber } from "../../utils/helper";

function SubContractDetail({ history: historyPage }) {
  let history = useHistory();

  let [showLoading, setShowLoading] = useState(false);
  // Loading
  let openLoadingSpinner = () => {
    setShowLoading(true);
  };

  let closeLoadingSpinner = () => {
    setShowLoading(false);
  };

  //state auth
  const { allowRoute } = useSelector((state) => state.auth);

  // get param
  let { oyaseino, thino } = useParams();

  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  //declare variable data grid
  const [dataDetail, setDataDetail] = useState({});
  const [sDnk, setSDnk] = useState('');
  const [checkButtonEdit, setCheckButtonEdit] = useState(1);
  const [txtSubcontractorNumber, setTxtSubcontractorNumber] = useState('');
  const getRole = JSON.parse(localStorage.getItem("uInfo")).role || '';
  const userId = JSON.parse(localStorage.getItem("uInfo")).userName || '';
  const [checkLockContract, setCheckLockContract] = useState(false);

  //init
  useEffect(() => {
    init();
  }, [allowRoute]);

  const init = () => {
    // Open loading
    openLoadingSpinner();
    return API.detail(oyaseino, thino)
      .then((res) => {
        let data = res.data ? res.data : null;
        setDataDetail(data);
        const dnk = data.listDnk.filter((item) => item.value == data.dnk);
        if (dnk.length > 0) {
          setSDnk(dnk[0].label);
        }

        if (data.flagDel && data.flagDel == '2') {
          setCheckButtonEdit(2);
        } else if (data.flagDel == null) {
          setCheckButtonEdit(1);
        } else {
          setCheckButtonEdit(3);
        }

        // if (data.contractoR_NUMBER) {
        //   const myArray = data.contractoR_NUMBER.split(";");
        //   const getcontractNumber = data.listContractNumber.filter((item) => item.id == myArray[0]);
        //   if (getcontractNumber.length > 0) {
        //     setTxtSubcontractorNumber(getcontractNumber[0].licensennumber);
        //   }
        // }
        if (data.ksgkykbng) {
          setTxtSubcontractorNumber(data.ksgkykbng);
        }

        if (data.lock_contract && data.lock_contract == "1") {
          setCheckLockContract(true);
        } else {
          setCheckLockContract(false);
        }

      })
      .catch((err) => {
        showMessage(KEY.ERROR, MSG_CATCH());
      })
      .then(() => {
        closeLoadingSpinner();
      });
  };

  function backToSearch(checkButton) {
    if (checkButton == 1) {
      setCheckButtonEdit(2);
    } else {
      setCheckButtonEdit(1);
    }
    // history.goBack();
  }

  // Popup common
  const showConfirmMessage = (
    msg,
    msg2,
    callbackOK,
    callbackCancel,
    typeInfo = false
  ) => {
    let ok, cancel;
    let callBackDefault = () => {
      Modal.destroyAll();
    };

    // Callback OK exist
    if (callbackOK) {
      ok = () => {
        callbackOK();
        callBackDefault();
      };
    }
    // Callback Cancel exist
    if (callbackCancel) {
      cancel = () => {
        callbackCancel();
        callBackDefault();
      };
    }

    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: (
        <NewLineContent
          text={
            msg2 ? msg + "\n" + msg2 : msg
          }
        />
      ),
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onCancel: cancel || callBackDefault,
      onOk: ok || callBackDefault,
    });
  };

  function NewLineContent(props) {
    const text = props.text;
    const newText = text.split("\n");
    return (
      <div>
        <p>{newText[0]}</p>
        <p style={{ color: "red" }}>{newText[1]}</p>
      </div>
    );
  }

  function backToContractParent() {
    history.push(
      `/ContractParent/Detail?oyaseino=${dataDetail.oyaseino}&&csncb1=${dataDetail.csncb1}`
    );
  }

  function confirmAccOrDel(checkButton) {
    let message_confirm = checkButton == 2 ? COMMON_CONSTANT.CONFIRM_DEL_SUBCONTRACT : COMMON_CONSTANT.CONFIRM_ACCEPT_SUB;
    let message_confirm2 = checkButton == 2 ? COMMON_CONSTANT.CONFIRM_DEL_SUBCONTRACT_SUB : COMMON_CONSTANT.CONFIRM_ACC_SUBCONTRACT_SUB;
    showConfirmMessage(message_confirm, message_confirm2, function () {
      openLoadingSpinner();

      // Sent data to Server
      API.accOrDel(dataDetail.thino)
        .then((res) => {
          if (statusRes(res)) {
            // Success
            showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003, backToSearch(checkButton));
          } else {
            showMessage(KEY.ERROR, MSG_CATCH());
          }
        })
        .catch((err) => {
          let msg = "";
          if (
            err.response &&
            err.response.status === 400 &&
            err.response.data
          ) {
            msg = err.response.data.msg;
          } else {
            msg = MSG_CATCH();
          }
          showMessage(KEY.ERROR, msg);
        })
        .then(() => {
          closeLoadingSpinner();
        });
    });
  }
  console.log('checkButtonEdit', checkButtonEdit);
  return (
    <div className="des-content">
      <TitlePage name={lang.REJECT_SUBCONTRACT_DETAIL} />
      <GroupItem
        center
        style={{
          margin: isPC ? "24px 16px" : 0,
          padding: isPC ? 24 : "12px 24px",
        }}
      >
        <div className="title-page d-block left border-bottom-10px-orange mt10 pl-20 pr-20" style={{ marginBottom: "10px" }}>
          <h1 className="bold white-space">{"下請情報"}</h1>
        </div>

        <BreakLine />
        <RowInput name={lang.DEPARTMENT} box>
          {dataDetail.bunm}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.MADE} box>
          {dataDetail.oyaseino}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.CONSTRUCTION} box>
          {dataDetail.kjiryknm}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.LOCATION_NAME} box>
          {dataDetail.trhsaknM1}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.LOCATION_PROVINE} box>
          {dataDetail.prefnm}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.LOCATION_DISTRICT} box>
          {dataDetail.locationProvince}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.CONTRACT_TERM} box>
          {dataDetail.hacymd}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.SUBCONTRACTOR} box>
          {dataDetail.subcontractor}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.SUBCONTRACTOR_ADDRESS} box>
          {dataDetail.trhadrs}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.SUBCONTRACTOR_CONTRUCTION_PERMIT_NUMBER} box>
          {txtSubcontractorNumber}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.SUBCONTRACTOR_TYPE} box>
          {dataDetail.snychscd}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.CAPITAL} box>
          {dataDetail.sihonkg ? Number.parseFloat(dataDetail.sihonkg).toFixed(1) : 0} <span>&emsp;&emsp;&emsp;&emsp;&emsp;(単位 :100万円)</span>
        </RowInput>
        <BreakLine />
        <RowInput name={lang.DATE_FINSHED_CHECK} box>
          {dataDetail.knsymdFrom}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.DELIVERY_DATE} box>
          {dataDetail.knsymdTo}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.ACCEPT_TYPE} box>
          {sDnk}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.SUBCONTRACT_PAYMENT_RULES} box>
          {dataDetail.paymentRules}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.PAYMENT_RULES} box>
          {dataDetail.paymentTerms}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.SUBCONTRACT_PAYMENT_STATUS} box>
          {dataDetail.paymentStatus}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.PRODUCT_NAME} box>
          {dataDetail.buhinxnm}
        </RowInput>
        <BreakLine />
        <RowInput name={lang.ORDER_AMOUNT2} box>
          {dataDetail.prchseAmount && formatNumber(dataDetail.prchseAmount)}
        </RowInput>
        <BreakLine />
        {(dataDetail.normaluserview && dataDetail.normaluserview == '1')
          || dataDetail.revuid === userId || getRole === '0' || getRole === '1' ? (
          <>
            <RowInput name={lang.ATTACHED_DOCUMENT} box>
              <Space size={8} align="center">
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  {dataDetail.attached_document &&
                    dataDetail.attached_document.split(",").map((item) => (
                      <li>
                        <a href={item} download target="_blank">
                          {getAttachName(item.split("/")[item.split("/").length - 1], 1)}
                        </a>
                      </li>
                    ))}
                </ul>
              </Space>
            </RowInput>
            <BreakLine />
          </>
        ) : <></>}
        <Row justify="space-between">
          <Col span={24} className="right">
            {(checkButtonEdit == 1 || checkButtonEdit == 2)
              && (dataDetail.revuid === userId || getRole === '0' || getRole === '1') ? (
              <Space size={20} align="center">
                <Button
                  className="buttonPC button--info wAuto ml15"
                  onClick={() => {
                    history.push(
                      `/ContractSub/create/${oyaseino}/${thino}`
                    );
                  }}
                  disabled={checkLockContract}
                >
                  <EditFilled />
                  {" " + lang.EDIT}
                </Button>
              </Space>
            ) : (<></>)}

            {checkButtonEdit == 1 || checkButtonEdit == 2 ? (
              <Space size={20} align="center">
                {checkButtonEdit == 2 ? (
                  <Button
                    className="buttonPC button--info wAuto ml15"
                    onClick={() => confirmAccOrDel(2)}
                    disabled={checkLockContract}
                  >
                    <CheckCircleOutlined />
                    {" " + lang.BUTTON_DELETE}
                  </Button>
                ) : (
                  <></>
                )}
                {checkButtonEdit == 1 ? (
                  <Button
                    className="buttonPC button--info wAuto ml15"
                    onClick={() => confirmAccOrDel(1)}
                    disabled={checkLockContract}
                  >
                    <CheckCircleOutlined />
                    {" " + lang.HOVER_CONFIRM}
                  </Button>
                ) : (
                  <></>
                )}
              </Space>
            ) : (<></>)}
            <Space size={20} align="center">
              <Button
                className="buttonPC button--outline --todo-- wAuto ml20 mt30"
                onClick={() => backToContractParent()}
              >
                <RollbackOutlined />
                {" " + COMMON_CONSTANT.BACK}
              </Button>
            </Space>
          </Col>
        </Row>
      </GroupItem>

      <Loading show={showLoading}></Loading>
    </div>
  );
}
export default SubContractDetail;
