import Api from "@src/api";
import { KEY } from "../../constants/common.const";
export default {
  getAllGroupsName() {
    return Api().get("GroupChecklist/GetAllGroupChecklist");
  },
  getDataGroup(groupId) {
    let endpoint = `Checklists/Mobile/Group/${groupId}?offset=0&limit=9999`;
    return Api().get(endpoint);
  },
  createGroupChecklist(groupName, type = KEY.GROUP_TYPE_USER) {
    return Api().post(
      "GroupChecklist/CreateGroupChecklist?groupName=" +
        groupName +
        "&type=" +
        type
    );
  },
  getAllChecklist() {
    return Api().get("Checklists/GetAllCheckListForGroupChecklist");
  },
  searchChecklist(factoryCode, offset, name) {
    let endpoint =
      "Checklists/Mobile?siteOfficeMasterCode=" +
      factoryCode +
      "&offset=" +
      offset +
      "&limit=24&checklistName=" +
      name;
    return Api().get(endpoint);
  },
  deleteGroupChecklist(groupId) {
    let endpoint = `GroupChecklist/DeleteGroupChecklist/${groupId}`;
    return Api().delete(endpoint);
  },
  saveGroup(groupId, data) {
    let endpoint = `GroupChecklist/UpdateGroupChecklistById/${groupId}`;
    return Api().post(endpoint, data);
  },
  getAdminGroup() {
    return Api().get("GroupChecklist/GetGroupManagers");
  },
  saveAdminGroup(data) {
    return Api().post(`GroupChecklist/UpdateGroupManagers`, data);
  },
};
