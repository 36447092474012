import React, { useEffect } from "react";
import { Modal, Row, Col, Button, Input, Form } from "antd";
import "./popup.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, customGroup } from "@src/redux/confirm";
import WaringIcon from "@src/styles/icon/notify_icon.svg";
import {
  resetStateIsUpdated,
  updateResultPOMM,
  createResultPOMM,
  updateShowMultiChecker,
  setShowAlert,
  deleteRequestByIndex,
} from "@src/redux/checkList";
import {
  addGroup,
  updateGroupId,
  deleteGroup,
  updateGroup,
} from "@src/redux/setting";
import Paragraph from "antd/lib/typography/Paragraph";
import { lang, COMMON_CONSTANT } from "@src/constants/common.const";
function Confirm() {
  const dispatch = useDispatch();
  let { open, title, typeConfirm, typeCustom, redirectUrl, data } = useSelector(
    (state) => state.confirm
  );
  let { groupId, allGroupsName, arrayName } = useSelector(
    (state) => state.setting
  );
  const { selectedFactory } = useSelector((state) => state.auth);
  const { showMultiChecker, dataMultiCheck } = useSelector(
    (state) => state.checklist
  );
  const stateCL = useSelector((state) => state.checklist);
  const history = useHistory();
  const location = useLocation();
  const [formGroupName] = Form.useForm();
  const [kind, updateKind] = React.useState(null);
  let regExp = '^[^<>{}"/|;:.,~!?@#$%^=&*\\]\\\\[¿§«»ω⊙¤°℃℉€¥£¢¡®©_+]*$';
  const renderIcon = () => {
    return <img style={{ height: "30px" }} alt="logo.png" src={WaringIcon} />;
  };
  useEffect(() => {
    data && formGroupName.setFieldsValue({ name: data.text });
  }, [data, formGroupName]);
  const addGroups = () => {
    if (
      !formGroupName.getFieldsError()[0].errors.length &&
      formGroupName.getFieldValue("name") &&
      arrayName.indexOf(formGroupName.getFieldValue("name")) === -1
    ) {
      dispatch(addGroup(formGroupName.getFieldValue("name")));
      closeDialog();
    }
  };
  const deleteGroupChecklist = () => {
    dispatch(deleteGroup(groupId));
    dispatch(updateGroupId(allGroupsName[0].id));
    closeDialog();
  };
  const updateGroupChecklist = () => {
    if (
      !formGroupName.getFieldsError()[0].errors.length &&
      formGroupName.getFieldValue("name") &&
      arrayName.indexOf(formGroupName.getFieldValue("name")) === -1
    ) {
      dispatch(updateGroup(groupId, formGroupName.getFieldValue("name")));
      closeDialog();
    }
  };
  const checkSameName = async (rule, value) => {
    if (arrayName.indexOf(value) !== -1) {
      throw new Error(lang.GROUP_CL_HAS_EXISTED);
    }
  };
  const updateOrCreateCheckResult = () => {
    let params = {
      isOnline: navigator.onLine,
      type: "draft",
      factoryCode: selectedFactory,
    };
    if (data.type === "edit") {
      dispatch(
        updateResultPOMM(params, data.state, history, data.checklist, null)
      );
    } else {
      dispatch(
        createResultPOMM(params, data.state, history, data.checklist, null)
      );
    }
    closeDialog();
  };
  const updateOrCreate = (kindData, mergeStatus) => {
    if (!mergeStatus) {
      updateKind(kindData);
    }
    handleTypeSave(
      dataMultiCheck.type,
      dataMultiCheck.checkResult,
      kindData,
      mergeStatus
    );
  };
  const handleTypeSave = (type, checklistResult, kind, mergeStatus) => {
    let params = {
      isOnline: navigator.onLine,
      type: type,
      factoryCode: selectedFactory,
      hideRemark: checklistResult.hideRemark,
      data: checklistResult,
    };
    dispatch(setShowAlert(false));
    if (checklistResult.id !== "new-checklist") {
      dispatch(
        updateResultPOMM(
          params,
          stateCL,
          history,
          checklistResult,
          kind,
          mergeStatus
        )
      );
    } else {
      dispatch(
        createResultPOMM(
          params,
          stateCL,
          history,
          checklistResult,
          kind,
          mergeStatus
        )
      );
    }
    dispatch(updateShowMultiChecker(false));
  };
  const renderButtonRight = () => {
    if (typeCustom) {
      switch (typeCustom) {
        case "edit":
          return (
            <Button onClick={openModalDelete} className="button button--error">
              {lang.DELETE}
            </Button>
          );
        case "delete":
          return (
            <Button
              onClick={closeDialog}
              className="button button--outline --todo--"
            >
              {lang.CLOSE}
            </Button>
          );
        default:
          return <></>;
      }
    }
  };

  const renderButtonLeft = () => {
    if (typeConfirm) {
      switch (typeConfirm) {
        case "warning":
          return (
            <Button onClick={() => redirectPage()} className="button button--warning">
              {lang.OK}
            </Button>
          );
        case "success":
          return (
            <Button onClick={() => redirectPage()} className="button button--success">
              {lang.OK}
            </Button>
          );
        case "error":
          return (
            <Button
              onClick={() => redirectChecklist()}
              className="button button--error"
            >
              {lang.OK}
            </Button>
          );
        // case save cl when redirect by browser from detail checklist to home screen
        case "saveCL":
          return (
            <Button
              onClick={() => updateOrCreateCheckResult()}
              className="button button--warning2"
            >
              {lang.SAVE_DRAFT}
            </Button>
          );
        default:
          return <></>;
      }
    }
    if (typeCustom) {
      switch (typeCustom) {
        case "add":
          return (
            <Button
              htmlType="submit"
              className="button button--info"
              onClick={() => addGroups()}
            >
              {COMMON_CONSTANT.ADD}
            </Button>
          );
        case "edit":
          return (
            <Button
              htmlType="submit"
              className="button button--info"
              onClick={() => updateGroupChecklist()}
            >
              {lang.SAVE}
            </Button>
          );
        case "delete":
          return (
            <Button
              htmlType="submit"
              className="button button--error"
              onClick={() => deleteGroupChecklist()}
            >
              {lang.OK}
            </Button>
          );
        default:
          return <></>;
      }
    }
  };
  const openModalDelete = () => {
    dispatch(closeModal());
    dispatch(
      customGroup({
        open: true,
        title: COMMON_CONSTANT.TITLE_DEL_GROUP,
        typeCustom: "delete",
        data: {
          text: data.text,
        },
      })
    );
  };
  const renderCustomGroup = () => {
    if (typeCustom !== "delete") {
      return (
        <Form name="input" form={formGroupName}>
          <Row align="middle" justify="center" gutter={[8, 8]}>
            <Col span={24}>
              <Row justify="center">
                {typeCustom ? (
                  <Col className="titleCustomGroup">{title}</Col>
                ) : (
                  renderIcon()
                )}
              </Row>
            </Col>
            <Col className="text-weight-600" span={24}>
              {COMMON_CONSTANT.GROUP_NAME}:　
            </Col>
            <Col span={24}>
              <Form.Item
                name="name"
                className="rowNotMargin"
                rules={[
                  {
                    required: true,
                    message: lang.PLEASE_INPUT_NAME,
                  },
                  {
                    pattern: regExp,
                    message: lang.INPUT_FIELD_DO_NOT_SPECIAL_CHAR,
                  },
                  { validator: checkSameName },
                ]}
              >
                <Input maxLength="255" className="inputMobile" />
              </Form.Item>
            </Col>
            {typeCustom === "add" ? (
              <Col span={24}>{renderButtonLeft()}</Col>
            ) : (
              <>
                <Col span={12}>{renderButtonLeft()}</Col>
                <Col span={12}>{renderButtonRight()}</Col>
              </>
            )}
            {typeCustom !== "delete" ? (
              <Col span={24}>
                <Row justify="center">
                  <Button
                    style={{ width: "50%" }}
                    onClick={closeDialog}
                    className="button button--outline --todo--"
                  >
                    {lang.CLOSE}
                  </Button>
                </Row>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Form>
      );
    } else {
      return (
        <Row align="middle" justify="center" className="rowNotMargin">
          <Col span={24}>
            <Row justify="center" align="middle" gutter={[8, 8]}>
              <Col>{renderIcon()}</Col>
              <Col className="titleCustomGroup" style={{ paddingBottom: 0 }}>
                {title}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Paragraph
                ellipsis={{ rows: 2 }}
                style={{ color: "#A4AFC1", fontSize: "20px" }}
              >
                "{data.text}"
              </Paragraph>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Col style={{ fontSize: "20px", textAlign: "center" }}>
                {COMMON_CONSTANT.BOM_C033}
              </Col>
            </Row>
          </Col>
          <Col span={12}>{renderButtonLeft()}</Col>
          <Col span={12}>{renderButtonRight()}</Col>
        </Row>
      );
    }
  };
  const renderPopupConfirm = () => {
    return (
      <Row align="middle" justify="center" gutter={[8, 8]}>
        <Col span={24}>
          <Row justify="center" style={{ padding: "8px 0" }}>
            {renderIcon()}
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <div style={{ fontWeight: 600, textAlign: "center" }}>
              {typeConfirm !== "saveCL" ? (
                COMMON_CONSTANT.BOM_C018
              ) : (
                <div>
                  <p className="rowNotMargin">
                    {lang.CURRENT_CHECKLIST_IS_PROCESSING}
                  </p>
                  <p className="rowNotMargin">
                    {lang.DO_YOU_SAVE_DRAFT_CHECKLIST}
                  </p>
                  <p className="rowNotMargin">{lang.SAVE_DRAFT_CL}</p>
                  <p className="rowNotMargin">{lang.CANCEL_SAVE_CL}</p>
                </div>
              )}
            </div>
          </Row>
        </Col>
        <Col flex={1}>{renderButtonLeft()}</Col>

        <Col flex={1}>
          <Button className="button button--cancel" onClick={closeDialog}>
            {COMMON_CONSTANT.CLEAR}
          </Button>
        </Col>
      </Row>
    );
  };
  const redirectPage = () => {
    dispatch(resetStateIsUpdated());
    history.push(redirectUrl);
    closeDialog();
  };
  const redirectChecklist = () => {
    dispatch(resetStateIsUpdated());
    history.push(redirectUrl);
    closeDialog();
  };
  const closeDialog = () => {
    formGroupName.resetFields();
    dispatch(closeModal(false));
    if (typeConfirm === "saveCL" && !location.pathname.includes('/list/')) {
      dispatch(resetStateIsUpdated());
      // history.push('/checklist')
    }
  };

  const handleMultiChecker = (typeStatus) => {
    switch (typeStatus) {
      case "merge":
        Modal.confirm({
          centered: true,
          title: lang.WARMING,
          content: "未入力データのみを保存しますが、よろしいでしょうか。",
          okText: lang.OK,
          cancelText: lang.CANCEL,
          onOk: () => updateOrCreate(kind, 1),
        });
        break;
      case "replace":
        updateOrCreate(kind, 3);
        break;
      case "replaceAll":
        updateOrCreate(kind, 3);
        break;
      case "replaceItem":
        Modal.confirm({
          centered: true,
          title: lang.WARMING,
          content: "入力した項目を全て上書きしますが、よろしいでしょか。",
          okText: lang.OK,
          cancelText: lang.CANCEL,
          onOk: () => updateOrCreate(kind, 2),
        });
        break;
      default:
        break;
    }
    updateKind(null);
  };
  const onCancelMultiCheck = () => {
    let existsInRequest;
    const checkResult = dataMultiCheck.checkResult;
    if (checkResult.id) {
      existsInRequest = stateCL.dataRequests.filter(
        (item) => item.id === checkResult.id
      );
      if (existsInRequest !== -1) {
        const index = stateCL.dataRequests.findIndex(
          (item) => item.id === checkResult.id
        );
        dispatch(deleteRequestByIndex(index));
      }
    }
    dispatch(updateShowMultiChecker(false));
    if (window.location.pathname === "/checklist") {
      window.location.reload();
    }
  };
  const renderModalMultiChecker = () => (
    <Modal
      visible={showMultiChecker && !location.pathname.includes("/list")}
      title={lang.WARMING}
      closeIcon={<></>}
      footer={
        dataMultiCheck.hasDuplicate
          ? dataMultiCheck.haveMerge
            ? [
                <Button
                  style={{ marginTop: "5px" }}
                  key="merge"
                  type="primary"
                  onClick={() => handleMultiChecker("merge")}
                >
                  未入力データのみ保存
                </Button>,
                <Button
                  style={{ marginTop: "5px" }}
                  key="replaceItem"
                  type="primary"
                  onClick={() => handleMultiChecker("replaceItem")}
                >
                  入力した項目すべて上書き保存
                </Button>,
                <Button
                  style={{ marginTop: "5px" }}
                  key="cancel"
                  onClick={() => onCancelMultiCheck()}
                >
                  {lang.CANCEL}
                </Button>,
              ]
            : [
                <Button
                  style={{ marginTop: "5px" }}
                  key="replaceItem"
                  type="primary"
                  onClick={() => handleMultiChecker("replaceItem")}
                >
                  入力した項目すべて上書き保存
                </Button>,
                <Button
                  style={{ marginTop: "5px" }}
                  key="cancel"
                  onClick={() => onCancelMultiCheck()}
                >
                  {lang.CANCEL}
                </Button>,
              ]
          : dataMultiCheck.haveMerge
          ? [
              <Button
                style={{ marginTop: "5px" }}
                key="merge"
                type="primary"
                onClick={() => handleMultiChecker("merge")}
              >
                未入力データのみ保存
              </Button>,
              <Button
                style={{ marginTop: "5px" }}
                key="cancel"
                onClick={() => onCancelMultiCheck()}
              >
                {lang.CANCEL}
              </Button>,
            ]
          : [
              <Button
                style={{ marginTop: "5px" }}
                key="replace"
                type="primary"
                onClick={() => handleMultiChecker("replace")}
              >
                {lang.OK}
              </Button>,
              <Button
                style={{ marginTop: "5px" }}
                key="cancel"
                onClick={() => onCancelMultiCheck()}
              >
                {lang.CANCEL}
              </Button>,
            ]
      }
    >
      <Row>
        <Col span={24}>
          <span className="info--text">'{dataMultiCheck.checklistName}'</span>{" "}
          は<span className="info--text">'{dataMultiCheck.inspector}'</span>
          {lang.SAVE_CL_DRAFT}
        </Col>
      </Row>
    </Modal>
  );
  return (
    <>
      <Modal
        className="modalMobile"
        visible={open}
        footer={null}
        centered="true"
        zIndex={10000}
        closeIcon={<></>}
        width={370}
      >
        <Row align="middle" justify="center" gutter={[8, 8]}>
          {typeCustom ? renderCustomGroup() : renderPopupConfirm()}
        </Row>
      </Modal>
      {renderModalMultiChecker()}
    </>
  );
}
export default Confirm;
