import { createSlice } from "@reduxjs/toolkit";
import ROUTER_PAGE from "@src/constants/router.const";
import { FunctionCustomApi } from "./../api/backend/functionCustom";
const initialState = {
  tabParent: ["detailOffice", "dashboard"],
  tabChild: ["DASHBOARD_PAGE"],
  collapsed: false,
  visibleNotify: false,
  titleHeader: null,
  listMenu: [],
  listMenuCode: [],
};

export const loadMenuAction = () => (dispatch) => {
  FunctionCustomApi.loadMenu().then((res) => {
    if (res && res.status === 200) {
      dispatch(setMenu(res.data));
    }
  });
};
const sideBarSlice = createSlice({
  name: "sideBar",
  initialState,
  reducers: {
    selectTab: (state, { payload }) => {
      state.tabParent = payload.tabParent;
      state.tabChild = payload.tabChild;
    },
    collapsedSideBar: (state, { payload }) => {
      state.collapsed = payload;
    },
    setVisibleNotify: (state, { payload }) => {
      state.visibleNotify = payload;
    },
    updateTitleHeader: (state, { payload }) => {
      state.titleHeader = ROUTER_PAGE.NAME_PAGE[payload];
    },
    updateTitleHeaderWithoutConst: (state, { payload }) => {
      state.titleHeader = payload;
    },
    setMenu: (state, { payload }) => {
      let arr = [];
      payload.forEach((item) => {
        arr.push(item.menuCode);
        if (item.subMenus) {
          item.subMenus.forEach((subItem) => {
            arr.push(subItem.menuCode);
          });
        }
      });
      state.listMenuCode = arr;
      state.listMenu = payload;
    },
  },
});

// the action
export const {
  selectTab,
  collapsedSideBar,
  setVisibleNotify,
  updateTitleHeader,
  updateTitleHeaderWithoutConst,
  setMenu,
} = sideBarSlice.actions;

//the selector
export const sideBarSelector = (state) => state.sideBar;

// the reducer
export default sideBarSlice.reducer;
