import { runWithAdal } from "react-adal";
import { authContext } from "@src/api/config";
import { ConfigProvider } from "antd";
import "react-app-polyfill/stable";
import "antd-mobile/dist/antd-mobile.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "@src/redux/store";
import * as serviceWorker from "./serviceWorker";
// Lang
import jaJP from "antd/lib/locale/ja_JP";
import moment from "moment";
import "moment/locale/ja";
import { PersistGate } from "redux-persist/integration/react";

import { msalConfig } from "@src/api/config";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";


moment.locale("ja_JP");
moment.updateLocale("ja", {
  week: {
    dow: 1, //set monday as the first day of week
  },
});
jaJP.Empty.description = "データがありません。";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(

  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConfigProvider locale={jaJP}>
            <App />
          </ConfigProvider>
        </PersistGate>
      </Provider>
    </MsalProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
serviceWorker.register();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
